import { Injectable } from '@angular/core';
import { NgZoneService } from '@pos-common/services/system';

export type Interval = number;

@Injectable()
export class TimerService {
  constructor(private readonly ngZoneService: NgZoneService) {}

  setInterval(handler: TimerHandler, timeout: number, runOutsideAngular: boolean = true): Interval {
    if (runOutsideAngular) {
      return this.ngZoneService.runOutsideAngular(() => setInterval(handler, timeout));
    }

    return setInterval(handler, timeout);
  }

  clearInterval(id: Interval | undefined) {
    return clearInterval(id);
  }

  setTimeout(handler: TimerHandler, timeout: number, runOutsideAngular: boolean = true): number {
    if (runOutsideAngular) {
      return this.ngZoneService.runOutsideAngular(() => setTimeout(handler, timeout));
    }

    return setTimeout(handler, timeout);
  }
}
