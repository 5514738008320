import { Injectable } from '@angular/core';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { LogService } from '@pos-common/services/system/logger';
import { ILogger } from '@spryrocks/logger';
import {
  MakePaymentOptions,
  MakeRefundOptions,
  MyposGlassError,
  MyposGlassPlugin,
  PosInfo,
  TransactionResponse,
} from '@paymash/capacitor-mypos-glass-plugin';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { StorageKeys, MYPOS_GLASS_ERROR_CODE_MATRIX, MyposGlassTransactionCodeEnum } from '@pos-common/constants';
import { TerminalNames } from '@pos-common/constants/terminal-names.enum';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { MyposGlassErrorCode, NfcState } from '@paymash/capacitor-mypos-glass-plugin/dist/esm/models';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';

@Injectable()
export class MyposGlassService {
  private readonly logger: ILogger;
  protected commonErrors: Set<MyposGlassTransactionCodeEnum> = new Set<MyposGlassTransactionCodeEnum>([
    MyposGlassTransactionCodeEnum.TRANSACTION_DECLINED,
    MyposGlassTransactionCodeEnum.TRANSACTION_CANCELED,
    MyposGlassTransactionCodeEnum.TRANSACTION_FAILED
  ])

  constructor(
    logService: LogService,
    private platform: PlatformService,
    private localStorageService: LocalStorage,
    private readonly paymentProcessingService: PaymentProcessingService,
  ) {
    this.logger = logService.createLogger('MyposGlassService');
  }

  public get isActiveMyPosGlassTerminal(): boolean {
    const activeTerminal = this.localStorageService.getObject(StorageKeys.activeTerminal);
    return activeTerminal.name === TerminalNames.GLASS;
  }

  async isNfsReady(): Promise<boolean> {
    const staticError: MyposGlassError = new MyposGlassError(
      MyposGlassTransactionCodeEnum.NFC_NOT_READY,
      (<MyposGlassErrorCode><unknown>MyposGlassTransactionCodeEnum.NFC_NOT_READY),
      undefined);

    try {
      const state: NfcState = await MyposGlassPlugin.getNfcState();
      const isNfsReady: boolean = state === NfcState.Enabled;

      if (isNfsReady) {
        return isNfsReady;
      } else {
        this.logger.error(state, "myPOS Glass get NFC State not ready");
        throw (staticError);
      }
    } catch (e) {
      this.logger.error(e, "myPOS Glass get NFC State failed");
      throw(staticError);
    }
  }

  async info(): Promise<PosInfo> {
    this.logger.debug('Receive POS info');

    this.checkSupportCurrentPlatform();

    try {
      const posInfo: PosInfo = await MyposGlassPlugin.info();
      this.logger.info('Pos info received', { posInfo });
      return posInfo;
    } catch (e) {
      this.logger.error(e, "Pos info received failed");
      throw (e);
    }

  }

  async makePayment(options: MakePaymentOptions): Promise<PaymentResult> {
    try {
      await this.info();

      this.logger.debug("Make payment", {options});

      const paymentResponse: TransactionResponse = await MyposGlassPlugin.makePayment({
        ...options,
        skipConfirmationScreen: true,
      });

      this.logger.info("Payment operation was successful", {paymentResponse});

      return this.createPaymentResult(paymentResponse);
    } catch (e) {
      this.logger.error(e, "Payment operation failed");
      this.processTransactionError(e);
      throw e;
    }
  }

  async makeRefund(options: MakeRefundOptions): Promise<PaymentResult> {
    try {
      await this.info();

      this.logger.debug('Make refund', { options });

      const paymentResponse: TransactionResponse = await MyposGlassPlugin.makeRefund({
        ...options,
        skipConfirmationScreen: true,
      });

      this.logger.info("Refund operation was successful");

      return this.createPaymentResult(paymentResponse);
    } catch (e) {
      this.logger.error(e, "Refund operation failed");
      const error: MyposGlassError = this.refundErrorMapper(e);
      this.processTransactionError(error);
      throw e;
    }
  }

  public getErrorMessageMyPosGlassTerminal(error: MyposGlassError): string {
    const hasTransactionProcessingResult: boolean =
      error.transactionProcessingResult &&
      !!MYPOS_GLASS_ERROR_CODE_MATRIX[error.transactionProcessingResult];

    const hasCodeError: boolean =
      !!error.code &&
      !!MYPOS_GLASS_ERROR_CODE_MATRIX[error.code];

    const hasMessageError: boolean =
      !!error.message &&
      !!MYPOS_GLASS_ERROR_CODE_MATRIX[error.message];

    if (hasTransactionProcessingResult) {
      return MYPOS_GLASS_ERROR_CODE_MATRIX[error.transactionProcessingResult];
    } else if (hasMessageError) {
      return MYPOS_GLASS_ERROR_CODE_MATRIX[error.message];
    } else if (hasCodeError) {
      return MYPOS_GLASS_ERROR_CODE_MATRIX[error.code];
    } else {
      return MYPOS_GLASS_ERROR_CODE_MATRIX[MyposGlassTransactionCodeEnum.UNKNOWN];
    }
  }

  public redirectToPlayMarket(): void {
    MyposGlassPlugin
      .redirectTo({ destination: 'glassAppInGooglePlay' });
  }

  public redirectToMyPosGlassApplication(): void {
    MyposGlassPlugin
      .redirectTo({ destination: 'glassApp' });
  }

  private checkSupportCurrentPlatform(): void {
    if (this.platform.isIOS) {
      const error: MyposGlassError = new MyposGlassError(
        <string>MyposGlassTransactionCodeEnum.PLATFORM_NOT_SUPPORT_GLASS,
        <MyposGlassErrorCode><unknown>MyposGlassTransactionCodeEnum.PLATFORM_NOT_SUPPORT_GLASS,
        undefined
        );
      this.logger.error(error, "MyPOS Glass is not available for this device");
      throw(error)
    }
  }

  private refundErrorMapper(error: MyposGlassError): MyposGlassError {
    const key: string = !!error.transactionProcessingResult ? 'transactionProcessingResult' : 'code';

    if (!error[key]) {
      return error;
    }

    const isCommonError: boolean = this.commonErrors.has(error[key]);
    if (isCommonError) {
      return {
        ...error,
        [key]: MyposGlassTransactionCodeEnum.REFUND_NOT_ACTIVATED
      }
    }

    return error;
  }

  private createPaymentResult(paymentResponse: TransactionResponse): PaymentResult {
    const paymentResult: PaymentResult = new PaymentResult(PAYMENT_PROVIDERS.MYPOSGLASS);
    paymentResult.setPaymentResultData(paymentResponse);
    return paymentResult;
  }

  private processTransactionError(error: unknown): void {
    this.paymentProcessingService.init();
    this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
      message: this.getErrorMessageMyPosGlassTerminal(<MyposGlassError>error),
      retryButtonOff: true,
    });
  }

}
