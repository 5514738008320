import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ModalService } from '../../common/services/system/modal.service';
import { LogService } from '../../common/services/system/logger/log.service';
import { GiftCard } from './gift-card';
import { GiftCardsProviderAbstract } from '@pos-common/services/resources/gift-cards.provider.abstract';
import { GiftCardProvider } from './gift-card.provider';
import { IGiftCardValidators } from './interfaces/giftCardValidators.interface';
import { NetworkService } from '@pos-common/services/system/network.service';
import { UserNotificationService } from '@pos-common/services/system/user-notification/user-notification.service';

@Injectable()
export class GiftCardService extends GiftCardProvider {
  private readonly logger = this.logService.createLogger('GiftCardService');
  constructor(
    private modalService: ModalService,
    private giftCardsProvider: GiftCardsProviderAbstract,
    private networkService: NetworkService,
    private userNotificationService: UserNotificationService,
    private logService: LogService
  ) {
    super();
  }

  public openAssignGiftCardModal(giftCardCode: string, validators?: IGiftCardValidators, isAssign: boolean = false): Promise<GiftCard> {
    return new Promise<GiftCard>(async (resolve, reject) => {
      if (this.isOffline()) {
        return resolve(null);
      }
      const onDidEnterEvent = new Subject<void>();
      const component = (await import('./assign-gift-card-modal/assign-gift-card.component')).AssignGiftCardComponent;
      const assignGiftCardsModal = await this.modalService.presentModal(component, {
        onDidEnterEvent,
        giftCardCode,
        isAssign,
        validators,
      });
      assignGiftCardsModal.onDidDismiss().then((result) => {
        this.logger.debug('openAssignGiftCardModal:onDidDismiss:data', { data: result.data });
        resolve(result.data);
      });
      assignGiftCardsModal
        .present()
        .then(() => {
          this.logger.debug('assignGiftCardsModal:present');
          onDidEnterEvent.next();
          onDidEnterEvent.complete();
        })
        .catch((error) => {
          this.logger.error(error, 'openAssignGiftCardModal');
          reject(error);
        });
    });
  }

  getGiftCardByCode(code: string): Observable<GiftCard> {
    return this.giftCardsProvider.getGiftCardByCode(code);
  }

  getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard> {
    return this.giftCardsProvider.getGiftCardByPhysicalCode(physicalCode);
  }

  isOffline(): boolean {
    if (this.networkService.getConnectionStatus()) {
      this.userNotificationService.showNoConnectionGiftCardsMessage();
      return true;
    }
    return false;
  }

  helperGiftCardPayCalculate(card: GiftCard, invoiceAmount: number): { given: number; change: number } {
    const { balance } = card;

    let given: number = balance;
    let change: number = 0;

    if (balance > invoiceAmount) {
      given = invoiceAmount;
      change = balance - invoiceAmount;
    }

    return { given, change };
  }

  makeCardObfuscation(value: string, showStars: boolean = true, defaultSing: string = '...', addBrackets: boolean = false) {
    if (!value || value?.length < 5) {
      return value;
    }
    const regexReplaceAllExceptSpaces = /[^\s]/g;
    const amountOfVisibleSymbols = 4;
    const first = value.slice(0, value.length - amountOfVisibleSymbols);
    const last = value.slice(-amountOfVisibleSymbols);
    let result = defaultSing + last;
    if (showStars) {
      result = first.replace(regexReplaceAllExceptSpaces, '*') + last;
    }
    return addBrackets ? `(${result})` : result;
  }
}
