import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Product as ProductEntity } from '../../../classes/product.class';
import { ICustomerDisplayProvider } from './ICustomerDisplayProvider';
import { EmailModelChanged, ICustomerDisplayService } from './ICustomerDisplayService';
import { ICustomerDisplayDevice } from './ICustomerDisplayDevice';
import { Invoice } from '@pos-common/classes/invoice.class';
import { ICustomerDisplaySession } from './ICustomerDisplaySession';
import { SecurityService } from '@pos-common/services/system/security.service';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { Image, IMAGE_SIZES } from '@pos-common/classes/image.class';
import { ImageLoaderService } from '@spryrocks/ionic-image-loader-v5';
import { ImageUtils } from '@pos-common/services/utils/image.utils';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { Company } from '@pos-common/classes/company.class';
import { getRandomProducts, ImageSize } from './CustomerDisplayServiceUtils';
import { Customer } from '@pos-common/classes/customer.class';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@pos-common/services/system/cart.service';
import { InvoiceEntry, InvoiceGuest, Product } from './entities';
import { ICustomerDisplaySessionDelegate } from './ICustomerDisplaySessionDelegate';
import { Subject, Subscription } from 'rxjs';
import { CustomerDisplayServiceState } from './state';
import { UniversalCustomerDisplayProvider } from './universal/UniversalCustomerDisplayProvider';
import { InvoiceEntry as InvoiceEntryEntity } from '@pos-common/classes/invoice-entry.class';
import { IDeviceReportingService } from '@pos-common/services/system/device-report';
import { CustomerDisplayReporting } from './CustomerDisplayReporting';
import { LogService } from '@pos-common/services/system/logger';
import PQueue from 'p-queue';
import { ignorePromiseError } from '@pos-common/services/utils/promise.utils';

@Injectable({ providedIn: 'root' })
export class CustomerDisplayService extends ICustomerDisplayService implements ICustomerDisplaySessionDelegate {
  private readonly logger = this.logService.createLogger('CustomerDisplayService');

  private readonly providers: ICustomerDisplayProvider[];

  private devices_: ICustomerDisplayDevice[] = [];
  private readonly devicesChangeEvent_: EventEmitter<ICustomerDisplayDevice[]>;

  private readonly sessions: ICustomerDisplaySession[] = [];

  private readonly emailModelChangedSubject = new Subject<EmailModelChanged>();

  private readonly sessionsSubscriptions = new Map<ICustomerDisplaySession, Subscription[]>();

  private readonly report: CustomerDisplayReporting;

  private _state: CustomerDisplayServiceState | undefined;
  private turnedOn: boolean;
  private isDeviceDiscoveryStarted: boolean;

  constructor(
    injector: Injector,
    private readonly securityService: SecurityService,
    private readonly localStorage: LocalStorage,
    private readonly imageLoaderService: ImageLoaderService,
    private readonly imageUtils: ImageUtils,
    private readonly dbDaoService: DbDaoService,
    private readonly translateService: TranslateService,
    private readonly cartService: CartService,
    deviceReportingService: IDeviceReportingService,
    private readonly logService: LogService
  ) {
    super();

    this.logger.info('Initialize customer display service');

    this.report = new CustomerDisplayReporting(this, deviceReportingService);

    this.devicesChangeEvent_ = new EventEmitter<ICustomerDisplayDevice[]>();

    this.providers = [new UniversalCustomerDisplayProvider(injector, this.report)];

    this.providers.forEach((provider) => {
      provider.devices.subscribe((devices) => this.onDevicesUpdated(provider, devices));
      provider.initialize(this);
    });

    this.cartService.activeInvoiceUpdated.subscribe((data) => {
      this.processInvoiceChange(data).catch(ignorePromiseError);
    });
  }

  get devices() {
    return this.devices_;
  }

  get devicesChangeEvent() {
    return this.devicesChangeEvent_;
  }

  async turnOn() {
    this.logger.debug('Turn on customer display service');

    if (this.turnedOn) {
      this.logger.info('Customer display service already turner on, skipping');
      return;
    }
    this.turnedOn = true;

    this.logger.info('Turn on providers');
    for (const provider of this.providers) {
      await provider.turnOn();
    }

    this.startDeviceDiscovery();

    this.logger.info('Customer display service turned on');
  }

  async turnOff() {
    this.logger.debug('Turn off customer display service');

    if (!this.turnedOn) {
      this.logger.info('Customer display service already turned off, skipping');
      return;
    }
    this.turnedOn = false;

    this.stopDeviceDiscovery();

    this.logger.debug('Turn off providers');
    for (const provider of this.providers) {
      await provider.turnOff();
    }

    await this.disconnectAllDevices();

    this.logger.info('Customer display service turned off');
  }

  startDeviceDiscovery() {
    this.logger.debug('Start device discovery');
    if (this.isDeviceDiscoveryStarted) {
      this.logger.info('Device discovery already started, skipping');
      return;
    }
    this.isDeviceDiscoveryStarted = true;

    this.logger.debug('Start device discovery in each provider');
    this.providers.forEach((provider) => provider.startDeviceDiscovery());

    this.logger.info('Device discovery started');
  }

  stopDeviceDiscovery() {
    this.logger.debug('Stop device discovery');
    if (!this.isDeviceDiscoveryStarted) {
      this.logger.info('Device discovery already stopped, skipping');
      return;
    }
    this.isDeviceDiscoveryStarted = false;

    this.logger.info('Stop device discovery in each provider');
    this.providers.forEach((provider) => provider.stopDeviceDiscovery());

    this.logger.info('Device discovery stopped');
  }

  async connectDevice(device: ICustomerDisplayDevice) {
    this.logger.debug('Connect to device', { device });
    const provider = this.findProviderById(device.providerId);
    await provider.connect(device);
    this.report.deviceConnected(device);
    this.logger.info('Device connected successfully', { device });
  }

  async disconnectDevice(device: ICustomerDisplayDevice) {
    this.logger.debug('Disconnect from device', { device });
    const provider = this.findProviderById(device.providerId);
    const session = this.findSessionByDevice(device);
    await provider.disconnect(device, session);
    this.report.deviceDisconnected(device);
    this.logger.info('Device disconnected successfully', { device });
  }

  async disconnectAllDevices() {
    const devices = [...this.devices_];
    this.logger.debug('Disconnect from all devices', { devices });
    for (const device of devices) {
      if (device.status === 'disconnected') {
        this.logger.info('Device already disconnected, skipping', { device });
        continue;
      }
      await this.disconnectDevice(device);
    }
    this.logger.info('All devices disconnected', { devices });
  }

  async onDeviceConnected(session: ICustomerDisplaySession) {
    const device = session.device;
    this.logger.info('Device connected', { device });
    this.sessions.push(session);
    await this.setupSessionSubscriptions(session);
    this.logger.info('Subscriptions setup finished', { device });
    await this.initializeDevice(session);
    this.logger.info('Device initialized', { device });
  }

  async onDeviceDisconnected(device: ICustomerDisplayDevice, session: ICustomerDisplaySession | undefined) {
    const logger = this.logger.child();
    logger.updateParams({ device });

    this.logger.info('Device disconnected');

    if (!session) {
      this.logger.info('Session is not exists');
      return;
    }

    const index = this.sessions.indexOf(session);
    if (index > -1) {
      this.sessions.splice(index, 1);
      this.logger.info(`Session with index "${index}" removed from list`, { device });
    } else {
      this.logger.info('Session not found');
    }

    await this.destroySessionSubscriptions(session);
    this.logger.info('Session subscription destroyed');
    await session.destroy();
    this.logger.info('Session destroyed');
  }

  async processInvoiceChange(invoice: Invoice | undefined) {
    await this.processInvoiceChangedInternal(invoice, undefined, true);
  }

  async invoiceSent() {
    await this.invoiceSentInternal(undefined, true);
  }

  async invoiceSentInternal(sessions: ICustomerDisplaySession[] | undefined, setState: boolean) {
    this.logger.info('Invoice sent', { setState });
    if (setState) {
      this.state = { type: 'invoiceSent' };
    }
    await this.processSessions((session) => session.invoiceSent(), sessions);
  }

  async lockUserInput(sessions?: ICustomerDisplaySession[]) {
    this.logger.info('Lock user input');
    await this.processSessions((session) => session.lockUserInput(), sessions);
  }

  async unlockUserInput(sessions?: ICustomerDisplaySession[]) {
    this.logger.info('Unlock user input');
    await this.processSessions((session) => session.unlockUserInput(), sessions);
  }

  async requestEmailAddress(amount: number, qrCode: string) {
    this.logger.info('Request email address', { amount, qrCode });
    await this.requestEmailAddressInternal(amount, qrCode, undefined, true);
  }

  async setEmailAddress(value: string) {
    this.logger.info('Set email address', { value });
    await this.setEmailAddressInternal(value, undefined, true);
  }

  async setEmailAddressInternal(value: string, sessions: ICustomerDisplaySession[] | undefined, saveState: boolean) {
    this.logger.debug('setEmailAddressInternal', { value, saveState });

    if (saveState) {
      if (this.state && this.state.type === 'email') {
        this.state = {
          ...this.state,
          email: value,
        };
      } else {
        this.logger.info('State not changed, because current type of state should be "email"', { state: this.state });
      }
    }

    await this.processSessions((session) => session.setEmailAddress(value), sessions);
  }

  emailModelChanged() {
    return this.emailModelChangedSubject;
  }

  private onDevicesUpdated(provider: ICustomerDisplayProvider, devices: ICustomerDisplayDevice[]) {
    this.logger.info('Devices updated', { devices });
    const availableDevices: ICustomerDisplayDevice[] = [...this.devices_];

    devices.forEach((device) => {
      const index = availableDevices.findIndex((d) => d.equals(device));
      if (index < 0) {
        this.logger.info('Add device to list', { device });
        availableDevices.push(device);
      }
    });

    const devicesToRemove = availableDevices.filter(
      (device) => device.providerId === provider.providerId && devices.findIndex((d) => d.equals(device)) < 0
    );
    this.logger.info('Remove devices', { devicesToRemove });
    devicesToRemove.forEach((device) => availableDevices.splice(availableDevices.indexOf(device), 1));

    this.setDevices(availableDevices);
  }

  private setDevices(devices: ICustomerDisplayDevice[]) {
    this.logger.info('Device list changed', { devices });
    this.devices_ = devices;
    this.devicesChangeEvent_.emit(devices);
  }

  private findProviderById(providerId: string) {
    this.logger.info(`Find provider with id: "${providerId}"`);
    const provider = this.providers.find((p) => p.providerId === providerId);
    if (!provider) throw new Error(`Unknown provider: ${providerId}`);
    this.logger.info(`Provider with id "${providerId}" found`);
    return provider;
  }

  private findSessionByDevice(device: ICustomerDisplayDevice) {
    this.logger.info('Find session for device', { device });
    const session = this.sessions.find((s) => s.device.equals(device));
    if (session) {
      this.logger.info('Session for device found', { device });
    } else {
      this.logger.info('Session for device not found', { device });
    }
    return session;
  }

  private async initializeDevice(session: ICustomerDisplaySession) {
    this.logger.debug('Initialize device', { device: session.device });

    // company
    const { company, currency } = this.getCompanyAndCurrency();
    const lang = this.getPreferredLanguage();
    const companyImage = await this.loadImageAsync(company.image, IMAGE_SIZES.MEDIUM);
    this.logger.info('Initialize session', { company, currency, lang, companyImageExists: companyImage !== undefined });
    await session.initialize(currency, lang, companyImage);

    // 3 random products
    this.logger.info('Get random products from database');
    const products = await this.getRandomProductsFromDatabaseWithImages();
    this.logger.info('Found random products in the database', { products });
    await session.setProductsForScreensaver(products);

    this.logger.info('Restore state', { state: this.state });

    // cart
    if (this.state?.type === 'default') {
      await this.processInvoiceChangedInternal(this.state.invoice, [session], false);
    }

    // email
    if (this.state?.type === 'email') {
      await this.requestEmailAddressInternal(this.state.amount, this.state.qrCode, [session], false);
      await this.setEmailAddressInternal(this.state.email, [session], false);
    }

    // invoiceSent
    if (this.state?.type === 'invoiceSent') {
      await this.invoiceSentInternal([session], false);
    }
  }

  private async setupSessionSubscriptions(session: ICustomerDisplaySession) {
    let subscriptions: Subscription[] = this.sessionsSubscriptions.get(session);
    if (!subscriptions) {
      subscriptions = [];
      this.sessionsSubscriptions.set(session, subscriptions);
    }

    subscriptions.push(
      session.emailModelChanged().subscribe((data) => {
        this.logger.debug('Email model changed', { data });
        if (this.state?.type === 'email') {
          this.state = {
            ...this.state,
            email: data.emailAddress,
          };
        } else {
          this.logger.info('State not changed because type of current state should be "email"', { state: this.state });
        }
        this.emailModelChangedSubject.next(data);
      })
    );
  }

  private async destroySessionSubscriptions(session: ICustomerDisplaySession) {
    const subscriptions: Subscription[] = this.sessionsSubscriptions.get(session);
    if (!subscriptions?.length) return;

    subscriptions.forEach((session) => {
      session.unsubscribe();
    });

    this.sessionsSubscriptions.delete(session);
  }

  private async processInvoiceChangedInternal(
    invoice: Invoice | undefined,
    sessions: ICustomerDisplaySession[] | undefined,
    setState: boolean
  ) {
    this.logger.info('Process invoice changed', { invoiceUuid: invoice?.uuid, setState });

    if (setState) {
      this.state = {
        type: 'default',
        invoice,
      };
    }

    if (!this.sessions?.length) {
      this.logger.info('Process invoice - no active sessions');
      return;
    }

    // customer
    const customer = invoice?.customer ? await this.getCustomerByUuid(invoice.customer.uuid) : undefined;
    if (customer) {
      const customerName = customer.dataToShowInList;
      const customerImage = await this.loadImageAsync(customer.image, IMAGE_SIZES.NORMAL);
      await this.processSessions((session) => session.addCustomer(customerName, customerImage), sessions);
    } else {
      await this.processSessions(async (session) => session.removeCustomer(), sessions);
    }

    // entries
    if (!invoice) {
      this.logger.info('Invoice is undefined, update invoice entries');
      await this.processSessions(async (session) => session.updateInvoiceEntries([], [], 0), sessions);
      return;
    }

    const invoiceEntities = invoice.getActiveInvoiceEntries();
    const rawEntries = await Promise.all(
      invoiceEntities
        .filter((entry) => entry.quantity > 0)
        .map(async (entry) => {
          const image = await this.loadImageAsync(entry.image, IMAGE_SIZES.NORMAL);
          return { entry, image };
        })
    );

    const { entries, guests } = this.groupGuests(rawEntries);

    const addDiscount = ([prefix, postfix]: ['invoice' | 'customer', 'cash' | 'percent'], totalPrice: number) =>
      totalPrice &&
      entries.push(
        InvoiceEntry.createDiscount(
          prefix === 'invoice' ? this.translateService.instant('invoice_discount') : this.translateService.instant('customer_discount'),
          totalPrice,
          prefix === 'invoice' ? invoice.totalInvoiceDiscountAmount : invoice.totalCustomerDiscountAmount,
          `${prefix}-discount-${postfix}`
        )
      );

    addDiscount(['invoice', 'cash'], invoice.discount);
    addDiscount(['invoice', 'percent'], invoice.discountPercentage);
    addDiscount(['customer', 'cash'], invoice.customerDiscount);
    addDiscount(['customer', 'percent'], invoice.customerDiscountPercentage);

    await this.processSessions((session) => session.updateInvoiceEntries(entries, Array.from(guests.values()), invoice.amount), sessions);
  }

  private groupGuests(rawEntries: { entry: InvoiceEntryEntity; image: string | undefined }[]) {
    const guests = new Map<number, InvoiceGuest>();
    const entries = new Array<InvoiceEntry>();

    rawEntries.forEach(({ entry, image }) => {
      if (entry.isGuest) {
        const guest = InvoiceGuest.fromInvoiceEntryEntity(entry);
        guests.set(guest.guestNumber, guest);
        return;
      }

      const guest = guests.get(entry.guestNumber);
      const entity = InvoiceEntry.fromInvoiceEntryEntity(entry, image, guest?.uuid);
      entries.push(entity);
    });

    return { entries, guests };
  }

  private async getCustomerByUuid(uuid: string): Promise<Customer | undefined> {
    try {
      const { data, status } = await this.dbDaoService.getDataByUUID(UPDATES_TYPES.Customer.type, uuid);
      if (!(data && status === 200)) return undefined;
      return new Customer(data);
    } catch (e) {
      return undefined;
    }
  }

  private async getRandomProductsFromDatabaseWithImages() {
    const maxCount = 50;
    const concurrency = 50;
    const dbPageSize = 100;

    const queue = new PQueue({ concurrency });

    const result = new Array<Product>();

    await getRandomProducts(
      this.dbDaoService,
      async (product, cancel) => {
        if (result.find((p) => p.uniqueIdentifier === product.uuid) !== undefined) {
          return;
        }
        if (queue.pending >= concurrency) {
          await queue.onIdle();
        }
        queue
          .add(async () => {
            if (result.length >= maxCount) {
              cancel();
              return;
            }
            const image = await this.loadProductImageAsync(product, IMAGE_SIZES.NORMAL);
            if (!image) return;
            if (result.length >= maxCount) {
              cancel();
              return;
            }
            const productWithImage = Product.fromProductEntity(product, image);
            result.push(productWithImage);
          })
          .catch(ignorePromiseError);
      },
      { pageSize: dbPageSize }
    );

    await queue.onIdle();

    return result;
  }

  private getCompanyAndCurrency(): { company: Company; currency: string } {
    const company = this.securityService.getLoggedCompanyData();
    const currency = company['locale']['currency'];
    return { company, currency };
  }

  private getPreferredLanguage(): string {
    return this.localStorage.get('preferredLanguage');
  }

  private async loadProductImageAsync(product: ProductEntity, imageSize: ImageSize) {
    const image = product.images.length > 0 ? product.images[0].image : undefined;
    if (!image) return undefined;
    return this.loadImageAsync(image, imageSize);
  }

  private loadImageAsync(image: Image | undefined | null, imageSize: ImageSize): Promise<string | undefined> {
    if (!image) return Promise.resolve(undefined);
    const base64RegularForReplace = /^data:.*.;base64,/;
    const imageUrl = image.getImageUrlBySize(imageSize);
    return this.imageLoaderService
      .getImagePath(imageUrl)
      .then((image) => this.imageUtils.convertFileSrc(image))
      .then((image) => this.imageUtils.imageToDataURL(image))
      .then((image) => (image ? image.replace(base64RegularForReplace, '') : undefined))
      .catch(() => undefined);
  }

  private async processSessions(
    callback: (session: ICustomerDisplaySession) => Promise<void>,
    sessions: ICustomerDisplaySession[] | undefined
  ) {
    if (sessions === undefined) sessions = [...this.sessions];
    for (const session of sessions) {
      await callback(session);
    }
  }

  private async requestEmailAddressInternal(
    amount: number,
    qrCode: string,
    sessions: ICustomerDisplaySession[] | undefined,
    setState: boolean
  ) {
    this.logger.debug('Request email address', { amount, qrCode, setState });
    if (setState) {
      this.state = { type: 'email', amount, qrCode, email: '' };
    }
    await this.processSessions((session) => session.requestEmailAddress(amount, qrCode), sessions);
  }

  private get state() {
    return this._state;
  }

  private set state(newState: CustomerDisplayServiceState | undefined) {
    this._state = newState;
  }
}
