import { UPDATES_TYPES } from '../constants/updates-types.const';
import { SyncRetryInterface } from '../interfaces/sync-retry.interface';
import { ReferenceEntity } from './reference-entity';

export class SyncListItem implements SyncRetryInterface {
  uuid: string;
  deleted: boolean = false;
  type: string;
  syncInProgress: boolean = false;
  reference: ReferenceEntity[];
  retryCount: number;
  localLastSyncDate: string;

  constructor(data: any, type: string) {
    this.uuid = data.uuid;
    this.deleted = data.deleted || false;
    this.type = type;
    this.syncInProgress = data.syncInProgress || false;
    this.reference = data.reference || this.setReference(data);
    this.retryCount = data.retryCount || 0;
    this.localLastSyncDate = data.localLastSyncDate || new Date().toISOString();
  }

  private setReference(entityData: any): ReferenceEntity[] {
    let reference: ReferenceEntity[] = [];
    if (this.type === UPDATES_TYPES.Invoice.type) {
      if (entityData.customer) {
        reference.push(new ReferenceEntity(entityData.customer.uuid));
      }
      if (entityData.originalInvoiceReference) {
        reference.push(new ReferenceEntity(entityData.originalInvoiceReference.uuid));
      }
    }
    return reference;
  }
}
