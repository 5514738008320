import { Injectable } from '@angular/core';
import { SecurityService } from '@pos-common/services/system/security.service';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';

type AdyenCredentialsResponseData = {
  properties: {
    apiKey: string;
    merchantId: string;
  };
};
type AdyenCredentials = { apiKey: string; merchantId: string };

@Injectable()
export class AdyenApiService {
  private baseAdyenCredentialsUrl = `${SERVER_CONFIG.API_URL}paymentGateways/adyen/credentials`;

  constructor(private readonly securityService: SecurityService) {}

  getAdyenCredentials(): Observable<AdyenCredentials> {
    return this.securityService.doSecureRequestObservable(this.baseAdyenCredentialsUrl, 'get').pipe(
      map((response: SecuredResponse) => {
        const data: AdyenCredentialsResponseData = response.data as AdyenCredentialsResponseData;
        return {
          apiKey: data.properties?.apiKey ? data.properties.apiKey.trim() : null,
          merchantId: data.properties?.merchantId ? data.properties?.merchantId.trim() : null,
        } as AdyenCredentials;
      })
    );
  }
}
