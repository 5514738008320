import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';
import * as moment from 'moment';
import { File } from '@spryrocks/capacitor-filesystem-plugin';
import { createBlob } from '@pos-common/services/utils/blob.utils';

@Injectable()
export class AwsService {
  public sendData(pathOnAws: string, fileName: string, data: Blob): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const S3 = await import('aws-sdk/clients/s3').then((m) => m.default);
      const awsOptions = PAYMASH_PROFILE.ASW;
      const { accessKeyId, secretAccessKey, region } = awsOptions;
      const bucket = new S3({
        accessKeyId,
        region,
        secretAccessKey,
        correctClockSkew: true,
      });
      const request = bucket.putObject({
        Body: data,
        Bucket: awsOptions.bucket,
        Key: `pos/${pathOnAws}${fileName}`,
      });
      request.on('httpError', (err) => {
        reject(err);
      });
      request.send((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  public makeAwsLink(companyId: string | number, platform: string, deviceUuid: string, folder: string): string {
    const today = moment().format('YYYY-MM-DD');
    const time = moment().format('HH:mm:ss');
    return `company/${companyId}/pos-system/${today}/${platform}/${deviceUuid}/${folder}/${time}/`;
  }

  public async sendFilesToAws(files: File[], pathOnAws: string, fileName: string): Promise<void> {
    if (!files.length) {
      return;
    }
    const JSZip = await import('jszip').then((m) => m.default);
    const zip = new JSZip();
    for (const file of files) {
      const fileData = await file.readAsString();
      zip.file(file.name, fileData);
    }
    const data = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
    await this.sendData(pathOnAws, fileName, data);
  }
}
