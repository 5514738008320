<div *ngIf="showContent" @fadeElm>
  <div *ngIf="!isOnlineInvoice && (currentFilterSet.tab === 'open' || currentFilterSet.tab === 'paid')">
    <div class="icon-wrapper">
      <i class="custom-icon-sales"></i>
    </div>
    <div class="description-block">
      <span class="title">{{ 'no_invoices_title' | translate }}</span>
      <p class="description">{{ 'no_invoices_description' | translate }}</p>
    </div>

    <ng-container *ngIf="isPossibleAddInvoice">
      <div class="btn-block">
        <ion-button *ngIf="!isTableForce" class="button disable-hover" icon-left tappable (click)="addNewInvoice()"
          ><i class="custom-icon-add-invoice"></i>{{ 'add_invoice' | translate }}</ion-button
        >
        <hr />
        <a class="link" href="{{ 'add_invoice_help_center_link' | translate }}"
          ><i class="custom-icon-help-center"></i>{{ 'to_help_center' | translate }}</a
        >
      </div>
    </ng-container>

  </div>

  <div class="gastro-mode" *ngIf="!isOnlineInvoice && (currentFilterSet.tab !== 'open' && currentFilterSet.tab !== 'paid')">
    <div class="icon-wrapper">
      <i class="custom-icon-sales"></i>
    </div>
    <div class="description-block">
      <span class="title">
        {{ 'you_dont_have_any_open_invoices' | translate }}
        <ng-container *ngIf="currentFilterSet.tab !== 'no' && currentFilterSet.entities?.table">
          {{ 'for_table' | translate }} {{ currentFilterSet.entities.table.name }}
        </ng-container>
      </span>
      <p class="description">{{ 'here_you_can_find_the_list' | translate }}</p>
    </div>

    <ng-container *ngIf="isPossibleAddInvoice">
      <div class="btn-block">
        <ion-button
          *ngIf="!isTableForce || currentFilterSet.entities?.table"
          class="button disable-hover ion-text-wrap"
          icon-left
          tappable
          (click)="addNewInvoice()"
        >
          <i class="custom-icon-add-invoice"></i>
          {{ 'invoices_new_invoice' | translate }}
          <ng-container *ngIf="currentFilterSet.tab !== 'no' && currentFilterSet.entities?.table">
            {{ 'for_table' | translate }} {{ currentFilterSet.entities.table.name }}
          </ng-container>
        </ion-button>
      </div>
    </ng-container>

  </div>

  <div *ngIf="isOnlineInvoice">
    <div class="icon-wrapper">
      <i class="custom-icon-sales"></i>
    </div>
    <div class="description-block">
      <span class="title">{{ 'no_invoices_title' | translate }}</span>
      <p class="description">{{ 'no_online_invoices_description' | translate }}</p>
    </div>
  </div>

</div>
