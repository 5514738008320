import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PlatformType } from '@pos-common/constants/platform-types.enum';
import { Device } from '@ionic-native/device/ngx';

@Injectable()
export class PlatformService {
  constructor(private platform: Platform, private device: Device) {}

  get isAndroid() {
    return this.platform.is(PlatformType.Android);
  }

  get isIOS() {
    return this.platform.is(PlatformType.iOS);
  }

  get isMobile() {
    return window.innerWidth < 769;
  }

  get isWeb() {
    return !this.isNativePlatform;
  }

  get isNativePlatform() {
    return !!window.cordova;
  }

  get isAndroid10AndMore() {
    return this.isAndroid && this.greaterOrEqualVersion(10);
  }

  get isAndroid12AndMore() {
    return this.isAndroid && this.greaterOrEqualVersion(12);
  }

  get currentPlatform() {
    if (this.isAndroid) {
      return PlatformType.Android;
    } else if (this.isIOS) {
      return PlatformType.iOS;
    }
    return PlatformType.Web;
  }

  private greaterOrEqualVersion(version: number) {
    const deviceVersion = parseInt(this.device.version);
    return deviceVersion >= version;
  }

  public ready() {
    return this.platform.ready();
  }
}
