export const PAYMASH_PROFILE_COMMON = {
  APP_ENV: 'dev',
  isProduction: false,
  SERVER_URL: 'https://app.staging.mypaymash.com',
  isLoggerActive: true,
  sentryUrl: 'https://470324f4aa304d21ad5b88041a5ade3e@sentry.io/1200980',
  sentryEnvironment: 'development',
  requestTimeout: {
    status: 1000 * 30,
    normal: 1000 * 60,
    max: 1000 * 150,
    firstUpdate: 1000 * 60 * 8,
    retryAfter: 10,
    diagnosis: 1000 * 10,
    sendRequest: 1000 * 120,
  },
  retrySyncItemInterval: 5000,
  retrySyncItemIntervalMaxValue: 1000 * 20,
  retrySyncCount: 15,
  retrySyncMaxCount: 100,
  ASW: {
    accessKeyId: 'AKIAJQDXVVMNMQAKSCBQ',
    secretAccessKey: '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',
    region: 'eu-central-1',
    bucket: 'paymash-test',
  },
  LOGGER: {
    url: 'https://logs.fabware.com:45635',
    username: 'Mokohzik4IoFuYgh_X2ZDEV',
    password: '76L9oyKRTRHFLj5SUS17l-Z',
  },
  sendingLogsDelay: 0.5,
  printingTriesCount: 25,
  socketScanner: {
    developerId: '60CDCCA8-B0D4-41A0-AF84-18092176194F',
    iosAppKey: 'MC4CFQDHlPCy/4oPJ84WU32AZd6qWyi5WwIVAPPPUja1nVdgLTqY6yf1thBgyOBL',
    androidAppKey: 'MC4CFQC6E+QAKGnTvp43b+w9nimkmGoWZgIVAPbzjuNHQ8ArYeeMLhHf865G9B3s',
  },
  lokiJS: {
    prefix: 'pos',
    maxFileSize: 1570000,
  },
  fullCalendarLicenseKey: '0972986092-fcs-1657871549',
  adyen: {
    managementUrl: 'https://management-test.adyen.com',
    terminalUrl: 'https://terminal-api-test.adyen.com',
  },
  intercom: {
    appId: 'f7ygz4ih',
    appType: 'Paymash POS Hybrid',
  },
  sumup: {
    apiKey: '5371115a-f0f1-493d-82ee-fc9db50138a6',
  },
  gaTrackingCode: 'UA-1808094-9',
};
