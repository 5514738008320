import { Injectable, NgZone } from '@angular/core';
import { SchedulerLike, Subscription, asyncScheduler } from 'rxjs';

//rx-ng-zone-scheduler.ts
//https://www.npmjs.com/package/ngx-rxjs-zone-scheduler
type Work<T> = (state?: T) => void;
type Delay = number | undefined;

class ZoneScheduler implements SchedulerLike {
  constructor(protected ngZone: NgZone, protected scheduler: SchedulerLike) {}

  schedule<T>(...args: [Work<T>, Delay, T]): Subscription {
    return this.ngZone.runOutsideAngular(() => {
      return this.scheduler.schedule(...args);
    });
  }

  now(): number {
    return this.scheduler.now();
  }
}

@Injectable()
export class NgZoneService {
  constructor(private ngZone: NgZone) {}

  runOutsideAngular<T>(fn: () => T): T {
    return this.ngZone.runOutsideAngular(() => fn());
  }

  leaveNgZoneRxjs() {
    return new ZoneScheduler(this.ngZone, asyncScheduler);
  }

  run<T>(fn: () => T): T {
    return this.ngZone.run(() => fn());
  }
}
