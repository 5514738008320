import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { Injectable } from '@angular/core';
import { PosSettingsService } from '../pos-settings.service';
import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { InvoiceEntityOrGuestType } from '@pos-common/types/invoice-entity-or-guest.type';
import { MathUtils } from '@pos-common/services/utils/math.utils';
import { AlertService } from '../alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { Subject } from 'rxjs';
import { Customer } from '@pos-common/classes/customer.class';
import { Invoice } from '@pos-common/classes/invoice.class';

@Injectable()
export class MultipleGuestsService {
  public nextGuestNumber = 1;
  private _guestNumbers = [];
  get guestNumbers() {
    return this._guestNumbers.length;
  }
  public activeInvoiceEntryGuest: InvoiceEntryGuest = null;
  private _guestNumberForPay = 1;
  private addNewGuest$ = new Subject<void>();
  private _isFirstGuest = true;
  private readonly logger = this.logService.createLogger('MultipleGuestsService');

  constructor(
    private posSettingsService: PosSettingsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  get isMultipleGuests() {
    return this.posSettingsService.getMultipleGuestsStatus();
  }

  get activeGuestNumber() {
    const guestNumber = this.activeInvoiceEntryGuest?.guestNumber || 1;
    return guestNumber;
  }

  get activeGuestName() {
    if (!this.activeInvoiceEntryGuest) {
      return this.getDefaultGuestName(1);
    }
    return this.activeInvoiceEntryGuest.name;
  }

  set isFirstGuest(value: boolean) {
    this._isFirstGuest = value;
  }

  get isFirstGuest() {
    return this._isFirstGuest;
  }

  getAddNewGuestEvent() {
    return this.addNewGuest$;
  }

  setAddNewGuestEvent() {
    this.addNewGuest$.next();
  }

  get guestNumberForPay() {
    return this._guestNumberForPay;
  }

  setGuestNumberForPay(guestNumber: number) {
    this._guestNumberForPay = guestNumber;
  }

  setActiveInvoiceEntryGuest(invoiceEntryGuest: InvoiceEntryGuest) {
    this.activeInvoiceEntryGuest = invoiceEntryGuest;
  }

  increaseGuestNumbers() {
    this._guestNumbers = [...this._guestNumbers, this.nextGuestNumber];
  }

  reduceGuestNumbers(guestNumber: number) {
    this._guestNumbers = this._guestNumbers
      .filter((currentGuestNumber) => currentGuestNumber !== guestNumber)
      .map((currentGuestNumber) => {
        if (currentGuestNumber > guestNumber) {
          currentGuestNumber -= 1;
        }
        return currentGuestNumber;
      });

    this.nextGuestNumber -= 1;
  }

  setGuestNumbers(value: number[]) {
    this._guestNumbers = value;
  }

  setDefaultGuestNumbers(invoiceEntryGuests: InvoiceEntryGuest[]) {
    const guestNumbers = invoiceEntryGuests.map((invoiceEntryGuest) => invoiceEntryGuest.guestNumber);
    const nextGuestNumber = Math.max(...guestNumbers, 0);
    const currentNextGuestNumber = nextGuestNumber + 1;
    if (invoiceEntryGuests.length !== this.guestNumbers || currentNextGuestNumber !== this.nextGuestNumber) {
      this.setGuestNumbers(guestNumbers);
      this.nextGuestNumber = nextGuestNumber + 1;
    }

    const { activeInvoiceEntryGuest, activeGuestNumber } = this;
    const isExistActiveInvoiceEntryGuest = invoiceEntryGuests.some((entry) => entry.guestNumber === activeGuestNumber);

    if (invoiceEntryGuests.length && (!activeInvoiceEntryGuest || !isExistActiveInvoiceEntryGuest)) {
      this.setActiveInvoiceEntryGuest(invoiceEntryGuests[0]);
    }
  }

  increaseNumbers() {
    this.nextGuestNumber++;
    this.increaseGuestNumbers();
  }

  reset() {
    this.nextGuestNumber = 1;
    this._guestNumbers = [];
    this.activeInvoiceEntryGuest = null;
    this._guestNumberForPay = 1;
    this.isFirstGuest = true;
  }

  getInvoiceEntryGuests(invoiceEntries: InvoiceEntry[]): InvoiceEntryGuest[] {
    if (!invoiceEntries.length) {
      return [];
    }

    const invoiceEntryGuests = invoiceEntries
      .filter((entry) => entry.isGuest && !entry.deleted)
      .sort((a, b) => a.guestNumber - b.guestNumber)
      .map((entry, index) => new InvoiceEntryGuest({ ...entry, totalAmount: 0, isCustomerGuest: index === 0 }));

    invoiceEntries.forEach((invoiceEntry) => {
      if (invoiceEntry.deleted || invoiceEntry.isGuest) {
        return;
      }

      const currentGuest = invoiceEntryGuests.find((entry) => entry.guestNumber === invoiceEntry.guestNumber);
      if (currentGuest) {
        currentGuest.quantityForKitchenReceipt += invoiceEntry.quantityForKitchenReceipt;
        currentGuest.quantityForSubtract += invoiceEntry.quantityForSubtract;
        currentGuest.invoiceEntryCount++;
        currentGuest.totalAmount = MathUtils.normalizeAddition(currentGuest.totalAmount, invoiceEntry.totalAmount);
      }
    });
    return invoiceEntryGuests;
  }

  getInvoiceEntryOrGuestList(invoiceEntryGuests: InvoiceEntryGuest[], invoiceEntries: InvoiceEntry[]): InvoiceEntityOrGuestType[] {
    if (!invoiceEntryGuests.length) {
      return invoiceEntries;
    }

    return invoiceEntryGuests.reduce((result, invoiceEntryGuest) => {
      const invoiceEntriesForCurrentGuest = invoiceEntries.filter(
        (entry) => entry.guestNumber === invoiceEntryGuest.guestNumber && !entry.isGuest
      );
      if (invoiceEntriesForCurrentGuest.length) {
        return [...result, invoiceEntryGuest, ...invoiceEntriesForCurrentGuest];
      }
      return [...result, invoiceEntryGuest];
    }, []);
  }

  createNewGuest(): InvoiceEntryGuest {
    const { nextGuestNumber: guestNumber } = this;
    const name = this.getGuestName(guestNumber);
    const newInvoiceEntryGuest = new InvoiceEntryGuest({ guestNumber, name });
    this.setActiveInvoiceEntryGuest(newInvoiceEntryGuest);
    this.increaseNumbers();
    return newInvoiceEntryGuest;
  }

  async removeActiveGuest(guestNumber: number, invoiceEntries: InvoiceEntry[]): Promise<InvoiceEntry[]> {
    const minGuestNumber = 1;
    if (this.guestNumbers === minGuestNumber) {
      return [];
    }

    invoiceEntries = [...invoiceEntries];
    let invoiceEntryGuests = this.getInvoiceEntryGuests(invoiceEntries);

    try {
      const invoiceEntryGuest = invoiceEntryGuests.find((invoiceEntryGuest) => invoiceEntryGuest.guestNumber === guestNumber);
      let shouldDeleteGuest = true;
      if (invoiceEntryGuest.invoiceEntryCount) {
        shouldDeleteGuest = await this.deleteProductsAlert();
      }

      if (!shouldDeleteGuest) {
        return [];
      }

      invoiceEntries.forEach((entry) => {
        if (entry.guestNumber === guestNumber) {
          entry.markAsDeleted();
        }
      });
      const updatedInvoiceEntries = this.recalculateEntriesGuestNumber(invoiceEntries, guestNumber);
      this.reduceGuestNumbers(guestNumber);
      invoiceEntryGuests = this.getInvoiceEntryGuests(updatedInvoiceEntries);

      const newInvoiceEntryGuest = this.findActiveGuest(this.activeGuestNumber, invoiceEntryGuests);
      this.setActiveInvoiceEntryGuest(newInvoiceEntryGuest);
      return updatedInvoiceEntries;
    } catch (error) {
      this.logger.error(error, 'removeActiveGuest');
      return [];
    }
  }

  private recalculateEntriesGuestNumber(invoiceEntries: InvoiceEntry[], guestNumber: number): InvoiceEntry[] {
    return invoiceEntries.map((entry) => {
      if (entry.guestNumber === guestNumber) {
        entry.deleted = true;
      }
      if (entry.deleted || entry.guestNumber < guestNumber) {
        return entry;
      }
      const currentGuestNumber = entry.guestNumber;
      const newGuestNumber = currentGuestNumber - 1;
      entry.guestNumber = newGuestNumber;
      entry.name = this.getGuestName(currentGuestNumber, entry.name, newGuestNumber);
      return entry;
    });
  }

  private findActiveGuest(guestNumber: number, invoiceEntries: InvoiceEntryGuest[]): InvoiceEntryGuest {
    const invoiceEntryGuest = invoiceEntries.find((entry) => entry.guestNumber === guestNumber);
    if (invoiceEntryGuest) {
      return invoiceEntryGuest;
    }
    guestNumber -= 1;
    return invoiceEntries.find((entry) => entry.guestNumber === guestNumber);
  }

  private deleteProductsAlert(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertService.create({
        message: this.translateService.instant('cart_delete_products'),
        buttons: [
          {
            text: this.translateService.instant('common_no'),
            handler: () => resolve(false),
          },
          {
            text: this.translateService.instant('common_yes'),
            handler: () => resolve(true),
          },
        ],
      });
      await alert.present();
    });
  }

  private getDefaultGuestName(guestNumber: number): string {
    return `${this.translateService.instant('common_guest')} ${guestNumber}`;
  }

  getGuestName(guestNumber: number, guestName?: string, nextGuestNumber?: number) {
    const defaultGuestName = this.getDefaultGuestName(guestNumber);
    if (!guestName) {
      return defaultGuestName;
    }
    if (!nextGuestNumber) {
      return guestName;
    }
    const newName = this.getDefaultGuestName(nextGuestNumber);
    return defaultGuestName === guestName ? newName : guestName;
  }

  calculateSplitInvoiceWithGuests(invoiceEntries: InvoiceEntry[]): InvoiceEntry[] {
    let invoiceEntryGuests = [];
    return invoiceEntries
      .map((invoiceEntry) => {
        if (invoiceEntry.isGuest) {
          invoiceEntry.quantity = 0;
          invoiceEntry.quantityForSubtract = 0;
          invoiceEntryGuests = [...invoiceEntryGuests, invoiceEntry];
        }
        return invoiceEntry;
      })
      .map((invoiceEntry) => {
        if (invoiceEntry.isGuest) {
          return invoiceEntry;
        }

        const { guestNumber } = invoiceEntry;
        const currentGuest = invoiceEntryGuests.find((entry) => !entry.deleted && entry.guestNumber === guestNumber);
        if (currentGuest) {
          currentGuest.quantity += invoiceEntry.quantity;
          currentGuest.quantityForSubtract += invoiceEntry.quantityForSubtract;
        }
        return invoiceEntry;
      });
  }

  updateGuestNumbers(invoiceEntries: InvoiceEntry[], deletedGuestNumber: number): InvoiceEntry[] {
    const subGuestNumber = deletedGuestNumber > 1 ? deletedGuestNumber - 1 : deletedGuestNumber;
    return invoiceEntries.map((entry) => {
      if (entry.deleted) {
        return entry;
      }

      const { guestNumber: currentGuestNumber } = entry;
      const newGuestNumber = currentGuestNumber > 1 ? currentGuestNumber - subGuestNumber : 1;
      entry.guestNumber = newGuestNumber;
      if (entry.isGuest) {
        entry.name = this.getGuestName(currentGuestNumber, entry.name, newGuestNumber);
      }
      return entry;
    });
  }

  addCustomerToGuest(invoiceEntry: InvoiceEntry, customer: Customer, guestNumber: number) {
    let name = this.getDefaultGuestName(guestNumber);
    let customerIdentifier: string = null;

    if (customer) {
      name = `${customer.firstName} ${customer.lastName}`;
      customerIdentifier = customer.uuid;
    }

    invoiceEntry.update({ ...invoiceEntry, name, customerIdentifier });
    return invoiceEntry;
  }

  deleteEmptyGuestsFromPaidInvoice(invoice: Invoice): Invoice {
    if (!invoice.isPaid) {
      return invoice;
    }

    const invoiceEntryGuests = this.getInvoiceEntryGuests(invoice.invoiceEntries);
    invoice.invoiceEntries = invoice.invoiceEntries.map((invoiceEntry) => {
      if (invoiceEntry.isGuest) {
        const invoiceEntryGuest = invoiceEntryGuests.find((guest) => guest.guestNumber === invoiceEntry.guestNumber);
        if (!invoiceEntryGuest?.invoiceEntryCount) {
          invoiceEntry.deleted = true;
        }
      }
      return invoiceEntry;
    });
    return invoice;
  }
}
