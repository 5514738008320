import { Injectable } from '@angular/core';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Image, IMAGE_SIZES } from '../../classes/image.class';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { ImageLoaderService } from '@spryrocks/ionic-image-loader-v5';
import { LogService } from './logger/log.service';
import { DbDaoService } from '../db/db-dao.service';
import { UuidService } from '../utils/uuid.utils';
import { PlatformService } from './platform/platform.service';
import { ImageToLoad } from '@pos-common/classes/image-to-load.class';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { oneOf } from '@paymash/capacitor-database-plugin';
import { delay, filter, skip, switchMap } from 'rxjs/operators';
import { SetTimeoutUtil } from '../utils/settimeout.utils';

export interface ImageLoadingState {
  inProgress: boolean;
  total: number;
  loaded: number;
}

@Injectable()
export class ImageLoadingService {
  private imagesToBeLoaded: ImageToLoad[] = [];
  private isBackgroundProcess = false;
  private imageLoadTimeout = 3000;
  private limitNumberOfRetries = 5;

  private _loadingState: Subject<ImageLoadingState> = new BehaviorSubject({
    inProgress: false,
    total: 0,
    loaded: 0,
  });
  private readonly logger = this.logService.createLogger('ImageLoadingService');

  constructor(
    private imageLoaderService: ImageLoaderService,
    private dbDaoService: DbDaoService,
    private uuidService: UuidService,
    private platformService: PlatformService,
    private setTimeoutUtil: SetTimeoutUtil,
    private logService: LogService
  ) {}

  public findAndPrepareImages(item) {
    try {
      switch (item.type) {
        case UPDATES_TYPES.Product.type:
          this.addImageListToCacheList(item.properties.images, 'image');
          break;
        case UPDATES_TYPES.ProductVariant.type:
          this.addImageListToCacheList(item.properties.images, 'document');
          break;
        default:
          this.addImageToCacheList(item.properties.image, IMAGE_SIZES.NORMAL);
          break;
      }
    } catch (e) {
      this.logger.error(e, 'findAndPrepareImages:');
    }
  }

  public get loadingState(): Observable<ImageLoadingState> {
    return this._loadingState.asObservable();
  }

  public async savePreloadImages(): Promise<unknown> {
    if (this.platformService.isWeb || !this.imagesToBeLoaded.length) {
      return;
    }
    const images = [...this.imagesToBeLoaded];
    this.imagesToBeLoaded = [];
    return this.dbDaoService.upsertDataToCollection(UPDATES_TYPES.ImagesToLoad.type, images);
  }

  public async startLoading(): Promise<void> {
    if (this.platformService.isWeb || this.isBackgroundProcess) {
      return;
    }
    const imagesCount = await this.dbDaoService.count(UPDATES_TYPES.ImagesToLoad.type);
    if (!imagesCount) {
      return;
    }
    await this.setTimeoutUtil.addVisualEffect(3000);
    this.startBackgroundLoading();
  }

  private async startBackgroundLoading() {
    this.isBackgroundProcess = true;
    const limit = PAGINATION.IMAGE_ITEMS_COUNT;
    let imagesToLoad: ImageToLoad[] = [];

    const subscription = this.loadingState
      .pipe(
        skip(1),
        filter((state) => !state.inProgress),
        switchMap(() => {
          const imagesToDelete = imagesToLoad.map((image) => image.uuid);
          if (imagesToDelete.length) {
            return from(this.dbDaoService.remove(UPDATES_TYPES.ImagesToLoad.type, { uuid: oneOf(...imagesToDelete) }));
          }
          return of();
        }),
        delay(2000),
        switchMap(() => from(this.dbDaoService.getAllDataRaw(UPDATES_TYPES.ImagesToLoad.type, undefined, { limit })))
      )
      .subscribe((images: ImageToLoad[]) => {
        imagesToLoad = images;
        if (!images.length) {
          subscription?.unsubscribe();
          this.isBackgroundProcess = false;
          return;
        }
        this.checkLoadingQueue(images, images.length);
      });

    imagesToLoad = await this.dbDaoService.getAllDataRaw(UPDATES_TYPES.ImagesToLoad.type, undefined, { limit });
    this.checkLoadingQueue(imagesToLoad, imagesToLoad.length);
  }

  private addImageListToCacheList(images: any[], property: string) {
    if (!images || (images && !images.length)) return;
    for (const img of images) {
      const imageData = img[property];
      if (!imageData) continue;
      const imgObj = new Image(imageData);
      const imagesSize = this.platformService.isMobile ? IMAGE_SIZES.MEDIUM : IMAGE_SIZES.NORMAL;
      this.addImageToCacheList(imgObj, imagesSize);
      this.addImageToCacheList(imgObj, IMAGE_SIZES.SMALL);
    }
  }

  private addImageToCacheList(image: any, size: string): void {
    if (!image) return;
    const imgSrc = new Image(image).getImageUrlBySize(size);
    const imageToLoad = new ImageToLoad({ uuid: this.uuidService.generate(), src: imgSrc });
    this.imagesToBeLoaded.push(imageToLoad);
  }

  private checkLoadingQueue(images: ImageToLoad[], imagesTotal: number) {
    const imagesToLoad = [...images];
    if (imagesToLoad.length > 0) {
      this._loadingState.next({ inProgress: true, total: imagesTotal, loaded: imagesTotal - images.length });
      const imageUrl = imagesToLoad.shift();

      if (imageUrl.numberOfRetries < this.limitNumberOfRetries) {
        this.imageLoaderService
          .preload(imageUrl.src)
          .catch((e) => {
            imagesToLoad[0] = new ImageToLoad({ ...imagesToLoad[0], numberOfRetries: imagesToLoad[0].numberOfRetries + 1 });
            this.setTimeoutUtil.waitTimeAndDo(this.imageLoadTimeout).then(() => this.checkLoadingQueue(imagesToLoad, imagesTotal));
          })
          .then(() => this.setTimeoutUtil.waitTimeAndDo(this.imageLoadTimeout).then(() => this.checkLoadingQueue(imagesToLoad, imagesTotal)));
        return;
      } else {
        const filterImagesToLoad: ImageToLoad[] = imagesToLoad.filter((item: ImageToLoad) => item.uuid !== imageUrl.uuid);
        this.setTimeoutUtil.waitTimeAndDo(this.imageLoadTimeout).then(() => this.checkLoadingQueue(filterImagesToLoad, imagesTotal))
      }

    }
    this._loadingState.next({ inProgress: false, total: imagesTotal, loaded: imagesTotal - images.length });
  }

}
