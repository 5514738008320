/**
 * Created by y.belinsky on 3/15/18.
 */
export enum PAYMENT_PROVIDERS {
  SUMUP = 'SumUp',
  CCV = 'CCV',
  SIX = 'Six',
  OPI = 'Opi',
  MYPOS = 'myPOS',
  MYPOSMINI = 'myPOSmini',
  PAYMASH_PAY = 'paymashPay',
  MYPOSGLASS = 'myposGlass',
}

export enum PAYMENT_TRANSACTION {
  PAYMENT_PURCHASE = 'PaymentPurchase',
  PAYMENT_REVERSAL = 'PaymentReversal',
  PAYMENT_REFUND = 'PaymentRefund',
}
