<a tappable (click)="logout()">
  <div class="info" [class.active-store]="showStore && ActiveStore">
    <p class="employee-name">
      <span *ngIf="ActiveEmployee">{{ ActiveEmployee.firstName }} {{ ActiveEmployee.lastName }}</span>
    </p>
    <p *ngIf="showStore" class="store-name">
      <span *ngIf="ActiveStore">{{ ActiveStore.name }}</span>
    </p>
  </div>
  <pos-image-loader-cap *ngIf="ActiveEmployee?.image" [url]="ActiveEmployee.image | ImageUrl : IMAGE_SIZES.SMALL"> </pos-image-loader-cap>
  <person-placeholder [class.show]="!ActiveEmployee?.image" width="30" height="30" fillColor="#007aff"></person-placeholder>
</a>
