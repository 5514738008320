import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { mdTransitionAnimation, MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@ionic-native/device/ngx';
import { ModalService } from '../common/services/system/modal.service';
import { DbDaoService } from '../common/services/db/db-dao.service';
import { UpdatesService } from '../common/services/system/updates.service';
import { SyncService } from '../common/services/system/sync.service';
import { SecurityService } from '../common/services/system/security.service';
import { NetworkService } from '../common/services/system/network.service';
import { PosInfoService } from '../common/services/system/pos-info.service';
import { LocalStorage } from '../common/services/utils/localstorage.utils';
import { UserIdleService } from '../common/services/system/idle.service';
import { UPDATES_TYPES } from '../common/constants/updates-types.const';
import { SERVER_CONFIG } from '../common/constants/server.const';
import { AlertService } from '../common/services/system/alert.service';
import { LoadingService } from '../common/services/system/loading.service';
import { AppUpdateService } from '../common/services/system/app-update.service';
import { APP_INFO } from '../common/constants/app-info.const';
import { GoogleAnalyticsService } from '../common/services/system/google-analitycs.service';
import { AdminPageService } from '../common/services/system/admin-page.service';
import { ImageLoaderConfigService } from '@spryrocks/ionic-image-loader-v5';
import { InvoicesService } from '../common/services/system/invoices.service';
import { CollectionViewService } from '../common/services/system/collection-view.service';
import { CHECKOUT_STEPS } from '../common/constants/checkout-steps.const';
import { NullifyModalService } from '../common/services/system/nullify-modal.service';
import { TimApiService } from '../common/services/system/timApi.service';
import { LogService, CoreFields } from '@pos-common/services/system/logger';
import { PosSettingsService } from '../common/services/system/pos-settings.service';
import { PAYMASH_PROFILE } from '@profile';
import { RouteNavigationService } from '../common/services/system/route-navigation/route-navigation.service';
import { ROUTE_URLS } from '../common/constants/route-urls.const';
import { PosSelfCheckService } from '../common/services/system/pos-self-check.service';
import { MyPosMiniFacade } from '../modules/my-pos-mini/my-pos-mini.facade';
import { StorageKeys } from '../common/constants/storage.const';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { SumUpService } from '@pos-common/services/system/sumup/sumup.service';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { StatusBarService } from '@pos-common/services/system/status-bar/status-bar.service';
import { CartService } from '@pos-common/services/system/cart.service';
import { NotifyPopupsService } from '@pos-common/services/system/notify-popups.service';
import { DbDaoUtils, IntercomService } from '@pos-common/services';
import { ActionSheetService } from '@pos-common/services/system/action-sheet/action-sheet.service';
import { AuthApiService } from '@pos-common/services/api/auth-api.service';
import { NgZoneService, TranslateSystemService } from '@pos-common/services/system';
import { swiperRegister } from './swiper';
import { Company } from '@pos-common/classes/company.class';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { ICustomerDisplayService } from '@pos-common/services/system/customer-display';

swiperRegister();

@Component({
  selector: 'app-paymash',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'app.html',
  styleUrls: ['app.component.scss'],
})
export class AppMainModule {
  private rootPage = ROUTE_URLS.login;
  private startSync = false;
  public appIsActive: boolean = true;
  public forceUserLogoutOnResume: boolean = false;
  private readonly logger = this.logService.createLogger('AppMainModule');

  constructor(
    public platform: Platform,
    private platformService: PlatformService,
    public dbDaoService: DbDaoService,
    private dbDaoUtils: DbDaoUtils,
    private updatesService: UpdatesService,
    public SyncService: SyncService,
    public SecurityService: SecurityService,
    public AdminPageService: AdminPageService,
    public NetworkService: NetworkService,
    public LocalStorage: LocalStorage,
    public TranslateService: TranslateService,
    private translateSystemService: TranslateSystemService,
    public UserIdleService: UserIdleService,
    public MenuController: MenuController,
    public ModalService: ModalService,
    public IntercomService: IntercomService,
    public PosInfoService: PosInfoService,
    public AlertService: AlertService,
    public LoadingService: LoadingService,
    private appUpdateService: AppUpdateService,
    public GoogleAnalyticsService: GoogleAnalyticsService,
    public ImageLoaderConfig: ImageLoaderConfigService,
    public InvoicesService: InvoicesService,
    private NullifyModalService: NullifyModalService,
    public CollectionViewService: CollectionViewService,
    public MprimeService: TimApiService,
    public Device: Device,
    public myPosMiniFacade: MyPosMiniFacade,
    private posSettingsService: PosSettingsService,
    private posSelfCheckService: PosSelfCheckService,
    private routeNavigationService: RouteNavigationService,
    private navController: NavController,
    private myPosService: MyPosService,
    private sumUpService: SumUpService,
    private keyboardService: KeyboardService,
    private statusBarService: StatusBarService,
    private cartService: CartService,
    private notifyPopupsService: NotifyPopupsService,
    private actionSheetService: ActionSheetService,
    private authApiService: AuthApiService,
    private customerDisplayService: ICustomerDisplayService,
    private ngZoneService: NgZoneService,
    private logService: LogService,
    private cdr: ChangeDetectorRef
  ) {
    this.ImageLoaderConfig.setBackgroundSize('contain');
    this.routeNavigationService.loadRouting();
    this.initializeApp();
  }

  initializeApp() {
    this.UserIdleService.idleEvent.subscribe(() => this.checkOnUserIdleOccur());
    this.SecurityService.badRefreshToken.subscribe((data: any) => {
      this.logger.debug('badRefreshToken: data', { data });
      if (data.clearLocalDatabase) {
        this.resetCompany();
        return;
      }
      if (data.isExpiredVersion) {
        this.appUpdateService.showRequiredUpdatePopup();
      }
      this.logoutAndRedirectToLoginPage();
    });

    this.updatesService.activeInvoiceWasPaid.subscribe((invoice) => {
      this.logger.info('invoice was paid on the other POS', { invoiceUuid: invoice.uuid });
      this.setPageForPaidInvoiceOnOtherDevice();
    });

    this.updatesService
      .getUpdateEmmiterByType(UPDATES_TYPES.PaymentMethod.type)
      .subscribe((paymentMethods) => this.posSettingsService.handlePaymentMethodUpdate(paymentMethods));

    this.updatesService
      .getUpdateEmmiterByType(UPDATES_TYPES.Product.type)
      .subscribe((products) => this.posSettingsService.handleTipProductUpdate(products));

    this.platform.pause.subscribe(() => {
      this.logger.info('initializeApp:platform:pause');
      this.appIsActive = false;
      this.GoogleAnalyticsService.trackEvent('AppUsage', 'appPaused');
    });

    this.platform.resume.subscribe(() => {
      this.GoogleAnalyticsService.trackEvent('AppUsage', 'appResumed');
      setTimeout(() => (this.appIsActive = true), 50);
      setTimeout(() => this.cdr.detectChanges(), 150);
      setTimeout(() => {
        if (this.forceUserLogoutOnResume) {
          this.logger.debug('initializeApp:platform:resume:checkOnUserIdleOccur');
          this.checkOnUserIdleOccur();
        }
      }, 500);
      this.logger.info('initializeApp:platform:resume');
      if (this.SecurityService.getLoggedUserData()) {
        this.reConnectToMprime();
      }
    });

    this.platform
      .ready()
      .then(() => {
        this.logger.info('initializeApp:platform:ready');
        const deviceInfo = `${this.Device.model}:${this.Device.version}:${this.Device.serial}`;
        this.logger.info('manufacturer, deviceInfo', { manufacturer: this.Device?.manufacturer, deviceInfo });
        this.logService.setField(CoreFields.Device, deviceInfo);

        if (this.myPosService.hasModernWebView) {
          this.checkWebViewVersion();
        }

        this.runApp();
      })
      .catch((err) => this.logger.error(err, 'initializeApp:platform:ready'));
  }

  async runApp() {
    try {
      this.posSelfCheckService.checkPluginsState();
    } catch (e) {
      this.logger.error(e, 'runApp:posSelfCheckService:checkPluginsState');
    }

    try {
      if (window.screen['orientation'] && this.platform.is('android')) {
        let screenOrientation: any = this.platform.width() < 768 ? 'portrait' : 'landscape';
        this.logger.debug('screenOrientation ', { screenOrientation });
        window.screen['orientation'].lock(screenOrientation);
      }
    } catch (e) {
      this.logger.error(e, 'runApp:screen:orientation');
    }

    try {
      await this.SecurityService.setDeviceFingerprint();
    } catch (e) {
      this.logger.error(e, 'runApp:setFingerprint');
    }

    this.GoogleAnalyticsService.startTracker().catch(() => {});

    if (this.platform.is('android')) {
      // TODOIONIC4 check
      this.platform.backButton.subscribeWithPriority(0, () => {
        return;
      });
      // this.platform.registerBackButtonAction((e) => {
      //     return false;
      // }, 1000);
    }

    this.sumUpService.init().catch(() => this.logger.debug('runApp:PaymentService:sumUpInit'));

    this.setupStatusBar();

    this.keyboardService
      .setAccessoryBarVisible({ isVisible: true })
      .catch((error) => this.logger.error(error, 'runApp:Keyboard:setAccessoryBarVisible'));

    //set logger parameters
    const deviceIdentifier = this.SecurityService.getDeviceIdentifier();
    const deviceFingerprint = this.SecurityService.getDeviceFingerprint();
    this.logService.setSentryTag(CoreFields.PosSystemUuid, deviceIdentifier);
    this.logService.setSentryTag(CoreFields.DeviceFingerprint, deviceFingerprint);
    this.logService.setField(CoreFields.PosSystemUuid, deviceIdentifier);
    this.logService.setField(CoreFields.AppVersion, `${APP_INFO.APP_VERSION}:${APP_INFO.APP_BUILD}`);
    this.logService.setField(CoreFields.System, `pm.pos.${PAYMASH_PROFILE.APP_ENV}`);
    this.logService.setField(CoreFields.AppEnv, PAYMASH_PROFILE.APP_ENV);

    this.NetworkService.startNetworkMonitoring();

    // check is user logged IN or NOT
    if (this.LocalStorage.get(StorageKeys.loggedUserData)) {
      try {
        let parsedUserData = JSON.parse(this.LocalStorage.get(StorageKeys.loggedUserData));
        let accessToken = parsedUserData['accessToken'];
        let refreshToken = parsedUserData['refreshToken'];
        let companyUuid = parsedUserData['company']['uuid'];
        if (!accessToken || !refreshToken || !companyUuid) throw new Error();

        // TODO implement
        // this.LogService.companiUuid = companyUuid;

        this.SecurityService.setLoggedUserData(parsedUserData);
        this.PosInfoService.updatePosDataOnServer();

        this.dbDaoUtils
          .getDbVersion(companyUuid)
          .then(async (dbVersion) => {
            if (!dbVersion) {
              this.dbDaoUtils
                .setDbVersion(APP_INFO.MIN_DB_VERSION, companyUuid)
                .catch((err) => this.logger.error(err, 'runApp:DbDaoService:getDbVersion:then:DbDaoService:setDbVersion'));
            }
            if (parseInt(dbVersion) < parseInt(APP_INFO.MIN_DB_VERSION)) {
              let alert = await this.AlertService.create({
                header: this.TranslateService.instant('database_mismatch_title'),
                message: this.TranslateService.instant('database_mismatch_message'),
                buttons: [
                  {
                    text: this.TranslateService.instant('common_cancel'),
                    role: 'cancel',
                  },
                  {
                    text: this.TranslateService.instant('database_refresh'),
                    handler: () => {
                      this.resetCompany();
                    },
                  },
                ],
              });
              alert.present().catch((err) => this.logger.error(err, 'runApp:DbDaoService.getDbVersion:alert:present'));
            }
          })
          .catch((err) => {
            this.logger.error(err, 'runApp:DbDaoService.getDbVersion');
            this.dbDaoUtils
              .setDbVersion(APP_INFO.MIN_DB_VERSION, companyUuid)
              .catch((err) => this.logger.error(err, 'runApp:DbDaoService:getDbVersion:catch:DbDaoService:setDbVersion'));
          });

        // get or create Database
        this.LoadingService.showLoadingItem();
        try {
          const data = await this.dbDaoService.initDatabase(companyUuid);
          this.logger.debug('initDatabase', { data });
          if (data) {
            this.startSync = true;
            this.rootPage = ROUTE_URLS.employee;
          }
          const companyData = await this.dbDaoService.getAllData(UPDATES_TYPES.Company.type);
          this.logger.debug('initDatabase:companyData:length', { length: companyData.data.length });
          const company = companyData.data[0];
          const hasLokiProblem = await this.hasLokiDbMigrationProblem(company, companyUuid);
          if (hasLokiProblem) {
            this.logger.info('hasLokiProblem should call resetCompany');
            this.updatesService.currentCompanyUuid = companyUuid;
            this.SecurityService.setLoggedCompanyData({ uuid: companyUuid });
            this.resetCompany(true);
            return;
          }
          this.SecurityService.setLoggedCompanyData(company);
          this.LoadingService.hideLoadingItem();
          this.reConnectToMprime();
        } catch (error) {
          this.logger.error(error, 'initDatabase');
          this.rootPage = ROUTE_URLS.login;
          this.SecurityService.setLoggedUserData(null);
          this.IntercomService.shutdown().catch((err) => this.logger.error(err, 'runApp:IntercomService.shutdown'));
          this.LoadingService.hideLoadingItem();
        }
      } catch (err) {
        this.SecurityService.setLoggedUserData(null);
        this.IntercomService.shutdown().catch((err) => this.logger.error(err, 'runApp:IntercomService.shutdown'));
      }
    } else {
      this.SecurityService.setLoggedUserData(null);
      this.IntercomService.shutdown().catch((err) => this.logger.error(err, 'runApp:IntercomService.shutdown'));
    }

    if (!this.PosInfoService.getDevicePerformance()) {
      const performanceValue: any = this.PosInfoService.performanceTest();
      this.PosInfoService.setDevicePerformance(performanceValue);
      this.GoogleAnalyticsService.trackEvent('PerformanceCategory', 'PerformanceMeasure', 'Label', performanceValue.toString());
    }

    this.translateSystemService.setDefaultLanguage();
    this.checkMyPOSConnection();
    this.appUpdateService.startUpdateScheduler(SERVER_CONFIG.APP_ENV);
    this.SecurityService.logoutEvent.subscribe(() => {
      this.logoutAndRedirectToLoginPage();
    });
    this.SecurityService.resyncEvent.subscribe(() => {
      this.navController
        .navigateRoot(ROUTE_URLS.employee, { animated: true, animationDirection: 'back' })
        .catch((err) => this.logger.error(err, 'runApp:SecurityService:resyncEvent:navController:navigateRoot:employee'));
    });
    this.navController
      .navigateRoot(this.rootPage, { state: { startSync: this.startSync } })
      .catch((err) => this.logger.error(err, 'runApp:navController:navigateRoot:rootPage'));
    this.routeNavigationService.setInitializationFinished(true);
  }

  private setPageForPaidInvoiceOnOtherDevice() {
    const pageInstance = this.routeNavigationService.current;
    const checkoutPageIsActive = pageInstance === ROUTE_URLS.checkout;

    if (checkoutPageIsActive) {
      this.CollectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.SUCCESS);
      return;
    }

    const isOneClickPayment = this.posSettingsService.getOneClickPaymentStatus();
    const collectionPageIsActive = pageInstance === ROUTE_URLS.collection;
    if (isOneClickPayment && collectionPageIsActive) {
      this.cartService.setDataAfterSuccessPay();
      this.CollectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.CHECKOUT);
      this.notifyPopupsService.launchNotification('invoice-paid');
      return;
    }

    const employeeSelectPageIsActive = pageInstance === ROUTE_URLS.employee;
    const isSuccessPage = this.CollectionViewService.getCurrentSidebarSlide() === CHECKOUT_STEPS.SUCCESS;
    if (!isSuccessPage && !checkoutPageIsActive && !employeeSelectPageIsActive) {
      this.navController
        .navigateForward(ROUTE_URLS.checkout, {
          animated: true,
          animation: mdTransitionAnimation,
          animationDirection: 'forward',
        })
        .then(() => {
          this.CollectionViewService.setCurrentSidebarSlide(CHECKOUT_STEPS.SUCCESS);
        })
        .catch((err) => this.logger.error(err, 'setPageForPaidInvoiceOnOtherDevice:navigateForward:checkout'));
    }
  }

  public checkMyPOSConnection() {
    if (this.myPosService.isMyPosTerminal) {
      const companyData = this.SecurityService.getLoggedCompanyData();
      const currency = companyData ? companyData.locale.currency : null;
      const language = this.TranslateService.currentLang;
      this.myPosMiniFacade.connectAndGetPrinter(currency, language).catch((err) => {
        this.logger.error(err, 'checkMyPOSConnection:connect');
        this.LocalStorage.remove(StorageKeys.activeTerminal);
      });
    }
  }

  public checkOnUserIdleOccur(): void {
    this.logger.debug('Start checkOnUserIdleOccur');
    this.MenuController.close().catch((err) => this.logger.error(err, 'logout:MenuController:close'));
    this.NullifyModalService.destroyModal();
    this.ModalService.dismissAll();
    this.AlertService.dismissAll();
    this.actionSheetService.dismiss();
    this.IntercomService.shutdown().catch((err) => this.logger.error(err, 'checkOnUserIdleOccur:IntercomService:shutdown'));
    this.InvoicesService.removeOldInvoicesFromLocalDb();
    if (this.SecurityService.getActiveEmployee()) {
      if (this.appIsActive) {
        this.logout();
      }
      this.forceUserLogoutOnResume = !this.appIsActive;
    }
    this.logger.info('Finish checkOnUserIdleOccur');
  }

  private logout() {
    this.logger.debug('Start logout');
    this.SecurityService.setActiveEmployee(null);
    this.ngZoneService.run(() =>
      this.navController
        .navigateForward(ROUTE_URLS.employee)
        .catch((err) => this.logger.error(err, 'logoutAndRedirectToLoginPage:navigateRoot:login'))
    );
    this.logger.info('Finish logout');
  }

  public logoutAndRedirectToLoginPage(shouldReload: boolean = false) {
    this.logger.debug('Start logoutAndRedirectToLoginPage');
    this.authApiService.logout().catch((err) => this.logger.error(err, 'logoutAndRedirectToLoginPage:authApiService:logout'));
    this.SecurityService.setLoggedUserData(null);
    this.SecurityService.setActiveStore(null);
    this.SecurityService.setActiveEmployee(null);
    this.customerDisplayService.turnOff().catch();
    this.updatesService.stopUpdates();
    this.AdminPageService.logout();
    this.navController
      .navigateRoot(ROUTE_URLS.login, { animated: true, animationDirection: 'back' })
      .catch((err) => this.logger.error(err, 'logoutAndRedirectToLoginPage:navigateRoot:login'));
    if (shouldReload) {
      setTimeout(() => {
        this.logger.debug('location.reload');
        window.location.reload();
      }, 400);
    }
    this.logger.info('Finish logoutAndRedirectToLoginPage');
  }

  public resetCompany(shouldReload: boolean = false) {
    this.logger.debug('Start resetCompany');
    this.SecurityService.resetCompany()
      .then(() => {
        const companyData = this.SecurityService.getLoggedCompanyData();
        if (companyData) {
          this.dbDaoUtils
            .setDbVersion(APP_INFO.MIN_DB_VERSION, companyData.uuid)
            .catch((err) => this.logger.error(err, 'resetCompany:DbDaoService:setDbVersion'));
        }
        this.updatesService.removeLocalVersions();
        this.updatesService.removeLocalUpdatesLinks();
        this.posSettingsService.resetCompanyData();
        this.logoutAndRedirectToLoginPage(shouldReload);
        this.logger.info('Finish resetCompany');
      })
      .catch((err) => this.logger.error(err, 'resetCompany:resetCompany'));
  }

  private reConnectToMprime() {
    try {
      const terminal = this.MprimeService.geTerminalFromLocalStorage();
      this.MprimeService.saveTerminal(terminal.terminalId, terminal.ipAddress);
      this.MprimeService.toggleTippingOnTerminal(terminal.tipAllowed);
    } catch (err) {
      this.logger.warning("Can't connect to mPrime");
    }
  }

  private checkWebViewVersion() {
    // tslint:disable-next-line: no-string-literal
    window.plugins['webViewChecker']
      .getAndroidWebViewPackageInfo()
      .then(async (packageInfo) => {
        // tslint:disable-next-line: radix
        if (parseInt(packageInfo.versionName.split('.')[0]) < APP_INFO.MIN_WEBVIEW_VERSION) {
          const alert = await this.AlertService.create({
            header: this.TranslateService.instant('webview_version_mismatch_title'),
            message: this.TranslateService.instant('webview_version_mismatch_message'),
            backdropDismiss: false,
          });
          alert.present().catch((err) => this.logger.error(err, 'runApp:checkWebViewVersion:alert:present'));
        }
      })
      .catch((err) => {
        this.logger.error(err, 'runApp:checkWebViewVersion:webViewChecker:getAndroidWebViewPackageInfo');
      });
  }

  private setupStatusBar() {
    const style = this.statusBarService.getStatusBarStyle();
    this.statusBarService.setStyle({ style }).catch((error) => this.logger.error(error, 'setupStatusBar:setStyle'));

    this.statusBarService.setBackgroundColor({ color: '#16284D' }).catch((e) => this.logger.error(e, 'setupStatusBar:setBackgroundColor'));
  }

  private async hasLokiDbMigrationProblem(company: Company, companyUuid: string) {
    if (!!company || this.platformService.isWeb) {
      return false;
    }
    return this.dbDaoUtils.hasLokiDbFiles(companyUuid);
  }
}
