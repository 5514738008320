import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { iOSPermissions } from '@pos-common/constants/ios-permissions.enum';
import { PermissionTypes } from '@pos-common/constants/permission-types.enum';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { IStoragePermission } from '@pos-common/interfaces/storage-permission.interface';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { LogService } from '../logger/log.service';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { OPEN_SETTINGS } from '@pos-common/constants/open-settings.enum';
import { ANDROID_PERMISSION, PermissionState } from '@pos-common/constants';
import { requestCameraPermissions } from '@pos-common/classes';

@Injectable()
export class PermissionService {
  private permissions = new Map<PermissionTypes, string[]>();
  private readonly logger = this.logService.createLogger('PermissionService');
  constructor(
    private androidPermissions: AndroidPermissions,
    private platformService: PlatformService,
    private localStorage: LocalStorage,
    private openNativeSettings: OpenNativeSettings,
    private logService: LogService
  ) {
    this.setAllPermitions();
  }

  private setAllPermitions() {
    const PERMISSION = { ...this.androidPermissions.PERMISSION, ...ANDROID_PERMISSION };
    this.permissions.set(PermissionTypes.CAMERA, [PERMISSION.CAMERA, iOSPermissions.CAMERA]);
    this.permissions.set(PermissionTypes.ACCESS_COARSE_LOCATION, [PERMISSION.ACCESS_COARSE_LOCATION, iOSPermissions.LOCATION]);
    this.permissions.set(PermissionTypes.BLUETOOTH_CONNECT, [PERMISSION.BLUETOOTH_CONNECT, iOSPermissions.BLUETOOTH_CONNECT]);
    this.permissions.set(PermissionTypes.BLUETOOTH_SCAN, [PERMISSION.BLUETOOTH_SCAN, iOSPermissions.BLUETOOTH_SCAN]);
  }

  async checkPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    const permissions = this.permissions.get(permissionTypes);
    if (this.platformService.isWeb || !permissions) {
      return false;
    }
    const [androidPermission, iOSPermission] = permissions;
    if (this.platformService.isAndroid) {
      return this.checkAndroidPermission(androidPermission);
    }
    return this.checkiOSPermission(iOSPermission);
  }

  private checkAndroidPermission(permission: string) {
    return this.androidPermissions.checkPermission(permission).then((result) => result.hasPermission);
  }

  private async checkiOSPermission(permission: string): Promise<boolean> {
    switch (permission) {
      case iOSPermissions.CAMERA:
        return requestCameraPermissions().then((status) => status === PermissionState.granted);
      default:
        return true;
    }
  }

  async requestPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    if (this.platformService.isWeb) {
      return true;
    }
    const permissions = this.permissions.get(permissionTypes);
    if (permissions && this.platformService.isAndroid) {
      const [androidPermission] = permissions;
      return this.androidPermissions.requestPermission(androidPermission).then((result) => result.hasPermission);
    }
    return false;
  }

  checkAndRequestPermission(permissionTypes: PermissionTypes): Promise<boolean> {
    return this.checkPermission(permissionTypes).then((hasPermission) => {
      if (!hasPermission) {
        return this.requestPermission(permissionTypes);
      }
      return hasPermission;
    });
  }

  async openSettings(setting: OPEN_SETTINGS, isNewActivity: boolean = false): Promise<any> {
    if (this.platformService.isWeb) {
      return { status: true };
    }
    return this.openNativeSettings.open([setting, isNewActivity]);
  }

  checkLocationPermission() {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const hasPermission = await this.checkPermission(PermissionTypes.ACCESS_COARSE_LOCATION);
        const permission: IStoragePermission = this.localStorage.getObject(StorageKeys.permissions);
        permission.location = hasPermission;
        this.localStorage.setObject(StorageKeys.permissions, permission);
        resolve(hasPermission);
      } catch (error) {
        this.logger.error(error, 'checkLocationPermission');
        reject(error);
      }
    });
  }
}
