import { AdyenTerminalInfo, AdyenTerminalSettingsInfo } from '../../classes';
import { AdyenApiHelper } from './adyen-api-helper.service';
import { Injectable } from '@angular/core';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { ApiTerminals, ApiTerminalSettings } from '../../adyen-types';
import { LoadingService } from '@pos-common/services/system/loading.service';
import { LogService } from '@pos-common/services/system/logger';

@Injectable()
export class AdyenTerminalApi {
  private readonly baseUrl = SERVER_CONFIG.ADYEN_MANAGEMENT_URL;
  private readonly logger = this.logService.createLogger('AdyenTerminalApi');

  constructor(
    private readonly apiHelper: AdyenApiHelper,
    private readonly loadingService: LoadingService,
    private logService: LogService
  ) {}

  getTerminals(options: { merchantId: string }): Promise<AdyenTerminalInfo[]> {
    const url = `${this.baseUrl}terminals?merchantIds=${options.merchantId}`;
    this.loadingService.showLoadingItem();
    return this.apiHelper
      .get(url, { errorHandler: this.processError.bind(this) })
      .then((terminals: ApiTerminals) => {
        terminals.data = terminals?.data || [];
        return terminals.data.map(({ deviceModel, serialNumber }) => new AdyenTerminalInfo(deviceModel, serialNumber));
      })
      .finally(() => this.loadingService.hideLoadingItem());
  }

  getTerminalSettings(deviceId: string): Promise<AdyenTerminalSettingsInfo> {
    const url: string = `${this.baseUrl}/terminals/${deviceId}/terminalSettings`;

    const defaultSettings: AdyenTerminalSettingsInfo = new AdyenTerminalSettingsInfo(deviceId, false);

    this.loadingService.showLoadingItem();
    return this.apiHelper.get(url, { errorHandler: this.processError.bind(this) })
      .then((current: ApiTerminalSettings): AdyenTerminalSettingsInfo => {

          if (!current) {
            this.logger.error(new Error('Adyen device settings'), `Could not get the settings for the device id ${deviceId}`);
          }

          return current ? new AdyenTerminalSettingsInfo(
            deviceId,
            current.gratuities.length > 0
          ) : defaultSettings;

      })
      .finally(() => this.loadingService.hideLoadingItem())

  }

  private processError(error: unknown): Error {
    const apiErrorDetails = this.decodeApiErrorDetails(error);
    if (!apiErrorDetails) {
      this.logger.error(error, 'Unknown api error');
      return new Error('Unknown api error');
    }
    return new Error(apiErrorDetails.title);
  }

  private decodeApiErrorDetails(error: any) {
    if (typeof error !== 'object') return undefined;
    if (!error.error || typeof error.error !== 'string') return undefined;

    const errorDetailsString = error.error;

    try {
      const errorDetails: { title: string } = JSON.parse(errorDetailsString);
      return errorDetails;
    } catch (e) {
      return undefined;
    }
  }
}
