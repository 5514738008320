import { Injectable } from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';
import { SelfOrderInvoiceStatusTypesEnum } from '@pos-common/constants';
import { SelfOrdersApiService } from '@pos-common/services/api/self-orders-api.service';

@Injectable()
export class SelfOrdersService {

  constructor(private selfOrderApi: SelfOrdersApiService) { }

  updatePreparationStatusFromCheckoutPage(invoice: Invoice, status: SelfOrderInvoiceStatusTypesEnum): void {
    const uuid: string = invoice?.selfOrder?.uuid;
    const fulfillmentState: SelfOrderInvoiceStatusTypesEnum = status;

    this.selfOrderApi.updatePreparationStatus({ uuid, fulfillmentState }).then();
  }

  updateSelfOrderService(currentItem: { properties: Invoice }): Invoice {
    return {
      ...currentItem.properties,
      selfOrder: {
        ...currentItem.properties.selfOrder,
        uuid: currentItem.properties.sourceUuid
      }
    } as Invoice;
  }
}
