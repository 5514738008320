/**
 * Created by maksymkunytsia on 10/11/16.
 */

export const SALES_CHANNEL_TYPES = {
  POS: 'POS',
  WEBSHOP: 'WEBSHOP', // TODO deprecate for 2.7.0, but needed for 2.6.0
  ONLINE: 'ONLINE',
  MAIL: 'MAIL'
};
