import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { SunmiDeviceModel, SunmiPrinterWidth } from './sunmi.service.model';

@Injectable()
export abstract class ISunmiService {
  abstract get isSunmiDevice(): boolean;
  abstract get sunmiDeviceModel(): SunmiDeviceModel;
  abstract get hasPrinter(): boolean;
  abstract get hasAppScanner(): boolean;
  abstract get hasBroadcastScanner(): boolean;
  abstract get printerWidth(): SunmiPrinterWidth;
}

@Injectable()
export class SunmiService implements ISunmiService {
  private readonly _isSunmiDevice: boolean;
  private readonly _sunmiDeviceModel: SunmiDeviceModel;

  constructor(private device: Device) {
    let manufacturer = this.device?.manufacturer || '';
    manufacturer = manufacturer.toLowerCase();
    this._isSunmiDevice = manufacturer === 'sunmi';
    this._sunmiDeviceModel = this.detectSunmiModel();
  }

  private detectSunmiModel(): SunmiDeviceModel {
    let model = this.device?.model || '';
    model = model.toLocaleLowerCase();

    if (model.includes('v2splus')) return SunmiDeviceModel.V2sPlus;
    if (model.includes('v2s')) return SunmiDeviceModel.V2s;
    if (model.includes('t2s')) return SunmiDeviceModel.T2s;
    if (model.includes('d3mini')) return SunmiDeviceModel.D3Mini;
    if (model.includes('d3 mini')) return SunmiDeviceModel.D3Mini;

    return SunmiDeviceModel.Unknown;
  }

  get isSunmiDevice() {
    return this._isSunmiDevice;
  }

  get sunmiDeviceModel() {
    return this._sunmiDeviceModel;
  }

  get hasPrinter() {
    switch (this._sunmiDeviceModel) {
      case SunmiDeviceModel.T2s:
      case SunmiDeviceModel.V2s:
      case SunmiDeviceModel.V2sPlus:
      case SunmiDeviceModel.D3Mini:
        return true;
      case SunmiDeviceModel.Unknown:
        return false;
    }
  }

  get hasAppScanner() {
    switch (this._sunmiDeviceModel) {
      case SunmiDeviceModel.V2s:
      case SunmiDeviceModel.V2sPlus:
        return true;
      case SunmiDeviceModel.T2s:
      case SunmiDeviceModel.Unknown:
        return false;
    }
  }

  get hasBroadcastScanner() {
    switch (this._sunmiDeviceModel) {
      case SunmiDeviceModel.V2s:
      case SunmiDeviceModel.V2sPlus:
      case SunmiDeviceModel.T2s:
      case SunmiDeviceModel.D3Mini:
        return true;
      case SunmiDeviceModel.Unknown:
        return false;
    }
  }

  get printerWidth() {
    switch (this._sunmiDeviceModel) {
      case SunmiDeviceModel.T2s:
        return SunmiPrinterWidth.w80mm;
      case SunmiDeviceModel.V2s:
        return SunmiPrinterWidth.w40mm;
      default:
        return SunmiPrinterWidth.w58mm;
    }
  }
}
