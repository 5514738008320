import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AdyenPaymentService } from './services/adyen-payment.service';
import { AdyenTerminalProvider } from './adyen-terminal-provider';
import { AdyenPaymentApi, AdyenTerminalApi, AdyenApiHelper } from './services/api';
import { AdyenUtils } from './adyen-utils';
import { AdyenScannerService } from './services/adyen-scanner.service';
import { AdyenTippingService } from './services/adyen-tipping.service';
import { AdyenAcquirerDataService } from './services/adyen-acquirer-data.service';

@NgModule({
  providers: [
    AdyenPaymentService,
    AdyenPaymentApi,
    AdyenTerminalProvider,
    AdyenTerminalApi,
    AdyenApiHelper,
    AdyenUtils,
    AdyenScannerService,
    AdyenTippingService,
    AdyenAcquirerDataService,
  ],
})
export class AdyenModule {
  constructor(@Optional() @SkipSelf() parentModule: AdyenModule) {
    if (parentModule) {
      throw new Error('AdyenModule is already loaded. Import it in the AppModule only');
    }
  }
}
