import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MyposGlassService } from './mypos-glass.service';

@NgModule({
  providers: [
    MyposGlassService,
  ],
})
export class MyposGlassModule {
  constructor(@Optional() @SkipSelf() parentModule: MyposGlassModule) {
    if (parentModule) {
      throw new Error('MyposGlassModule is already loaded. Import it in the AppModule only');
    }
  }
}
