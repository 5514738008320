import { Directive, ElementRef, Input, NgModule, OnInit } from '@angular/core';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnInit {
  private focus = true;
  @Input() set autofocus(value: boolean) {
    this.focus = value !== false;
  }
  private inputKey = 'input';
  constructor(private host: ElementRef, private setTimeoutUtil: SetTimeoutUtil) {}
  ngOnInit(): void {
    this.setFocus();
  }

  private setFocus() {
    let element = this.host.nativeElement as HTMLElement;
    if (!this.isInput(element)) {
      element = element.querySelector(this.inputKey);
    }

    if (element && this.focus) {
      this.setTimeoutUtil.addVisualEffect(100).then(() => {
        element.focus();
      });
    }
  }

  private isInput(element: HTMLElement) {
    return element.tagName.toLocaleLowerCase() === this.inputKey;
  }
}
@NgModule({
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective],
})
export class AutofocusDirectiveModule {}
