/**
 * Created by ap on 15/03/18.
 */

import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChangeDetectionStrategy, Renderer2, ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';
import { ActionCallbackInterface, ProcessActionInterface } from '../../interfaces';
import { PaymentProcessingUserActionInterface } from './payment-processing-user-action.interface';
import { PaymentProcessingActions } from './payment-processing-actions.enum';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { PaymentProcessingUserActions } from './payment-processing-user-actions.enum';

@Component({
  selector: 'payment-processing',
  styleUrls: ['payment-processing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'payment-processing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubSinkService],
})
export class PaymentProcessing {
  private actionCallback: ActionCallbackInterface;

  public activeScreen: PaymentProcessingActions;
  public terminalMsg: string;
  public heightChange: any;
  public fadeAnim: boolean = false;
  public retryButtonOff: boolean;
  public cancelButtonOff: boolean;

  @Input() onUserAction: ActionCallbackInterface;
  @Input() actionsDispatcher: Observable<ProcessActionInterface>;
  @Input() providerDataStream: Observable<string>;

  @ViewChild('sHeight', { static: true }) sHeight: ElementRef;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private setTimeoutUtil: SetTimeoutUtil,
    private subSinkService: SubSinkService
  ) {}

  ngOnInit() {
    let timeout = 0;
    this.subSinkService.sink = this.actionsDispatcher.subscribe((event) => {
      if (!event?.action) {
        return;
      }
      if (event.action === PaymentProcessingActions.close) {
        this.onUserActionEvent({ type: PaymentProcessingUserActions.close });
        return;
      }
      this.terminalMsg = '';
      this.fadeAnim = false;
      this.setTimeoutUtil.waitTimeAndDo(timeout).then(() => {
        timeout = 200;
        this.fadeAnim = true;
        this.activeScreen = event.action;
        if (event.configuration?.message) {
          this.terminalMsg = event.configuration.message;
        }
        this.cancelButtonOff = event.configuration.cancelButtonOff;
        this.retryButtonOff = event.configuration.retryButtonOff;
        this.actionCallback = event.callback;
        this.maxHeight();
      });
      this.maxHeight();
    });

    this.subSinkService.sink = this.providerDataStream.subscribe((data) => {
      this.terminalMsg = data;
      this.maxHeight();
    });
  }

  private maxHeight() {
    this.setTimeoutUtil.addVisualEffect(50).then(() => {
      const maxHeight = this.sHeight.nativeElement.offsetHeight + 20
      this.renderer.setStyle(this.sHeight.nativeElement.parentElement, 'max-height', maxHeight + 'px');
    });
    this.cdr.detectChanges();
  }

  onUserActionEvent(event: PaymentProcessingUserActionInterface) {
    this.onUserAction(event);
    if (this.actionCallback) {
      this.actionCallback(event);
    }
  }
}
