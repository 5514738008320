import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, NgModule } from '@angular/core';
import { CompanyProperties } from '@pos-common/constants';
import { InvoicesFilterService } from '@pos-common/services/system/invoice-filter.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { SubSinkService } from '@pos-common/services/system/sub-sink/sub-sink.service';
import { TableEnforceService } from '@pos-common/services/system/table-select-enforcement.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';
import { fadeElm } from 'app/app.animations';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'no-invoices',
  templateUrl: './no-invoices.component.html',
  styleUrls: ['./no-invoices.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeElm()],
  providers: [SubSinkService],
})
export class NoInvoicesComponent implements OnInit {
  @Input() isPossibleAddInvoice: boolean = true;
  @Input() isOnlineInvoice: boolean = false;
  public currentFilterSet: any = {};
  public showContent: boolean = false;
  public isTableForce: boolean = false;

  constructor(
    private tableEnforceService: TableEnforceService,
    private securityService: SecurityService,
    private invoicesFilterService: InvoicesFilterService,
    private setTimeoutUtil: SetTimeoutUtil,
    private subSinkService: SubSinkService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isTableForce = this.tableEnforceService.checkForTableEnforcement();
    this.securityService
      .observableCompanyProperties(CompanyProperties.tablesEnforced)
      .pipe(this.subSinkService.takeUntilDestroy())
      .subscribe((company) => {
        this.isTableForce = company.tablesEnforced;
        this.cdr.detectChanges();
      });
    this.invoicesFilterService
      .getInvoiceFilter()
      .pipe(this.subSinkService.takeUntilDestroy())
      .subscribe((data) => {
        if (data && data.tab) {
          this.currentFilterSet = data;
          this.cdr.detectChanges();
        }
      });
    this.setTimeoutUtil.addVisualEffect(100).then(() => {
      this.showContent = true;
      this.cdr.detectChanges();
    });
  }

  addNewInvoice() {
    const { table, tab } = this.currentFilterSet;
    if (tab === 'paid' || tab === 'open' || tab === 'no' || table === 'all') {
      this.invoicesFilterService.newInvoice();
      return;
    }
    this.invoicesFilterService.newTableInvoice();
  }
}
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
  ],
  declarations: [NoInvoicesComponent],
  exports: [NoInvoicesComponent],
})
export class NoInvoicesComponentModule {}
