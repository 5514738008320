import { ModuleWithProviders, NgModule } from '@angular/core';
import { GiftCardsApiService } from './gift-cards-api.service';
import { InvoicesApiService } from './invoices-api.service';
import { CustomersApiService } from './customers-api.service';
import { AuthApiService } from './auth-api.service';
import { PosApiService } from './pos-api.service';
import { ReportApiService } from './report-api.service';
import { EmployeesApiService } from './employees-api.service';
import { StoreApiService } from './store-api.service';
import { AppointmentApiService } from './appointment-api.service';
import { AdyenApiService } from '@pos-common/services/api/adyen-api.service';
import { PaymentMethodsApiService } from './payment-methods-api.service';
import { ShiftsApiService } from './shifts-api.service';
import { SelfOrdersApiService } from '@pos-common/services/api/self-orders-api.service';

@NgModule()
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        AuthApiService,
        CustomersApiService,
        InvoicesApiService,
        GiftCardsApiService,
        ReportApiService,
        PosApiService,
        EmployeesApiService,
        StoreApiService,
        AppointmentApiService,
        AdyenApiService,
        PaymentMethodsApiService,
        ShiftsApiService,
        SelfOrdersApiService
      ],
    };
  }
}
