import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from './log.service';
import * as Sentry from '@sentry/capacitor';

@Injectable()
export class PaymashErrorHandler implements ErrorHandler {
  private readonly logger = this.logService.createLogger('PaymashErrorHandler');

  constructor(private logService: LogService) {
    window.onerror = (message: string, filename?: string, lineno?: number, colno?: number, error?: Error) => {
      this.handleError(this.formatBrowserError(message, error));
    };
  }

  handleError(error: any): void {
    try {
      this.logger.error(error, 'an unknown error occurred');
      Sentry.captureException(error.originalError || error);
    } catch (err) {
      console.error('PaymashErrorHandler: this should not happen', error); // eslint-disable-line no-console
    }
  }

  private formatBrowserError(message, error) {
    if (message instanceof Error) {
      return message;
    } else if (error instanceof Error) {
      return error;
    } else if (!!message && message.error instanceof Error) {
      return message.error;
    } else if (typeof message === 'string') {
      return message;
    } else if (message instanceof Object && !!message.error) {
      return message.error;
    } else {
      return message;
    }
  }
}
