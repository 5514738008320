import { Injectable } from '@angular/core';
import { NullifyModalService } from '../../services/system/nullify-modal.service';
import { PaymentProcessing } from './payment-processing.component';

import {
  ActionCallbackInterface,
  PaymentActionConfiguration,
  PaymentProcessingModalConfigInterface,
  ProcessActionInterface,
} from '../../interfaces/index';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaymentProcessingUserActionInterface } from './payment-processing-user-action.interface';
import { PaymentProcessingUserActions } from './payment-processing-user-actions.enum';
import { PaymentProcessingActions } from './payment-processing-actions.enum';
import { ModalController } from '@ionic/angular';
import { AdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

@Injectable()
export class PaymentProcessingService {
  private processAction: Subject<ProcessActionInterface> = new BehaviorSubject(null);
  private additionalDataProvider: Subject<string> = new Subject();
  private modalPaymentProcessModal: HTMLIonModalElement;

  get process(): Observable<ProcessActionInterface> {
    return this.processAction.asObservable();
  }

  get additionalData(): Observable<string> {
    return this.additionalDataProvider.asObservable();
  }

  // callbacks

  private onSignatureCallback: (result: string) => void = () => {};

  // constructor

  constructor(
    private nullifyModalService: NullifyModalService,
    private modalCtrl: ModalController,
    private adyenService: AdyenService
  ) {}

  // public

  public init(onRetry: () => void = () => {}, onClose: () => void = () => {}, onCheck: () => void = () => {}) {
    this.setupModalComponent({ onClose, onRetry, onCheck });
  }

  public dispatchAction(
    action: PaymentProcessingActions,
    configuration: PaymentActionConfiguration = {},
    callback?: ActionCallbackInterface
  ) {
    this.processAction.next({
      action,
      configuration,
      callback,
    });
  }

  public dispatchAdditionalData(data: string) {
    this.additionalDataProvider.next(data);
  }

  public dismissModal() {
    this.nullifyModalService.destroyModal();
    this.modalPaymentProcessModal?.dismiss();
    this.modalPaymentProcessModal = null;
  }

  // action callbacks

  public onSignature(callback: (result: string) => void) {
    this.onSignatureCallback = callback;
  }

  public handleUserAction(event: PaymentProcessingUserActionInterface, config: PaymentProcessingModalConfigInterface) {
    switch (event.type) {
      case PaymentProcessingUserActions.close:
        this.dismissModal();
        config.onClose();
        break;
      case PaymentProcessingUserActions.retry:
        config.onRetry();
        break;
      case PaymentProcessingUserActions.check:
        config.onCheck();
        break;
      case PaymentProcessingUserActions.drawSignatureDone:
        if (event.payload && typeof event.payload === 'string') {
          this.onSignatureCallback(<string>event.payload);
        }
        break;
    }
  }
  // private

  private async setupModalComponent(config: PaymentProcessingModalConfigInterface) {
    if (this.adyenService.isAdyenDevice) {
      this.adyenPaymentProcessFlow(config).then();
    } else {
      this.paymentProcessFlow(config);
    }
  }

  private paymentProcessFlow(config: PaymentProcessingModalConfigInterface) {

    if (this.nullifyModalService.isNullifyModalExist()) {
      return;
    }

    this.nullifyModalService.initModal({
      component: PaymentProcessing,
      options: {
        styles: { width: '500px' },
        verticalCenter: true,
        backdrop: true,
        notMobileFullScreen: true,
        closeBlocking: true,
      },
      inputs: [
        {
          config
        },
      ],
    }).then();
  }

  private async adyenPaymentProcessFlow(config: PaymentProcessingModalConfigInterface) {

    if (this.modalPaymentProcessModal) {
      return;
    }

    this.modalPaymentProcessModal = await this.modalCtrl.create({
      component: PaymentProcessing,
      componentProps: {
        adyenConfig: config
      },
      cssClass: 'payment-processing-popup',
      backdropDismiss: false
    });
    this.modalPaymentProcessModal.present();
  }

}
