import { BehaviorSubject, Subject } from 'rxjs';
import { ReceiptPrinter } from './classes/receipt-printer.class';

export interface ReceiptPrintersStoreAction {
  type: string;
  payload?: ReceiptPrinter | ReceiptPrinter[];
}

export enum ReceiptPrintersStoreActions {
  ADD_PRINTERS = 'ADD_PRINTERS',
  ADD_PRINTER = 'ADD_PRINTER',
  REMOVE_PRINTER = 'REMOVE_PRINTER',
  UPDATE_PRINTER_MODEL = 'UPDATE_PRINTER_MODEL',
  UPDATE_PRINTER_VIEW_DATA = 'UPDATE_PRINTER_VIEW_DATA',
  CLEAR_STORE = 'CLEAR_STORE',
}

export class ReceiptPrintersStore extends BehaviorSubject<ReceiptPrinter[]> {
  private dispatcher: Subject<ReceiptPrintersStoreAction>;

  constructor(initialState: ReceiptPrinter[]) {
    super(initialState);
    this.dispatcher = new Subject<ReceiptPrintersStoreAction>();
    this.dispatcher.subscribe((action: ReceiptPrintersStoreAction) => super.next(this.modifier(action)));
  }

  private removePrinter(printersList: ReceiptPrinter[], printerForDelete: ReceiptPrinter): ReceiptPrinter[] {
    return printersList.filter((printer) => printer.macAddress !== printerForDelete.macAddress);
  }

  private updatePrinterModel(printersList: ReceiptPrinter[], updatedPrinter: ReceiptPrinter): ReceiptPrinter[] {
    printersList.forEach((printer) => {
      if (ReceiptPrintersStore.compareMacAddresses(printer.macAddress, updatedPrinter.macAddress)) {
        printer.updateModel(updatedPrinter);
      }
    });
    return [...printersList];
  }

  private updatePrinterViewData(printersList: ReceiptPrinter[], updatedPrinter: ReceiptPrinter): ReceiptPrinter[] {
    printersList.forEach((printer) => {
      if (ReceiptPrintersStore.compareMacAddresses(printer.macAddress, updatedPrinter.macAddress)) {
        printer.updatePrinterViewData(updatedPrinter);
      }
    });
    return [...printersList];
  }

  private upsertPrinter(printersList: ReceiptPrinter[], updatedPrinter: ReceiptPrinter): ReceiptPrinter[] {
    const printerForUpsert: ReceiptPrinter = new ReceiptPrinter(updatedPrinter);
    let printerExist: boolean = false;
    printersList.forEach((printer) => {
      if (ReceiptPrintersStore.compareMacAddresses(printer.macAddress, printerForUpsert.macAddress)) {
        printerExist = true;
        printer.updateDeviceInfo(printerForUpsert);
        printer.isScannerActive = printerForUpsert.isScannerActive;
      }
    });
    const newPrintersList: ReceiptPrinter[] = [...printersList];
    if (!printerExist) newPrintersList.push(printerForUpsert);
    return newPrintersList;
  }

  private addMultiplePrinters(printersList: ReceiptPrinter[], newPrinters: ReceiptPrinter[]): ReceiptPrinter[] {
    const combineArrays = (p1, p2) => {
      if (p2.length === 0) return p1;
      const arrayToCombine = [...p2];
      const p = arrayToCombine.shift();
      const combined = this.upsertPrinter(p1, p);
      return combineArrays(combined, arrayToCombine);
    };
    return combineArrays(printersList, newPrinters);
  }

  private static compareMacAddresses(first: string, second: string): boolean {
    return first && second && first.toLowerCase() === second.toLowerCase();
  }

  private modifier(action: ReceiptPrintersStoreAction): ReceiptPrinter[] {
    const state = this.getState();
    switch (action.type) {
      case ReceiptPrintersStoreActions.ADD_PRINTERS:
        return this.addMultiplePrinters(state, <ReceiptPrinter[]>action.payload);
      case ReceiptPrintersStoreActions.ADD_PRINTER:
        return this.upsertPrinter(state, <ReceiptPrinter>action.payload);
      case ReceiptPrintersStoreActions.REMOVE_PRINTER:
        return this.removePrinter(state, <ReceiptPrinter>action.payload);
      case ReceiptPrintersStoreActions.UPDATE_PRINTER_MODEL:
        return this.updatePrinterModel(state, <ReceiptPrinter>action.payload);
      case ReceiptPrintersStoreActions.UPDATE_PRINTER_VIEW_DATA:
        return this.updatePrinterViewData(state, <ReceiptPrinter>action.payload);
      case ReceiptPrintersStoreActions.CLEAR_STORE:
        return [];
    }
  }

  public getState = (): ReceiptPrinter[] => this.value;

  public dispatch = (action: ReceiptPrintersStoreAction): void => this.dispatcher.next(action);
}
