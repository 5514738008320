export enum PaymentProcessingActions {
  connecting = 'connecting',
  processing = 'processing',
  succeed = 'succeed',
  retry = 'retry',
  provideCard = 'provideCard',
  providePin = 'providePin',
  provideSignature = 'provideSignature',
  drawSignature = 'drawSignature',
  close = 'close',
  warning = 'warning',
  check = 'check',
}
