import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LogService } from './logger/log.service';

export enum ToastMessageType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ToastMessagePositionType {
  top = 'top',
  bottom = 'bottom',
}

@Injectable()
export class ToastService {
  private readonly toastDuration: number = 3000;
  readonly toastPosition: 'top' | 'bottom' | 'middle' = 'bottom';
  private currentToast: HTMLIonToastElement;
  private readonly logger = this.logService.createLogger('ToastService');

  constructor(private toastCtrl: ToastController, private logService: LogService) {}

  async create(opts?: any): Promise<any> {
    opts = opts || {};
    const toast = await this.toastCtrl.create(opts);
    return this.getNewToast(toast);
  }

  async showMessage(message: string, type: ToastMessageType | string);
  async showMessage(message: string, type: ToastMessageType | string, duration?: number);
  async showMessage(message: string, type: ToastMessageType | string, duration = this.toastDuration) {
    let toast = await this.toastCtrl.create({
      message,
      position: ToastMessagePositionType.bottom,
      duration,
      cssClass: type,
    });
    toast = await this.getNewToast(toast);
    return toast.present();
  }

  private async getNewToast(toast: any) {
    if (this.currentToast) {
      try {
        await this.currentToast.dismiss();
      } catch (e) {
        this.logger.error(e, 'getNewToast:currentToast:dismiss');
      }
    }
    this.currentToast = toast;
    this.currentToast
      .onDidDismiss()
      .then(() => (this.currentToast = null))
      .catch((error) => this.logger.error(error, 'getNewToast:currentToast:onDidDismiss'));
    return toast;
  }
}
