import { Injectable } from '@angular/core';
import { PAYMASH_PROFILE } from '@profile';
import { ElasticLogger as ElasticLoggerBase } from '@paymash/logger-capacitor';
import { HttpClient as CapHttpClient } from '@spryrocks/capacitor-http-plugin';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ElasticLogger extends ElasticLoggerBase {
  constructor(private httpClient: HttpClient) {
    super(CapHttpClient, {
      serverUrl: `${SERVER_CONFIG.PUBLIC_URL}/logs`,
      system: `pm.pos.${PAYMASH_PROFILE.APP_ENV}`,
      username: PAYMASH_PROFILE.LOGGER.username,
      password: PAYMASH_PROFILE.LOGGER.password,
      debug: !PAYMASH_PROFILE.isProduction,
    });
  }
  async post(url: string, headers: { [p: string]: string }, data: unknown): Promise<void> {
    let newHeaders = new HttpHeaders();
    Object.keys(headers).forEach((key) => (newHeaders = newHeaders.append(key, headers[key])));
    await this.httpClient.post(url, data, { headers: newHeaders }).toPromise();
  }
}
