import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertOptions } from '../../interfaces/alert.inteface';
import { PopoverController } from '@ionic/angular';
import { LogService } from './logger/log.service';
import { TranslateService } from '@ngx-translate/core';
import { OPEN_SETTINGS } from '@pos-common/constants/open-settings.enum';
import { PermissionService } from './permission/permission.service';

@Injectable()
export class AlertService {
  private alertList: Array<HTMLIonAlertElement> = [];
  private popoverList: Array<HTMLIonPopoverElement> = [];
  private readonly logger = this.logService.createLogger('AlertService');

  constructor(
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private translateService: TranslateService,
    private permissionService: PermissionService,
    private logService: LogService
  ) {}

  public async create(opts?: AlertOptions): Promise<HTMLIonAlertElement> {
    opts = opts || {};
    opts.backdropDismiss = opts.backdropDismiss || false;
    const newAlert = await this.alertCtrl.create(opts);
    this.subscribeForDismiss(newAlert);
    this.alertList.push(newAlert);
    return newAlert;
  }

  public async createPopover(popoverComponent: any, data: any, event?: Event): Promise<HTMLIonPopoverElement> {
    const popover = await this.popoverCtrl.create({ component: popoverComponent, componentProps: data, event });
    this.subscribeForPopoverDismiss(popover);
    this.popoverList.push(popover);
    return popover;
  }

  public dismissAll() {
    for (let i = 0; i < this.alertList.length; i++) {
      this.alertList[i].dismiss().catch((err) => this.logger.error(err, 'dismissAll:alertList:dismiss'));
    }
    for (let i = 0; i < this.popoverList.length; i++) {
      this.popoverList[i].dismiss().catch((err) => this.logger.error(err, 'dismissAll:popoverList:dismiss'));
    }

    this.popoverCtrl
      .getTop()
      .then((popover) => popover && popover.dismiss())
      .catch((err) => this.logger.error(err, 'dismissAll:popoverCtrl:dismiss'));
  }

  private subscribeForDismiss(alert: HTMLIonAlertElement) {
    alert.onWillDismiss().then(() => this.removeAlertFromList(alert));
  }

  private removeAlertFromList(alert: HTMLIonAlertElement) {
    this.alertList = this.alertList.filter((a) => a.id !== alert.id);
  }

  private subscribeForPopoverDismiss(popover: HTMLIonPopoverElement) {
    popover.onWillDismiss().then(() => this.removePopoverFromList(popover));
  }

  //TODO Popover id error
  private removePopoverFromList(popover: any) {
    this.popoverList = this.popoverList.filter((p) => p.id !== popover.id);
  }

  public async showAlert(opts: { message?: string; header?: string; subHeader?: string; buttons?: string[] }) {
    const alert = await this.create({
      header: opts?.header ? this.translateService.instant(opts.header) : '',
      subHeader: opts?.subHeader ? this.translateService.instant(opts.subHeader) : '',
      message: opts?.message ? this.translateService.instant(opts.message) : '',
      buttons: opts?.buttons?.length ? [...opts.buttons] : ['OK'],
    });
    await alert.present();
    return alert;
  }

  public getLocationPermissionAlert() {
    return this.create({
      message: this.translateService.instant('alert_location_permisson_message'),
      buttons: [
        {
          text: this.translateService.instant('common_deny'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('common_accept'),
        },
      ],
    });
  }

  public getBackgroundLocationAlert(message?: string) {
    message = message ?? 'alert_background_location_permisson_message';
    return this.create({
      message: this.translateService.instant(message),
      buttons: [
        {
          text: this.translateService.instant('common_skip'),
          role: 'cancel',
        },
        {
          text: this.translateService.instant('common_update_settings'),
          handler: () => {
            this.permissionService
              .openSettings(OPEN_SETTINGS.ApplicationDetails)
              .catch((error) => this.logger.error(error, 'getBackgroundLocationAlert'));
          },
        },
      ],
    });
  }
}
