import { Injectable, Injector } from '@angular/core';
import { CommonPrinterService, OnDiscover, OnError } from '../common-printer.service';
import { StarPrintersScannerInterface } from '../../interfaces/star-printers-scanner.interface';
import { Subject, Observable } from 'rxjs';
import { ReceiptPrinter } from '../../classes/receipt-printer.class';
import { PrinterDto } from '../../dto/printer.dto';
import { StarPrinterPlugin, BarcodeReaderResultMessage } from '@paymash/capacitor-star-printer-plugin';
import { PrinterType } from '../../enum/printer-type.enum';

@Injectable()
export class StarPrinterService extends CommonPrinterService implements StarPrintersScannerInterface {
  private _scannerEvents: Subject<BarcodeReaderResultMessage> = new Subject<BarcodeReaderResultMessage>();

  constructor(injector: Injector) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return 'StarPrinterService';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  get scannerEvents(): Observable<BarcodeReaderResultMessage> {
    return this._scannerEvents.asObservable();
  }

  protected callDiscoverMethod(onDiscover: OnDiscover, onError: OnError) {
    StarPrinterPlugin.setDiscoverDevicesListener({
      onMessage: (data: any) => {
        if (data?.printers) {
          data.printers.forEach((printer) => (printer.deviceType = PrinterType.Star));
          onDiscover(data.printers);
        }
      },
      onError: (error) => onError(error),
    });
    StarPrinterPlugin.startDiscoverDevices().catch((error) => onError(error));
  }

  protected callStopDiscoverMethod(): Promise<void> {
    return StarPrinterPlugin.stopDiscoverDevices();
  }

  protected callPrintReceiptMethod(printer: PrinterDto, receipt: any[]): Promise<void> {
    return StarPrinterPlugin.print({ printer, receipt });
  }

  startBarcodeReader(device: ReceiptPrinter): Observable<BarcodeReaderResultMessage> {
    const printerDeviceName = this.isAndroid ? device.deviceName : device.printerModel;
    const printer = new PrinterDto(device.deviceType, device.target, printerDeviceName, device.ipAddress, device.macAddress);
    StarPrinterPlugin.setBarcodeMessageListener({
      onMessage: (message) => this._scannerEvents.next(message),
      onError: (error) => this.logger.error(error, 'setBarcodeMessageListener', undefined, { printer }),
    });
    StarPrinterPlugin.startBarcodeReader({ printer }).catch((error) =>
      this.logger.error(error, 'startBarcodeReader', undefined, { printer })
    );
    return this.scannerEvents;
  }

  stopBarcodeReader(): Promise<void> {
    return StarPrinterPlugin.stopBarcodeReader();
  }
}
