import { Injectable } from '@angular/core';
import { IMyPosReceiptItem } from '@pos-common/services/system/receipt-printers/classes/receipt-builder.class';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '@pos-common/constants/payment-providers.const';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import {
  MyPosTransactionCodeError,
  MyPosTransactionMessageError,
  MyPosTransactionResponseCode,
} from './constants/my-pos-transaction-models.enum';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { TranslateService } from '@ngx-translate/core';
import {
  IMyPosInfo,
  MyPosN5Plugin,
  IMyPosTransactionResult,
  MyPosScannerResult,
  MyposN5PluginError,
  MyPosScannerType,
} from '@paymash/capacitor-mypos-plugin';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { Observable } from 'rxjs';
import { DefaultPaymentMethods, LOCALE } from '@pos-common/constants';
import { MyPosMakeTransactionOptions } from './my-pos-types';

@Injectable()
export class MyPosFacade {
  private readonly logger = this.logService.createLogger('MyPosFacade');
  constructor(
    private paymentProcessingService: PaymentProcessingService,
    private translateService: TranslateService,
    private logService: LogService
  ) {}

  info(): Promise<IMyPosInfo> {
    return MyPosN5Plugin.info();
  }

  pay(options: MyPosMakeTransactionOptions): Promise<IMyPosTransactionResult> {
    const { amount, currency, invoiceUuid, paymentMethod } = options;
    return MyPosN5Plugin.pay({
      amount,
      currency,
      paymentMethod: paymentMethod === DefaultPaymentMethods.TWINT ? 'twint' : undefined,
      foreignTransactionId: invoiceUuid,
    });
  }

  refund(options: MyPosMakeTransactionOptions): Promise<IMyPosTransactionResult> {
    const { amount, currency, invoiceUuid, paymentMethod } = options;
    return MyPosN5Plugin.refund({
      amount: Math.abs(amount),
      currency,
      paymentMethod: paymentMethod === DefaultPaymentMethods.TWINT ? 'twint' : undefined,
      foreignTransactionId: invoiceUuid,
    });
  }

  print(printData: IMyPosReceiptItem[]): Promise<void> {
    return MyPosN5Plugin.print(printData);
  }

  startScanner(scannerType: MyPosScannerType): Observable<MyPosScannerResult> {
    return MyPosN5Plugin.startScanner(scannerType);
  }

  openCashDrawer(): Promise<void> {
    return MyPosN5Plugin.openCashDrawer();
  }

  async makeTransaction(options: MyPosMakeTransactionOptions): Promise<PaymentResult> {
    try {
      const { transactionType, amount, paymentMethod } = options;
      const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.MYPOS);
      const isRefund = transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0;
      if (paymentMethod === DefaultPaymentMethods.TWINT && !(await this.isEnabledTwint())) {
        return paymentResult;
      }
      const transactionResult: IMyPosTransactionResult = isRefund ? await this.refund(options) : await this.pay(options);
      paymentResult.setPaymentResultData(transactionResult);
      return paymentResult;
    } catch (error) {
      error = error as MyposN5PluginError;
      this.logger.error(error, 'makeTransaction');
      let { status, message, responseCode } = error;
      if (responseCode === MyPosTransactionResponseCode.NOT_SUPPORTED) {
        status = MyPosTransactionCodeError.TWINT_NOT_SUPPORTED;
      }
      message = MyPosTransactionMessageError[status] || message;
      if (status === MyPosTransactionCodeError.USER_CANCEL_PAYMENT) {
        throw message;
      }
      this.paymentProcessingService.init();
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
        message: this.translateService.instant(message),
        retryButtonOff: true,
      });
      throw message;
    }
  }

  private async isEnabledTwint() {
    const info = await this.info();
    return info.currencyName === LOCALE.Currency.CHF;
  }
}
