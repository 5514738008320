import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '../../constants/server.const';
import { ResetPasswordDataInterface } from '@pos-common/interfaces';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';

@Injectable()
export class AuthApiService {
  constructor(private securityService: SecurityService) {}

  public doLogin(loginData: Object) {
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_URL}authenticate`, 'post', loginData);
  }

  public resetPassword(data: ResetPasswordDataInterface): Promise<SecuredResponse> {
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_URL}employees/resetPassword`, 'post', data);
  }

  public getAccessToken() {
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_URL}authorization/accessToken`, 'post', null);
  }

  public logout() {
    return this.securityService.doSecureRequest(`${SERVER_CONFIG.API_URL}logout`, 'post', {});
  }

  public loadDemo(data: { currencyCode: string }) {
    return this.securityService.doSecureRequestObservable(`${SERVER_CONFIG.API_LEGACY_URL}demoAccount`, 'post', data);
  }

  public getIntercomUserHash(platform: string) {
    platform = platform.toUpperCase();
    return this.securityService
      .doSecureRequest(`${SERVER_CONFIG.API_V2_URL}intercom/verification?platform=${platform}`, 'get')
      .then((response) => response.data.hash);
  }
}
