export const SelfOrderShortOrderNumberUtil = (invoiceId: string): string => {
  const symbolOfDigits = 2;

  if (invoiceId) {

    const regexGetValueAfterDash = /-(.*)/g;

    const  fullNumber: string | undefined = invoiceId ? regexGetValueAfterDash.exec(invoiceId as string)?.[1] : '';
    return fullNumber ? fullNumber.length <= symbolOfDigits ? fullNumber : fullNumber.slice(-symbolOfDigits) : '';

  } else {
    return ''
  }

}
