import { Directive, ElementRef, HostListener, Input, NgModule, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';
import { EffectService } from '@pos-common/services/system/effect/effect.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Directive({
  selector: '[ripple-effect]',
})
export class RippleEffectDirective implements OnInit {
  @Input() rippleColor: string;
  @Input() rippleOpacity: string;
  @Input() rippleDuration: string;

  private hostElement: HTMLElement;
  private element: HTMLElement;
  private readonly className = 'ripple-effect';

  constructor(
    private renderer: Renderer2,
    el: ElementRef,
    private setTimeoutUtil: SetTimeoutUtil,
    private effectService: EffectService,
    private domController: DomController
  ) {
    this.hostElement = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.effectService.isDisableEffect()) {
      return;
    }
    this.renderer.addClass(this.hostElement, `pos-${this.className}`);
    this.addElement();
  }

  private addElement() {
    const element = this.renderer.createElement('div');
    this.effectService.setBackgroundColor(element, this.rippleColor);
    this.effectService.setOpacity(element, this.rippleOpacity);
    this.effectService.setAnimationDuration(element, this.rippleDuration);
    element.classList.add(this.className);
    this.element = element;
    this.hostElement.appendChild(element);
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    if (this.effectService.isDisableEffect()) {
      return;
    }
    this.handleClick(event);
  }

  private handleClick(event: any) {
    const className = `${this.className}-active`;
    this.domController.write(() => {
      this.setSize();
      this.setPosition(event);
      this.renderer.addClass(this.element, className);
    });
    this.setTimeoutUtil.addVisualEffect(500).then(() => {
      this.renderer.removeClass(this.element, className);
    });
  }

  private setPosition(event: any) {
    const { top, left } = this.effectService.getPosition(event, this.hostElement, this.element);
    this.renderer.setStyle(this.element, 'top', top);
    this.renderer.setStyle(this.element, 'left', left);
  }

  private setSize() {
    const dinstance = Math.max(this.hostElement.clientWidth, this.hostElement.clientHeight);
    this.renderer.setStyle(this.element, 'width', `${dinstance}px`);
    this.renderer.setStyle(this.element, 'height', `${dinstance}px`);
  }
}
@NgModule({
  declarations: [RippleEffectDirective],
  exports: [RippleEffectDirective],
})
export class RippleEffectDirectiveModule {}
