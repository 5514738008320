import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { PAYMASH_PROFILE } from '@profile';
import { LogService } from '../logger/log.service';
import { PlatformType } from '@pos-common/constants/platform-types.enum';
import { PlatformService } from '../platform/platform.service';
import {
  ScannerAppInfo,
  ScannerResultAction,
  SocketScannerPlugin,
  SocketScannerPluginError,
} from '@paymash/capacitor-socket-scanner-plugin';

export type SocketScannerDevice = { name: string; guid: string; batteryLevel: string | undefined };

@Injectable()
export class SocketScannerPluginProxy {
  private readonly logger = this.logService.createLogger('SocketScannerPluginProxy');
  constructor(private appVersion: AppVersion, private platformService: PlatformService, private logService: LogService) {}

  async connect(
    onDeviceConnected: (result: SocketScannerDevice) => void,
    onDeviceDisconnected: (guid: string) => void,
    onDataReceived: (data: string) => void,
    onError: (errorCode: number) => void
  ) {
    SocketScannerPlugin.setScannerListener({
      onMessage: (message) => {
        switch (message.action) {
          case ScannerResultAction.deviceConnected:
            onDeviceConnected({
              name: message.name,
              guid: message.guid,
              batteryLevel: message.batteryLevel,
            });
            break;
          case ScannerResultAction.deviceDisconnected:
            onDeviceDisconnected(message.guid);
            break;
          case ScannerResultAction.dataReceived:
            onDataReceived(message.data);
            break;
        }
      },
      onError: (error) => {
        if (error instanceof SocketScannerPluginError) {
          onError(error.code);
          return;
        }

        onError(SocketScannerPluginError.UnknownError);
      },
    });
    const appInfo = await this.loadAppInfo();
    await SocketScannerPlugin.startScanner(appInfo);
  }

  async disconnect() {
    await SocketScannerPlugin.stopScanner();
  }

  private loadAppInfo(): Promise<ScannerAppInfo> {
    return this.appVersion
      .getPackageName()
      .then((bundle) => {
        const { socketScanner } = PAYMASH_PROFILE;
        let prefix = '';
        let appKey = '';
        let developerId = socketScanner.developerId;

        if (this.platformService.isIOS) {
          prefix = PlatformType.iOS;
          appKey = socketScanner.iosAppKey;
        }

        if (this.platformService.isAndroid) {
          prefix = PlatformType.Android;
          appKey = socketScanner.androidAppKey;
          developerId = developerId.toUpperCase();
        }

        return {
          appId: `${prefix}:${bundle}`,
          developerId,
          appKey,
        };
      })
      .catch((error) => {
        this.logger.error(error, 'loadAppInfo');
        throw error;
      });
  }
}
