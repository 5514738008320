import { Component, OnInit, OnDestroy, ViewEncapsulation, NgModule, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NotifyPopupsService } from '@pos-common/services/system/notify-popups.service';
import { scaleElm } from 'app/app.animations';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SubSinkService } from '@pos-common/services/system';

@Component({
  selector: 'notify-popups',
  templateUrl: './notify-popups.component.html',
  styleUrls: ['./notify-popups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [scaleElm()],
  providers: [SubSinkService],
})
export class NotifyPopups implements OnInit, OnDestroy {
  public showNotify: boolean = false;
  public notifier: boolean = false;
  public notifyType: string = '';
  public payload: any = null;
  private timeout: NodeJS.Timeout | undefined;

  constructor(private notifyPopupsService: NotifyPopupsService, private subSinkService: SubSinkService, private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.notifyPopupsService.launchNotification(null);
  }

  ngOnInit() {
    this.notifyPopupsService
      .notificationEvents()
      .pipe(
        filter((data) => data?.event),
        this.subSinkService.takeUntilDestroy()
      )
      .subscribe((data) => {
        this.showNotify = true;
        this.notifier = true;
        this.notifyType = data.event;
        this.payload = data.payload;
        this.cdr.detectChanges();
        this.timeout = setTimeout(() => this.hideNotify(), 1800);
      });
  }

  hideNotify() {
    this.notifier = false;
    this.timeout && clearTimeout(this.timeout);
    this.cdr.detectChanges();
    setTimeout(() => {
      this.notifyPopupsService.launchNotification(null);
      this.showNotify = false;
      this.cdr.detectChanges();
    }, 200);
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [NotifyPopups],
  exports: [NotifyPopups],
})
export class NotifyPopupsModule {}
