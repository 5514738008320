/**
 * Created by maksymkunytsia on 9/1/16.
 */

import * as moment from 'moment';

import { InvoiceEntry } from './invoice-entry.class';
import { InvoicePayment } from './invoice-payment.class';
import { RECEIPT_TYPES } from '../constants/receipt-types';
import { SALES_CHANNEL_TYPES } from '../constants/sales-channel-types';
import { Address } from './address.class';
import { INVOICE_TYPES } from '../constants/invoice-types';
import { RksvReceipt } from './rksv-receipt.class';
import { MathUtils } from '../services/utils/math.utils';
import { LOCALE } from '../constants/locale.const';
import { Entity } from './entity.class';
import { DefaultPaymentMethods } from '../constants/default-payment-methods.enum';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { InvoiceTax } from './invoice-tax.class';
import { KassenSichVReceipt } from './kassenSichV-receipt.class';
import { DiscountCode } from './discount-code.class';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import {
  SalesSubChannelTypesEnum,
  SelfOrderInvoiceFulfillmentTypesEnum,
  SelfOrderInvoiceStatusTypesEnum,
} from '@pos-common/constants';
import { SelfOrderInInvoiceInterface } from '@pos-common/interfaces';

export class Invoice extends Entity {
  // general
  public publicUuid: string;
  public isDraft: boolean;
  public isPaid: boolean;
  public isPartiallyPaid: boolean;
  public invoiceId: string;
  public sequentId: number;
  public invoiceDisplayId: string;
  public isPrinted: boolean;
  public amount: number;
  public amountNotRounded: number;
  public date: string;
  public modificationDate: string;
  public isNet: boolean;
  public salesChannel: string;
  public subChannel: string;
  public receiptType: string;
  // discounts
  public discount: number;
  public discountPercentage: number;
  public customerDiscount: number;
  public customerDiscountPercentage: number;
  public discountCode: DiscountCode;
  public discountCodeDiscount: number;
  // other / references
  public employee: any;
  public customer: any;
  public customerUuid: string;
  public billingAddress: Address;
  public store: any;
  public inventoryStore: any;
  public mailTo: string;
  public fulfillmentState: string;
  public isWebshop: boolean;
  public isSelfOrder: boolean;
  // gastronomy
  public gastronomyTable: { uuid: string };
  public gastronomyTableName: string;
  // invoices entries/payments
  public invoiceEntries: InvoiceEntry[] = [];
  public invoicePayments: InvoicePayment[] = [];
  public invoiceTaxes: InvoiceTax[] = [];
  // cancellation
  public isCancelled: boolean;
  public isPartiallyCancelled: boolean;
  public cancellationReason: string;
  public cancellationStatus: string;
  public cancellingEmployee: any;
  public restockOnCancellation: boolean;
  public sourceUuid: string;
  // selfOrder info invoices
  public selfOrder?: SelfOrderInInvoiceInterface;
  // NOT SEND TO SERVER
  public totalInvoiceDiscountAmount: number;
  public totalCustomerDiscountAmount: number;
  public totalTaxesAmount: number;
  public totalProductsCount: number;
  public totalAmountWithoutTaxes: number;
  public totalAmountWithoutDiscount: number;
  //Refund
  public isRefund: boolean;
  public refundedAmount: number;
  public isSeparated: boolean;
  public invoiceType: string;
  public originalInvoiceReference: any;
  public cancellationInvoiceReference: any;
  public rksvReceipt: RksvReceipt;
  public kassenSichVReceipt: KassenSichVReceipt;
  public paymentMethod: string;
  public deleted: boolean;
  public rounding: number;

  constructor(data) {
    super(data);
    // general fields
    this.publicUuid = data.publicUuid || null;
    this.isDraft = data.isDraft || false;
    this.isPaid = data.isPaid || false;
    this.isPartiallyPaid = data.isPartiallyPaid || false;
    this.invoiceId = data.invoiceId || null;
    this.sequentId = data.sequentId || null;
    this.invoiceDisplayId = this.getDisplayId(this.sequentId, this.invoiceId);
    this.amount = data.amount || 0;
    this.amountNotRounded = data.amountNotRounded || 0;
    this.date = data.date ? moment.utc(data.date).toISOString() : null;
    this.modificationDate = moment.utc(data.modificationDate).toISOString() || moment.utc().toISOString();
    this.isNet = data.isNet || false;
    this.isPrinted = data.isPrinted || false;
    this.salesChannel = SALES_CHANNEL_TYPES[data.salesChannel] || SALES_CHANNEL_TYPES.POS;
    this.subChannel = data.subChannel || null;
    this.isWebshop = this.subChannel === SalesSubChannelTypesEnum.WEBSHOP;
    this.isSelfOrder = this.subChannel === SalesSubChannelTypesEnum.SELFORDER;
    // TODO ADJUST DEFAULT VALUE
    this.receiptType = RECEIPT_TYPES[data.receiptType] || RECEIPT_TYPES.EPSON;

    this.sourceUuid = data.sourceUuid;

    // selfOrder info invoices
    if (data.subChannel === SalesSubChannelTypesEnum.SELFORDER && data?.selfOrder && data?.sourceUuid) {
      this.selfOrder = this.generateSelfOrderInfo(data.selfOrder, data.sourceUuid);
    }
    // discounts
    this.discount = data.discount || 0;
    this.discountPercentage = data.discountPercentage || 0;
    this.customerDiscount = data.customerDiscount || 0;
    this.customerDiscountPercentage = data.customerDiscountPercentage || 0;
    this.discountCode = data.discountCode || null;
    this.discountCodeDiscount = data.discountCodeDiscount || 0;

    // other / references
    this.employee = data.employee || null;
    this.customer = data.customer || null;
    this.customerUuid = data.customerUuid || this.customer?.uuid || null;
    this.billingAddress = data.billingAddress || null;
    this.store = data.store || null;
    this.inventoryStore = data.inventoryStore || null;
    this.mailTo = data.mailTo || null;
    this.fulfillmentState = data.fulfillmentState || null;

    // gastronomy
    this.gastronomyTable = data.gastronomyTable || null;
    this.gastronomyTableName = data.gastronomyTableName || null;

    // invoice entries
    this.invoiceEntries = this.getItems(InvoiceEntry, data.invoiceEntries);
    // invoice payments
    this.invoicePayments = this.getItems(InvoicePayment, data.invoicePayments);
    // invoice taxes
    this.invoiceTaxes = this.getItems(InvoiceTax, data.invoiceTaxes);

    // cancellation
    this.isCancelled = data.isCancelled || false;
    this.isPartiallyCancelled = data.isPartiallyCancelled || false;
    this.cancellationReason = data.cancellationReason || null;
    this.cancellationStatus = data.cancellationStatus || null;
    this.cancellingEmployee = data.cancellingEmployee || null;
    this.restockOnCancellation = data.restockOnCancellation || false;

    // not send to server
    this.totalInvoiceDiscountAmount = data.totalInvoiceDiscountAmount || 0;
    this.totalCustomerDiscountAmount = data.totalCustomerDiscountAmount || 0;
    this.totalTaxesAmount = data.totalTaxesAmount || 0;
    this.totalProductsCount = data.totalProductsCount || 0;
    this.totalAmountWithoutTaxes = data.totalAmountWithoutTaxes || 0;
    this.totalAmountWithoutDiscount = data.totalAmountWithoutDiscount || 0;
    this.isRefund = data.isRefund || false;
    this.refundedAmount = data.refundedAmount || 0;
    this.isSeparated = data.isSeparated || false;
    this.invoiceType = data.invoiceType || INVOICE_TYPES.INVOICE;
    this.originalInvoiceReference = data.originalInvoiceReference;
    this.cancellationInvoiceReference = data.cancellationInvoiceReference;
    this.rksvReceipt = data.rksvReceipt ? new RksvReceipt(data.rksvReceipt) : null;
    this.kassenSichVReceipt = data.kassenSichVReceipt ? new KassenSichVReceipt(data.kassenSichVReceipt) : null;
    this.paymentMethod = data.paymentMethod || null;
    this.deleted = data.deleted || false;
    this.rounding = data.rounding || 0;
  }

  private getItems<T>(TCreator: new (data: any) => T, data: any[]): T[] {
    let result: T[] = [];
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        result = [...result, new TCreator(data[i])];
      }
    }
    return result;
  }

  public getAmountForPay(): number {
    const remainingAmount = MathUtils.roundHalfUp(this.amount - this.getPaymentsTotal(), 2);
    return remainingAmount >= 0 ? remainingAmount : 0;
  }

  public getPaymentsTotal(): number {
    if (this.invoicePayments.length === 0) {
      return 0;
    }
    const invoicePayments = this.getInvoicePayments();
    let invoicePaymentsTotal = 0;
    for (let i = 0; i < invoicePayments.length; i++) {
      const currentInvoicePayment = invoicePayments[i];
      let amount = currentInvoicePayment.amount;
      if (currentInvoicePayment.method === DefaultPaymentMethods.CASH) {
        amount = currentInvoicePayment.amountGiven;
      }
      invoicePaymentsTotal = MathUtils.normalizeAddition(invoicePaymentsTotal, amount);
    }
    return invoicePaymentsTotal;
  }

  public getAmountOfChange(): number {
    if (this.totalProductsCount === 0) {
      return 0;
    }
    const change = this.getPaymentsTotal() - this.amount;
    return change < 0 ? 0 : change;
  }

  public getInvoicePayments(): InvoicePayment[] {
    if (this.invoicePayments.length === 0) {
      return [];
    }
    return this.invoicePayments.filter((invoicePayment) => !invoicePayment.deleted);
  }

  getActiveInvoiceEntries(): InvoiceEntry[] {
    return this.invoiceEntries.filter((invoiceEntry) => !invoiceEntry.deleted);
  }

  hasDiscount(): boolean {
    return this.discount && this.discount !== 0;
  }

  hasDiscountPercentage(): boolean {
    return this.discountPercentage && this.discountPercentage !== 0;
  }

  hasCustomerDiscount(): boolean {
    return this.customer && this.customerDiscount && this.customerDiscount !== 0;
  }

  hasCustomerDiscountPercentage(): boolean {
    return this.customer && this.customerDiscountPercentage && this.customerDiscountPercentage !== 0;
  }

  hasAnyDiscount(): boolean {
    return this.hasDiscount() || this.hasDiscountPercentage() || this.hasCustomerDiscount() || this.hasCustomerDiscountPercentage();
  }

  private calculateTotalWithoutTaxes(amount: number, totalTaxesAmount: number) {
    this.totalAmountWithoutTaxes = MathUtils.normalizeSubtraction(amount, totalTaxesAmount);
  }

  private calculateDiscountedTaxesAndAmountForEntryBeforeGlobalDiscount() {
    this.totalProductsCount = 0;
    for (let i = 0; i < this.invoiceEntries.length; i++) {
      const entry = this.invoiceEntries[i];
      if (!entry.deleted && entry.type !== PRODUCT_TYPES.CATEGORY) {
        entry.calculateDiscountedPrice();
        entry.totalAmount = entry.discountedPrice * entry.quantity;
        this.totalProductsCount += Math.abs(entry.quantity);
      }
    }
  }

  calculateInvoiceAmountAfterDiscount(currentCompanyCurrencyRounding: string, originAmountInvoice: number = 0) {
    let amountWithoutDiscount: number = 0;
    let amountInclusiveDiscount: number = 0;
    let totalDiscountAmount: number = 0;
    let invoiceDiscountAmount: number = 0;
    let customerDiscountAmount: number = 0;

    // calculate invoiceEntry amounts
    this.calculateDiscountedTaxesAndAmountForEntryBeforeGlobalDiscount();
    amountWithoutDiscount = this.calculateAmountBeforeDiscount();

    // calculate the total global discount amount
    if (this.hasDiscount()) {
      const discount = this.getDiscount(amountWithoutDiscount, this.discount);
      invoiceDiscountAmount = this.calculateCancellationDiscount(amountWithoutDiscount, originAmountInvoice, discount);
    } else if (this.hasDiscountPercentage()) {
      const amountWithoutDiscountAndTips = this.getAmountWithoutDiscountAndTips(amountWithoutDiscount);
      invoiceDiscountAmount = InvoiceEntry.calculateDiscountAmount(amountWithoutDiscountAndTips, this.discountPercentage);
    }

    // calculate customer discount
    if (this.hasCustomerDiscount()) {
      const discount = this.getDiscount(amountWithoutDiscount, this.customerDiscount);
      customerDiscountAmount = this.calculateCancellationDiscount(amountWithoutDiscount, originAmountInvoice, discount);
    } else if (this.hasCustomerDiscountPercentage()) {
      const amountWithoutDiscountAndTips = this.getAmountWithoutDiscountAndTips(amountWithoutDiscount);
      const amountWithoutShippingCost = this.getAmountWithoutShippingCost(amountWithoutDiscountAndTips);
      customerDiscountAmount = InvoiceEntry.calculateDiscountAmount(amountWithoutShippingCost, this.customerDiscountPercentage);
    }

    totalDiscountAmount = invoiceDiscountAmount + customerDiscountAmount;

    // TODO ADD SUPPORT OF DISCOUNT CODES
    if (this.discountCode !== null) {
      totalDiscountAmount += this.discountCodeDiscount;
    }

    // subtract discount amount and add VATs for net Invoices
    amountInclusiveDiscount = MathUtils.normalizeSubtraction(amountWithoutDiscount, totalDiscountAmount);
    // do rounding consider user locale settings
    this.amountNotRounded = amountInclusiveDiscount;
    amountInclusiveDiscount = MathUtils.roundByFinalRoundingSize(
      amountInclusiveDiscount,
      LOCALE.CurrencyRounding[currentCompanyCurrencyRounding]
    );

    this.totalCustomerDiscountAmount = customerDiscountAmount;
    this.totalInvoiceDiscountAmount = invoiceDiscountAmount;
    this.totalAmountWithoutDiscount = amountWithoutDiscount < 0 ? 0 : amountWithoutDiscount;
    //TODO
    this.isRefund = amountInclusiveDiscount < 0;
    this.amount = amountInclusiveDiscount;
    this.refundedAmount = Math.abs(amountInclusiveDiscount);

    // for isNET invoices Invoice.amount will be calculated after calculateTaxesForEntriesAndInvoiceAfterGlobalDiscount()
    this.calculateTaxesForEntriesAndInvoiceAfterGlobalDiscount(currentCompanyCurrencyRounding);
    if (this.rounding || this.isDraft) {
      this.rounding = MathUtils.normalizeSubtraction(this.amount, this.amountNotRounded);
    }
    this.calculateTotalWithoutTaxes(this.amountNotRounded, this.totalTaxesAmount);
  }

  private calculateCancellationDiscount(amount: number, totalAmount: number, discount: number) {
    if (!totalAmount) {
      return discount;
    }
    const discountAbsolute = (amount / totalAmount) * discount;
    return MathUtils.roundHalfUp(discountAbsolute, 2);
  }

  private getDiscount(amount: number, discount: number) {
    return amount < discount ? amount : discount;
  }

  private getAmountWithoutDiscountAndTips(amountWithoutDiscount: number) {
    const tipsInvoiceEntry = this.getTippingInvoiceEntry();
    if (tipsInvoiceEntry) {
      return MathUtils.normalizeSubtraction(amountWithoutDiscount, tipsInvoiceEntry.totalAmount);
    }
    return amountWithoutDiscount;
  }

  getAmountWithoutShippingCost(amountWithoutDiscount: number) {
    const shoppingCostInvoiceEntry = this.getShippingCostInvoiceEntry();
    if (shoppingCostInvoiceEntry) {
      return MathUtils.normalizeSubtraction(amountWithoutDiscount, shoppingCostInvoiceEntry.totalAmount);
    }
    return amountWithoutDiscount;
  }

  private calculateTaxesForEntriesAndInvoiceAfterGlobalDiscount(currentCompanyCurrencyRounding: string) {
    const ratio = this.calculateInvoiceAmountToSumOfInvoiceEntryTotalsRatio();
    this.totalTaxesAmount = 0;
    for (let i = 0; i < this.invoiceEntries.length; i++) {
      const entry = this.invoiceEntries[i];
      if (!entry.deleted && entry.type !== PRODUCT_TYPES.CATEGORY) {
        const totalAmountForTax = entry.type === PRODUCT_TYPES.TIPS ? entry.totalAmount : entry.totalAmount * ratio;

        if (this.isNet) {
          entry.totalTaxAmount = MathUtils.roundHalfUp(totalAmountForTax * entry.taxRate, 3);
        } else {
          entry.totalTaxAmount = MathUtils.roundHalfUp(totalAmountForTax - totalAmountForTax / (entry.taxRate + 1), 3);
        }

        this.totalTaxesAmount += entry.totalTaxAmount;
      }
    }
    this.totalTaxesAmount = MathUtils.roundHalfUp(this.totalTaxesAmount, 2);

    if (this.isNet) {
      // do rounding consider user locale settings
      this.amountNotRounded = MathUtils.normalizeAddition(this.amountNotRounded, this.totalTaxesAmount);
      this.amount = MathUtils.roundByFinalRoundingSize(this.amountNotRounded, LOCALE.CurrencyRounding[currentCompanyCurrencyRounding]);
    }
  }

  calculateAmountBeforeDiscount(): number {
    const invoiceEntries = this.getActiveInvoiceEntriesWithoutTypes(PRODUCT_TYPES.CATEGORY);
    return invoiceEntries.reduce((result, invoiceEntry) => {
      return MathUtils.normalizeAddition(result, invoiceEntry.totalAmount);
    }, 0);
  }

  private calculateInvoiceAmountToSumOfInvoiceEntryTotalsRatio(): number {
    let amountBeforeDiscount = this.calculateAmountBeforeDiscount();
    let { amount } = this;
    if (this.hasDiscountPercentage() || this.hasCustomerDiscountPercentage()) {
      amountBeforeDiscount = this.getAmountWithoutDiscountAndTips(amountBeforeDiscount);
      amount = this.getAmountWithoutDiscountAndTips(amount);
    }

    if (amountBeforeDiscount === 0 || amountBeforeDiscount === amount) {
      return 1;
    } else {
      // TODO DISCOVER WHAT FORMAT AND LENGTH OF DECIMALS USE FOR RATIO
      return parseFloat((amount / amountBeforeDiscount).toFixed(4));
    }
  }

  public unlinkCustomerFromInvoice() {
    this.customer = null;
    this.billingAddress = null;
    this.customerDiscount = 0;
    this.customerDiscountPercentage = 0;
  }

  public isAllInvoiceEntriesHasBeenPrintedToKitchen(): boolean {
    let printed: boolean = true;
    for (let i = 0; i < this.invoiceEntries.length; i++) {
      if (this.invoiceEntries[i].quantityForKitchenReceipt > 0) printed = false;
    }
    return printed;
  }

  public isAnyInvoiceEntryHasBeenPrintedToKitchen(): boolean {
    for (let i = 0; i < this.invoiceEntries.length; i++) {
      if (this.invoiceEntries[i].quantityForKitchenReceipt === 0) return true;
    }
    return false;
  }

  public isInvoiceHasPayments(): boolean {
    return this.invoicePayments.filter((payment) => !payment.deleted).length > 0;
  }

  public isInvoiceCouldBeRemoved(): boolean {
    return !this.isAnyInvoiceEntryHasBeenPrintedToKitchen() && !this.isInvoiceHasPayments();
  }

  public cleanInvoiceEntriesKitchenQuantity(guestNumber: number): void {
    for (let i = 0; i < this.invoiceEntries.length; i++) {
      const invoiceEntry = this.invoiceEntries[i];
      const isEquelGuestNumber = guestNumber && invoiceEntry.guestNumber === guestNumber;
      if (!guestNumber || isEquelGuestNumber) {
        invoiceEntry.quantityForKitchenReceipt = 0;
        invoiceEntry.increaseLocalModificationDate();
      }
    }
  }

  public setInvoiceAsPaid() {
    this.isPaid = true;
    this.isDraft = false;
    if (!this.date) {
      this.date = moment.utc().toISOString();
    }
  }

  getGiftCardUuidFromGiftCardEntries(): string[] {
    return this.getGiftCardsEntries()
      .filter((entry) => entry.giftCard)
      .map((entry) => entry.giftCard.uuid);
  }

  getGiftCardUuidFromGiftCardPayments(): string[] {
    return this.invoicePayments
      .filter((payment) => !payment.deleted && payment.method === DefaultPaymentMethods.GIFTCARD && payment.giftCard)
      .map((payment) => payment.giftCard.uuid);
  }

  isUnpaidInvoiceWithOnInvoicePaymentMethod(): boolean {
    const paymentsTotal = this.getPaymentsTotal();
    return this.paymentMethod === DefaultPaymentMethods.ON_INVOICE && paymentsTotal < this.amount;
  }

  isUnpaidWebshopInvoice(): boolean {
    return this.isWebshop && !this.isPaid;
  }

  isUnpaidSelfOrderInvoice(): boolean {
    return this.isSelfOrder && !this.isPaid;
  }

  getGiftCardsEntries(): InvoiceEntry[] {
    return this.getActiveInvoiceEntiesByType(PRODUCT_TYPES.GIFT_CARD);
  }

  hasGiftCardsEntries(): boolean {
    return this.hasSomeActiveEntryByType(PRODUCT_TYPES.GIFT_CARD);
  }

  hasProductEntries(): boolean {
    const { PRODUCT, INDIVIDUAL } = PRODUCT_TYPES;
    return this.hasSomeActiveEntryByType(PRODUCT, INDIVIDUAL);
  }

  getProductEntries() {
    const { PRODUCT, INDIVIDUAL } = PRODUCT_TYPES;
    return this.getActiveInvoiceEntiesByType(PRODUCT, INDIVIDUAL);
  }

  hasTippingInvoiceEntry(): boolean {
    return this.hasSomeActiveEntryByType(PRODUCT_TYPES.TIPS);
  }

  getTippingInvoiceEntry() {
    return this.getActiveInvoiceEntiesByType(PRODUCT_TYPES.TIPS).find(() => true);
  }

  hasGuestInvoiceEntry(): boolean {
    return this.hasSomeActiveEntryByType(PRODUCT_TYPES.CATEGORY);
  }

  getGuestInvoiceEntries() {
    return this.getActiveInvoiceEntiesByType(PRODUCT_TYPES.CATEGORY).sort((a, b) => a.guestNumber - b.guestNumber);
  }

  getGuestInvoiceEntryByGuestNumber(guestNumber: number): InvoiceEntry {
    return this.getGuestInvoiceEntries().find((entry) => entry.guestNumber === guestNumber);
  }

  getActiveInvoiceEntriesWithoutGuests() {
    return this.getActiveInvoiceEntries().filter((entry) => entry.type !== PRODUCT_TYPES.CATEGORY);
  }

  getActiveInvoiceEntriesWithoutTypes(...types: string[]) {
    return this.invoiceEntries.filter((entry) => !entry.deleted && !types.includes(entry.type));
  }

  getShippingCostInvoiceEntry() {
    return this.getActiveInvoiceEntiesByType(PRODUCT_TYPES.SHIPPING_COST).find(() => true);
  }

  public generateSelfOrderInfo(data: SelfOrderInInvoiceInterface, sourceUuid: string): SelfOrderInInvoiceInterface {
    return {
      uuid: sourceUuid,
      fulfillmentState: data?.fulfillmentState || SelfOrderInvoiceStatusTypesEnum.OPEN,
      comment: data?.comment,
      fulfillmentOptions: data?.fulfillmentOptions || SelfOrderInvoiceFulfillmentTypesEnum.TAKE_AWAY,
      table: {
        table: data?.table?.table,
        tableUuid: data?.table?.tableUuid,
        room: data?.table?.room,
        roomUuid: data?.table?.roomUuid,
      },
      takeAwayTime: data?.takeAwayTime
    }
  }

  private hasSomeActiveEntryByType(...types: string[]) {
    return this.invoiceEntries.some((entry) => !entry.deleted && types.includes(entry.type));
  }

  private getActiveInvoiceEntiesByType(...types: string[]) {
    return this.invoiceEntries.filter((entry) => !entry.deleted && types.includes(entry.type));
  }

  public generateQrCode() {
    const { isWebshop, uuid, publicUuid } = this;
    let value = SERVER_CONFIG.PUBLIC_URL;
    value += isWebshop ? '/app#/proposals/' + uuid : '/I/' + publicUuid;
    return value;
  }

  private getDisplayId(sequentId: number, invoiceId: string): string {
    if (sequentId && invoiceId) return `${sequentId} (${invoiceId})`;
    if (invoiceId) return invoiceId;
    return '-';
  }
}
