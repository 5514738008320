import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { LocalStorage } from '../utils/localstorage.utils';
import { APP_INFO } from '../../constants/app-info.const';
import { Market } from '@ionic-native/market/ngx';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './logger/log.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { PlatformType } from '@pos-common/constants/platform-types.enum';
import { BehaviorSubject } from 'rxjs';
import { PosApiService } from '../api/pos-api.service';

@Injectable()
export class AppUpdateService {
  public updateAlert: HTMLIonAlertElement;
  private updateInProgress: boolean = false;
  private showUpdageMessage$ = new BehaviorSubject<boolean>(false);
  private readonly logger = this.logService.createLogger('AppUpdateService');

  constructor(
    private alertService: AlertService,
    private localStorage: LocalStorage,
    private market: Market,
    private translateService: TranslateService,
    private platformService: PlatformService,
    private posApiService: PosApiService,
    private logService: LogService
  ) {}

  public startUpdateScheduler(channel?: string) {
    if (this.localStorage.get('force_update_needed') === 'yes' || this.localStorage.get('new_version_available') === 'yes') {
      this.localStorage.set('force_update_needed', 'no');
      this.localStorage.set('new_version_available', 'no');
      this.showAppUpdatedPopup();
    }
    this.checkUpdate();
    setInterval(() => {
      this.checkUpdate();
    }, 300000);
  }

  public checkUpdate() {
    if (!this.updateInProgress) {
      this.updateInProgress = true;
      this.posApiService.posVersions().subscribe(
        (data) => {
          const currentPlatform = this.platformService.isIOS ? PlatformType.iOS : PlatformType.Android;
          const version = data.data.properties.find((obj) => obj.os.indexOf(currentPlatform) > -1);
          if (!version) {
            return;
          }

          const currentVersion = APP_INFO.APP_VERSION;
          if (this.compareVersions(currentVersion, version.min)) {
            this.showForceBinaryUpdatePopup();
          } else if (this.compareVersions(currentVersion, version.current)) {
            this.showUpdageMessage$.next(true);
          }
        },
        (err) => {
          this.logger.error(err, 'checkUpdate:doSecureRequest');
        }
      );
    }
  }

  public getShowUpdateMessage() {
    return this.showUpdageMessage$.asObservable();
  }

  private compareVersions(installed: string, required: string): boolean {
    const a = installed.split('.');
    const b = required.split('.');

    for (let i = 0; i < a.length; ++i) {
      if (Number(a[i]) > Number(b[i])) return false;
      if (Number(a[i]) < Number(b[i])) return true;
    }
    return false;
  }

  public async showUpdatePopup() {
    if (this.updateAlert) {
      return;
    }

    this.updateAlert = await this.alertService.create({
      header: this.translateService.instant('update_available_title'),
      message: this.translateService.instant('update_deploy_available_message'),
      buttons: [
        {
          text: this.translateService.instant('common_skip'),
          role: 'cancel',
          handler: () => {
            this.updateAlert = null;
          },
        },
        {
          text: this.translateService.instant('common_update'),
          handler: () => {
            this.localStorage.set('new_version_available', 'no');
            this.updateAlert = null;
          },
        },
      ],
    });
    await this.updateAlert.present();
  }

  public async showAppUpdatedPopup() {
    if (this.updateAlert) {
      return;
    }

    this.updateAlert = await this.alertService.create({
      header: this.translateService.instant('update_app_updated_title'),
      buttons: [
        {
          text: this.translateService.instant('common_yes'),
        },
      ],
    });
    await this.updateAlert.present();
  }

  public async showForceBinaryUpdatePopup() {
    if (this.updateAlert) {
      return;
    }

    this.updateAlert = await this.alertService.create({
      header: this.translateService.instant('update_force_available_title'),
      message: this.translateService.instant('update_binary_available_message'),
      buttons: [
        {
          text: this.translateService.instant('common_update'),
          handler: () => {
            this.updateAlert = null;
            this.openAppInMarket();
          },
        },
      ],
    });
    await this.updateAlert.present();
  }

  public async showRequiredUpdatePopup() {
    if (this.updateAlert) {
      return;
    }

    this.updateAlert = await this.alertService.create({
      header: this.translateService.instant('update_force_available_title'),
      message: this.translateService.instant('update_required_message'),
      buttons: [
        {
          text: this.translateService.instant('common_update'),
          handler: () => this.openAppInMarket(),
        },
      ],
    });
    this.updateAlert.onDidDismiss().then(() => (this.updateAlert = null));
    await this.updateAlert.present();
  }

  public openAppInMarket() {
    const appName = this.platformService.isAndroid ? 'com.paymash.android5POS' : 'id1199401347';
    this.market.open(appName).catch((err) => this.logger.error(err, 'openAppInMarket:market:open'));
  }
}
