import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { AnimationOptions } from '@pos-common/interfaces';

@Injectable()
export class RouteNavigationService {
  private previousUrl: string;
  private currentUrl: string;
  private routeHistory: string[] = [];
  private initEvent$ = new BehaviorSubject<boolean>(false);
  private isFinishInit = false;

  constructor(private router: Router, private navController: NavController) {}

  loadRouting() {
    this.setURLs(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => this.setURLs(urlAfterRedirects));
  }

  private setURLs(url: string): void {
    const tempUrl = this.currentUrl;
    this.previousUrl = tempUrl;
    this.currentUrl = url;
    this.routeHistory = [...this.routeHistory, url];
  }

  get previous(): string {
    return this.previousUrl;
  }

  get current(): string {
    return this.currentUrl;
  }

  get history(): string[] {
    return this.routeHistory;
  }

  get data() {
    return this.router.getCurrentNavigation()?.extras?.state || {};
  }

  back(options?: AnimationOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.navController.back(options);
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  }

  isFirstRoute(): boolean {
    return this.history.length < 2;
  }

  setInitializationFinished(isFinish: boolean) {
    this.initEvent$.next(isFinish);
    this.isFinishInit = isFinish;
  }

  isInitializationFinished(): Promise<boolean> {
    const { isFinishInit } = this;
    if (isFinishInit) {
      return Promise.resolve(isFinishInit);
    }
    return this.initEvent$
      .pipe(
        filter((isFinish) => isFinish === true),
        first()
      )
      .toPromise();
  }
}
