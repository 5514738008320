import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { Injectable } from '@angular/core';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import {
  MakePaymentOptions,
  PaymentType,
  MakePaymentRequestOptions,
  CommonRequestOptions,
  TransactionStatusRequestOptions,
  MakePaymentResponse,
} from '../adyen-types';
import { AdyenPaymentApi } from './api/adyen-payment-api.service';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { LogService } from '../../logger/log.service';
import { AdyenPaymentErrors } from '../costants';
import { TranslateService } from '@ngx-translate/core';
import { ErrorTranslationHelper } from '@pos-common/classes';
import { DefaultPaymentMethods } from '@pos-common/constants';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

type MakeTransactionOptions = { type: PaymentType } & MakePaymentOptions;

@Injectable()
export class AdyenPaymentService {
  private readonly errorTranslationHelper = new ErrorTranslationHelper();
  private readonly logger = this.logService.createLogger('AdyenPaymentService');

  constructor(
    private readonly adyenPaymentApi: AdyenPaymentApi,
    private readonly paymentProcessingService: PaymentProcessingService,
    private readonly utils: AdyenUtils,
    private readonly logService: LogService,
    private readonly setTimeoutUtil: SetTimeoutUtil,
    private readonly translateService: TranslateService
  ) {
    this.setupErrorMessages();
  }

  private setupErrorMessages() {
    this.errorTranslationHelper.setupMessages([
      [AdyenPaymentErrors.userCancel, 'terminal_transaction_error_user_cancel'],
      [AdyenPaymentErrors.provideTerminalInfo, 'settings_provide_terminal_info'],
      [AdyenPaymentErrors.currencyNotSupported, 'terminal_transaction_error_wrong_currency'],
      [AdyenPaymentErrors.refundNotEnabled, 'payment_method_does_not_support_refund'],
      [AdyenPaymentErrors.notEnoughBalance, 'terminal_transaction_error_not_enough_balance'],
      [AdyenPaymentErrors.twintNotEnabled, 'terminal_transaction_error_twint_disabled'],
      [AdyenPaymentErrors.declinedOnline, 'terminal_transaction_error_declined'],
      [AdyenPaymentErrors.notPresentCard, 'terminal_transaction_error_not_present_card'],
      [AdyenPaymentErrors.timeoutWaitingResponse, 'connection_timeout'],
      [AdyenPaymentErrors.applicationTimeout, 'connection_timeout'],
      [AdyenPaymentErrors.cardRemoved, 'terminal_transaction_error_user_cancel'],
      [AdyenPaymentErrors.failedToConnect, 'terminal_error_connect_fail'],
      [AdyenPaymentErrors.statusInProgress, 'terminal_error_connection_fail'],
      [AdyenPaymentErrors.connectFailedToPaymentHost, 'terminal_error_connect_fail'],
      [AdyenPaymentErrors.connectFailedToServer, 'terminal_error_connect_fail'],
      [AdyenPaymentErrors.requestTimeout, 'connection_timeout'],
      [AdyenPaymentErrors.timeout, 'terminal_error_connect_fail'],
    ]);
  }

  makePayment(options: MakePaymentOptions): Promise<PaymentResult> {
    return this.prepareTransaction({ type: 'payment', ...options });
  }

  makeRefund(options: MakePaymentOptions): Promise<PaymentResult> {
    return this.prepareTransaction({ type: 'refund', ...options });
  }

  private prepareTransaction(options: MakeTransactionOptions) {
    return new Promise<PaymentResult>((resolve, reject) => {
      let transactionError: Error = null;
      const requestOptions = this.getPaymentRequestOptions(options);
      this.paymentProcessingService.init(
        () =>
          this.checkTransactionStatus(requestOptions)
            .then(resolve)
            .catch((error) => (transactionError = error)),
        () => reject(transactionError)
      );
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
      this.isOnline()
        .then(() => this.makeTransaction(requestOptions))
        .then(resolve)
        .catch((error) => (transactionError = error));
    });
  }

  private async finishTransaction(paymentResponse: MakePaymentResponse) {
    const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.PAYMASH_PAY);
    paymentResult.setPaymentResultData(paymentResponse);
    return paymentResult;
  }

  private makeTransaction(options: MakePaymentRequestOptions) {
    return this.adyenPaymentApi
      .makePayment(options)
      .then((paymentResponse) => this.finishTransaction(paymentResponse))
      .catch((error) => {
        this.logger.error(error, 'makeTransaction');
        const translateKey = this.errorTranslationHelper.getTranslationKey(error?.message) || error?.message || 'common_error_msg';
        const timeoutErrors = [
          AdyenPaymentErrors.timeoutWaitingResponse,
          AdyenPaymentErrors.applicationTimeout,
          AdyenPaymentErrors.requestTimeout,
        ];
        const hasTimeoutError = timeoutErrors
          .map((timeoutError) => this.errorTranslationHelper.getTranslationKey(timeoutError))
          .some((timeoutError) => timeoutError === translateKey);
        const action = hasTimeoutError ? PaymentProcessingActions.check : PaymentProcessingActions.retry;
        const displayMessage = hasTimeoutError ? 'transaction_check_status_message' : translateKey;
        this.paymentProcessingService.dispatchAction(action, {
          message: this.translateService.instant(displayMessage),
          retryButtonOff: !hasTimeoutError,
        });
        throw error;
      });
  }

  private getPaymentRequestOptions(options: MakeTransactionOptions): MakePaymentRequestOptions {
    const { currency, amount, paymentUuid, type, paymentMethod } = options;
    const requestOptions: MakePaymentRequestOptions = {
      ...this.utils.createCommonRequestOptions(),
      currency,
      amount,
      saleTransaction: {
        transactionID: paymentUuid, // "27908" -- your reference to identify a payment. We recommend using a unique value per payment. In your Customer Area and Adyen reports, this will show as the merchant reference for the transaction.
        timeStamp: new Date().toISOString(), // "2019-03-07T10:11:04+00:00" -- date and time of the request in UTC format.
      },
      type,
      allowedPaymentBrand: paymentMethod === DefaultPaymentMethods.TWINT ? 'twint_pos' : undefined,
    };
    return requestOptions;
  }

  private checkTransactionStatus(options: CommonRequestOptions) {
    this.paymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
    const requestOptions = this.getTransactionStatusRequestOptions(options);
    return this.adyenPaymentApi
      .checkTransactionStatus(requestOptions)
      .then((paymentResponse) => this.finishTransaction(paymentResponse))
      .catch((error) => {
        this.logger.error(error, 'checkTransactionStatus');
        const message = this.errorTranslationHelper.getTranslationKey(error?.message) || 'connection_timeout';
        this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
          message: this.translateService.instant(message),
          retryButtonOff: true,
        });
        throw error;
      });
  }

  private getTransactionStatusRequestOptions(options: CommonRequestOptions): TransactionStatusRequestOptions {
    return {
      ...this.utils.createCommonRequestOptions(),
      transactionSaleID: options.saleID,
      transactionServiceID: options.serviceID,
    };
  }

  private async isOnline() {
    await this.setTimeoutUtil.waitTimeAndDo(1);
    const requestOptions = this.utils.createCommonRequestOptions();
    return this.adyenPaymentApi.diagnostic(requestOptions).catch((error) => {
      this.logger.error(error, 'diagnostic');
      const translateKey = this.errorTranslationHelper.getTranslationKey(error?.message) || error?.message || 'common_error_msg';
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
        message: this.translateService.instant(translateKey),
        retryButtonOff: true,
      });
      throw error;
    });
  }
}
