import { Directive, ElementRef, HostListener, Input, NgModule, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';
import { EffectService } from '@pos-common/services/system/effect/effect.service';
import { SetTimeoutUtil } from '@pos-common/services/utils/settimeout.utils';

@Directive({
  selector: '[wave-effect]',
})
export class WaveEffectDirective implements OnInit {
  @Input() waveColor: string;
  @Input() waveDuration: string;

  private hostElement: HTMLElement;
  private element: HTMLElement;
  private readonly className = 'wave-effect';

  constructor(
    private renderer: Renderer2,
    el: ElementRef,
    private setTimeoutUtil: SetTimeoutUtil,
    private effectService: EffectService,
    private domController: DomController
  ) {
    this.hostElement = el.nativeElement;
  }

  ngOnInit(): void {
    if (this.effectService.isDisableEffect()) {
      return;
    }
    this.renderer.addClass(this.hostElement, `pos-${this.className}`);
    this.addElement();
  }

  private addElement() {
    const element = this.renderer.createElement('div');
    this.effectService.setBackgroundColor(element, this.waveColor);
    this.effectService.setAnimationDuration(element, this.waveDuration);
    element.classList.add(this.className);
    this.element = element;
    this.hostElement.appendChild(element);
  }

  @HostListener('click') onClick() {
    if (this.effectService.isDisableEffect()) {
      return;
    }
    this.handleClick();
  }

  private handleClick() {
    const dinstance = Math.min(this.hostElement.clientWidth, this.hostElement.clientHeight);
    const className = `${this.className}-active`;
    this.domController.write(() => {
      this.setSize(dinstance);
      this.renderer.addClass(this.element, className);
    });
    this.setTimeoutUtil.addVisualEffect(500).then(() => {
      this.hide(className);
    });
  }

  private hide(className: string) {
    this.domController.write(() => {
      this.renderer.removeClass(this.element, className);
      this.setSize(0);
    });
  }

  private setSize(dinstance: number) {
    this.renderer.setStyle(this.element, 'width', `${dinstance}px`);
    this.renderer.setStyle(this.element, 'height', `${dinstance}px`);
  }
}
@NgModule({
  declarations: [WaveEffectDirective],
  exports: [WaveEffectDirective],
})
export class WaveEffectDirectiveModule {}
