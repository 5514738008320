import { Injectable } from '@angular/core';
import { NullifyModalService } from '../../services/system/nullify-modal.service';
import { PaymentProcessing } from './payment-processing.component';

import {
  ActionCallbackInterface,
  PaymentActionConfiguration,
  PaymentProcessingModalConfigInterface,
  ProcessActionInterface,
} from '../../interfaces/index';
import { BehaviorSubject, Subject } from 'rxjs';
import { PaymentProcessingUserActionInterface } from './payment-processing-user-action.interface';
import { PaymentProcessingUserActions } from './payment-processing-user-actions.enum';
import { PaymentProcessingActions } from './payment-processing-actions.enum';

@Injectable()
export class PaymentProcessingService {
  private processAction: Subject<ProcessActionInterface> = new BehaviorSubject(null);
  private additionalDataProvider: Subject<string> = new Subject();

  // callbacks

  private onSignatureCallback: (result: string) => void = () => {};

  // constructor

  constructor(private nullifyModalService: NullifyModalService) {}

  // public

  public init(onRetry: () => void = () => {}, onClose: () => void = () => {}) {
    this.setupModalComponent({ onClose, onRetry });
  }

  public dispatchAction(
    action: PaymentProcessingActions,
    configuration: PaymentActionConfiguration = {},
    callback?: ActionCallbackInterface
  ) {
    this.processAction.next({
      action,
      configuration,
      callback,
    });
  }

  public dispatchAdditionalData(data: string) {
    this.additionalDataProvider.next(data);
  }

  public dismissModal() {
    this.nullifyModalService.destroyModal();
  }

  // action callbacks

  public onSignature(callback: (result: string) => void) {
    this.onSignatureCallback = callback;
  }

  // private

  private handleUserAction(event: PaymentProcessingUserActionInterface, config: PaymentProcessingModalConfigInterface) {
    switch (event.type) {
      case PaymentProcessingUserActions.close:
        this.dismissModal();
        config.onClose();
        break;
      case PaymentProcessingUserActions.retry:
        config.onRetry();
        break;
      case PaymentProcessingUserActions.drawSignatureDone:
        if (event.payload && typeof event.payload === 'string') {
          this.onSignatureCallback(<string>event.payload);
        }
        break;
    }
  }

  private setupModalComponent(config: PaymentProcessingModalConfigInterface) {
    if (this.nullifyModalService.isNullifyModalExist()) {
      return;
    }

    this.nullifyModalService.initModal({
      component: PaymentProcessing,
      options: {
        styles: { width: '500px' },
        verticalCenter: true,
        backdrop: true,
        notMobileFullScreen: true,
        closeBlocking: true,
      },
      inputs: [
        {
          onUserAction: (event: PaymentProcessingUserActionInterface) => {
            this.handleUserAction(event, config);
          },
          actionsDispatcher: this.processAction.asObservable(),
          providerDataStream: this.additionalDataProvider.asObservable(),
        },
      ],
    });
  }
}
