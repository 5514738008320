import { Injectable } from '@angular/core';
import { AdyenTenderOptionsEnum } from '../enums';
import { StorageKeys } from '@pos-common/constants';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { IActiveTerminal } from '@pos-common/interfaces';

@Injectable()
export class AdyenTippingService {

  constructor(private localStorage: LocalStorage) { }

  public get add(): AdyenTenderOptionsEnum {
    return AdyenTenderOptionsEnum.Tipping;
  }

  public get isTippingAllowed(): boolean {
    const terminal: IActiveTerminal = this.localStorage.getObject(StorageKeys.activeTerminal);
    return terminal?.tipAllowed;
  }

}
