import { Injectable, EventEmitter } from '@angular/core';
import { Customer } from '../../classes/customer.class';
import { CustomersApiService } from '../api/customers-api.service';
import * as moment from 'moment';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Invoice } from '../../classes/invoice.class';
import { DbDaoService } from '../db/db-dao.service';
import { SyncService } from './sync.service';
import { NavController, mdTransitionAnimation } from '@ionic/angular';
import { Subject, BehaviorSubject } from 'rxjs';
import { UuidService } from '../utils/uuid.utils';
import { SyncListItem } from '../../classes/sync-list-item.class';
import { LogService } from './logger/log.service';
import { ROUTE_URLS } from '../../constants/route-urls.const';
import { ICustomerPageData } from '@pos-common/interfaces/customer-page-data.interface';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { CUSTOMER_EDIT_ACTION_TYPE } from '@pos-common/constants';
import { Query } from '@paymash/capacitor-database-plugin';

@Injectable()
export class CustomerService {
  private currentCustomer: Customer;
  private newCustomerEvent: EventEmitter<any> = new EventEmitter();
  public customerInListSelected: Subject<Customer> = new BehaviorSubject<Customer>(null);
  public actionEvent = new Subject<CUSTOMER_EDIT_ACTION_TYPE>();
  private update$ = new Subject<void>();
  private readonly logger = this.logService.createLogger('CustomerService');

  constructor(
    public customersApiService: CustomersApiService,
    public dbDaoService: DbDaoService,
    public uuidService: UuidService,
    public syncService: SyncService,
    private navController: NavController,
    private logService: LogService
  ) {}

  public createNewCustomer(): Customer {
    const newCustomer = new Customer({
      uuid: this.uuidService.generate(),
      isSynced: false,
    });
    newCustomer.setLocalCreationDate();
    return newCustomer;
  }

  public setCurrentCustomer(customer: Customer) {
    this.currentCustomer = customer;
    this.newCustomerEvent.emit(this.currentCustomer);
  }

  public getCurrentCustomer() {
    return this.currentCustomer;
  }

  public getNewCustomerEvent() {
    return this.newCustomerEvent;
  }

  public getCustomerLastPurchases(customerUuid: string): Promise<SecuredResponse> {
    return this.customersApiService.getInvoicesByCustomer(customerUuid);
  }

  public updateLastVisitDate(customer: Customer, lastVisit: any) {
    this.getCustomerByUuid(customer.uuid)
      .then((customerData): void => {
        if (customerData && !customer.deleted) {
          customerData.lastVisit = moment(lastVisit).format('YYYY-MM-DD');
          this.saveCustomer(customerData).catch((err) => this.logger.error(err, 'updateLastVisitDate:saveCustomer'));
        }
      })
      .catch((err) => this.logger.error(err, 'updateLastVisitDate:getCustomerByUuid'));
  }

  public getCustomerByUuid(customerUuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dbDaoService
        .getDataByUUID(UPDATES_TYPES.Customer.type, customerUuid)
        .then((data) => {
          if (data['data']) {
            resolve(new Customer(data['data']));
          } else {
            reject(null);
          }
        })
        .catch(reject);
    });
  }

  public saveCustomer(customer: Customer): Promise<Customer> {
    customer.increaseLocalModificationDate();
    return new Promise((resolve, reject) => {
      this.dbDaoService
        .upsertDataToCollection(UPDATES_TYPES.Customer.type, [customer])
        .then((data) => {
          let newCustomerData: Customer = new Customer(data['data'][0]);
          this.syncService
            .addDataToSyncList(new SyncListItem(newCustomerData, UPDATES_TYPES.Customer.type))
            .catch((err) => this.logger.error(err, 'saveCustomer:syncService:addDataToSyncList'));
          resolve(newCustomerData);
        })
        .catch(reject);
    });
  }

  public showCustomerPage(data: ICustomerPageData, animated: boolean = true) {
    return this.navController
      .navigateForward(ROUTE_URLS.customers, {
        state: { ...data },
        animated,
        animation: mdTransitionAnimation,
        animationDirection: 'forward',
      })
      .catch((err) => this.logger.error(err, 'showCustomerPage:navController:navigateForward:customers'));
  }

  public showCustomersModal(invoice: Invoice, fromState?: string) {
    let customer: Customer = null;
    if (invoice.customer) {
      customer = invoice.customer;
    }
    let data: any = customer
      ? {
          customer: customer,
          disabledForSelect: true,
        }
      : { selectCustomerForExistInvoiceMode: true };

    if (fromState) data.fromState = fromState;
    this.navController
      .navigateForward(ROUTE_URLS.customers, {
        state: { ...data },
        animation: mdTransitionAnimation,
      })
      .catch((err) => this.logger.error(err, 'showCustomersModal:navController:push:CustomersPage'));
  }

  public customerInListSelect(customer: Customer): void {
    this.customerInListSelected.next(customer);
  }

  public checkCustomerEmailForDuplicates(email: string, queryParams?: Query): Promise<boolean> {
    return this.dbDaoService
      .getAllData(UPDATES_TYPES.Customer.type, queryParams, { select: ['email'] })
      .then((data) => {
        const newEmail: string = email.toLowerCase();
        const emails: string[] = data.data.map((item) => item?.email?.toLowerCase()).filter((email) => email);
        return emails.includes(newEmail);
      })
      .catch((err): boolean => {
        this.logger.error(err, 'checkEmailForDuplicates:getDataByParams');
        return true;
      });
  }

  setActionEvent(action: CUSTOMER_EDIT_ACTION_TYPE) {
    this.actionEvent.next(action);
  }

  getActionEvent() {
    return this.actionEvent.asObservable();
  }

  setUpdate() {
    this.update$.next();
  }

  getUpdate() {
    return this.update$.asObservable();
  }
}
