export class Page {
  public title: string;
  public icon: string;
  public url: string;
  public isSelected?: boolean;
  public permission?: string;
  public companySetting?: string;

  constructor(data) {
    this.title = data.title || '';
    this.icon = data.icon || '';
    this.url = data.url || '';
    this.isSelected = data.isSelected || false;
    this.permission = data.permission || null;
    this.companySetting = data.companySetting || null;
  }
}
