import { ROUTE_URLS } from './route-urls.const';
import { Page } from '@pos-common/classes/page.class';

const pages: Page[] = [
  {
    title: 'global_collection_view_title',
    icon: 'products',
    url: ROUTE_URLS.collection,
  },
  {
    title: 'global_invoices_title',
    icon: 'invoices',
    url: ROUTE_URLS.invoices,
  },
  {
    title: 'global_day_report_title',
    icon: 'report',
    url: ROUTE_URLS.report,
    permission: 'hasReportPermission',
  },
  {
    title: 'global_calendar_title',
    icon: 'report',
    url: ROUTE_URLS.calendar,
    companySetting: 'isBookingEnabled',
  },
  {
    title: 'global_admin_title',
    icon: 'admin',
    url: 'Admin',
    permission: 'hasAdminPermission',
  },
  {
    title: 'global_settings_title',
    icon: 'settings',
    url: ROUTE_URLS.settings,
    permission: 'hasSettingsPermission',
  },
  {
    title: 'global_shifts_title',
    icon: 'shifts',
    url: ROUTE_URLS.shifts,
    companySetting: 'isCashRegisterEnabled',
  },
  {
    title: 'global_help_title',
    icon: 'help',
    url: 'Help',
  },
  {
    title: 'global_refer_friend_title',
    icon: 'refer-friend',
    url: 'Refer',
    permission: 'hasAdminPermission',
  },
];

export const ROUTES_LIST: Page[] = pages.map((page) => new Page(page));
