import { first, filter, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CapacitorPluginsName } from '@pos-common/constants/capacitor-plugins-name.enum';
import { Keyboard } from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { PlatformService } from '../platform/platform.service';

@Injectable()
export class KeyboardService {
  isAvailablePlugin = false;
  private _isOpen = false;
  private keyboardStateEvent = new Subject<boolean>();
  private plugin = Keyboard;

  constructor(private platformService: PlatformService) {
    this.isAvailablePlugin = Capacitor.isPluginAvailable(CapacitorPluginsName.Keyboard);
    this.addListeners();
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
    this.keyboardStateEvent.next(value);
  }

  private addListeners() {
    if (this.isAvailablePlugin) {
      this.plugin.addListener('keyboardDidShow', () => (this.isOpen = true));
      this.plugin.addListener('keyboardDidHide', () => (this.isOpen = false));
    }
  }

  show(): Promise<void> {
    if (this.isAvailablePlugin && this.platformService.isAndroid) {
      return Keyboard.show();
    }
    return Promise.resolve();
  }

  hide(): Promise<void> {
    if (!this.isAvailablePlugin || !this.isOpen) {
      return Promise.resolve();
    }
    return Keyboard.hide();
  }

  getCloseEventOnce() {
    return this.keyboardStateEvent.pipe(
      filter((isOpen) => !isOpen),
      delay(300),
      first()
    );
  }

  setAccessoryBarVisible(options: { isVisible: boolean }): Promise<void> {
    if (this.isAvailablePlugin && this.platformService.isIOS) {
      return this.plugin.setAccessoryBarVisible(options);
    }
    return Promise.resolve();
  }
}
