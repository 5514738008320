import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '@pos-common/constants/server.const';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { SelfOrderPutRequestDto } from '@pos-common/interfaces';

@Injectable()
export class SelfOrdersApiService {

  constructor(private SecurityService: SecurityService) { }

  public updatePreparationStatus(data: SelfOrderPutRequestDto): Promise<SecuredResponse> {
    // const uuid: string = '288adc30-486f-4c6e-bcd8-5d2728e9931b';
    const { fulfillmentState, uuid } = data;
    const url: string = `${SERVER_CONFIG.API_V2_URL}self-orders/${uuid}`;
    return this.SecurityService.doSecureRequest(url, 'put', {
      fulfillmentState
    });
  }
}
