/**
 * Created by y.belinsky on 12/1/16.
 */

import { Injectable } from '@angular/core';
import { CartService } from './cart.service';
import { Invoice } from '../../classes/invoice.class';
import { Customer } from '../../classes/customer.class';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { UuidService } from '../utils/uuid.utils';
import { SyncService } from './sync.service';
import { DbDaoService } from '../db/db-dao.service';
import { SyncListItem } from '../../classes/sync-list-item.class';
import { LogService } from './logger/log.service';

@Injectable()
export class InvoiceSendService {
  public customerCreationErrorAlert: any;
  private readonly logger = this.logService.createLogger('InvoiceSendService');

  constructor(
    public CartService: CartService,
    public AlertService: AlertService,
    public TranslateService: TranslateService,
    public UuidService: UuidService,
    public SyncService: SyncService,
    public DbDaoService: DbDaoService,
    private logService: LogService
  ) {}

  public sendInvoiceByEmail(invoice: Invoice, email: string, createCustomer: boolean) {
    invoice.mailTo = email;
    if (createCustomer) {
      this.createOrUpdateCustomerData(email)
        .then((customer: Customer) => {
          return this.upsertCustomer(customer);
        })
        .then((data) => {
          invoice.customer = new Customer(data);
          this.updateInvoice(invoice);
        })
        .catch(() => {
          this.customerCreationErrorAlert = this.AlertService.create({
            header: this.TranslateService.instant('common_error'),
            subHeader: this.TranslateService.instant('customers_creation_error'),
            buttons: ['OK'],
          });
          this.customerCreationErrorAlert.present();
        });
    } else {
      this.updateInvoice(invoice);
    }
  }

  private createOrUpdateCustomerData(customerEmail: string) {
    return new Promise((resolve, reject) => {
      this.getCustomerByEmailOrCreate(customerEmail)
        .then((data) => {
          let newCustomer: any = {};
          newCustomer = data;
          newCustomer.email = customerEmail;
          resolve(new Customer(newCustomer));
        })
        .catch(reject);
    });
  }

  private getCustomerByEmailOrCreate(customerEmail: string) {
    return new Promise((resolve) => {
      this.DbDaoService.getAllData(UPDATES_TYPES.Customer.type, { email: customerEmail })
        .then((data) => {
          if (data['data'] && data['data'][0]) {
            let customer: Customer = new Customer(data['data'][0]);
            resolve(customer);
          } else {
            resolve({ uuid: this.UuidService.generate() });
          }
        })
        .catch(() => {
          resolve({ uuid: this.UuidService.generate() });
        });
    });
  }

  private upsertCustomer(customer: Customer) {
    return new Promise((resolve, reject) => {
      this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.Customer.type, [customer])
        .then((data) => {
          let customerToSave: Customer = data['data'][0];
          this.SyncService.addDataToSyncList(new SyncListItem(customerToSave, UPDATES_TYPES.Customer.type)).catch((err) =>
            this.logger.error(err, 'upsertCustomer:addDataToSyncList')
          );
          resolve(customerToSave);
        })
        .catch(reject);
    });
  }

  private updateInvoice(invoice: Invoice) {
    this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.Invoice.type, [invoice])
      .then((data) => {
        let newInvoiceData: Invoice = data['data'][0];
        this.SyncService.addDataToSyncList(new SyncListItem(newInvoiceData, UPDATES_TYPES.Invoice.type)).catch((err) =>
          this.logger.error(err, 'updateInvoice:addDataToSyncList')
        );
      })
      .catch((err) => this.logger.error(err, 'updateInvoice:upsertDataToCollection'));
  }
}
