import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SocketScannerDevice, SocketScannerPluginProxy } from './socket-scanner-plugin.proxy';
import { LogService } from '../logger/log.service';
import { filter, map } from 'rxjs/operators';
import { ScannerResultAction } from '@paymash/capacitor-socket-scanner-plugin';

type SubjectType = { action: ScannerResultAction; value: SocketScannerDevice | string };

@Injectable()
export class SocketScannerService {
  connectedDevices: SocketScannerDevice[] = [];
  private subject = new Subject<SubjectType>();
  private readonly logger = this.logService.createLogger('SocketScannerService');

  constructor(private logService: LogService, private pluginProxy: SocketScannerPluginProxy) {}

  startObserve() {
    this.logger.debug('startObserve');

    this.pluginProxy.connect(
      (result) => {
        this.logger.debug('startObserve onDeviceConnected ', { result });
        if (result.guid) {
          this.addScannerToList(result);
        }

        this.subject.next({
          action: ScannerResultAction.deviceConnected,
          value: result,
        });
      },
      (guid) => {
        this.logger.debug('startObserve onDeviceDisconnected ', { guid });
        if (guid) {
          this.connectedDevices = this.connectedDevices.filter((value) => value.guid !== guid);
        }

        this.subject.next({
          action: ScannerResultAction.deviceDisconnected,
          value: guid,
        });
      },
      (data) => {
        this.logger.debug('startObserve onDataReceived ', { data });
        this.subject.next({
          action: ScannerResultAction.dataReceived,
          value: data,
        });
      },
      (errorCode) => {
        this.logger.error(errorCode, 'startObserve');
      }
    );
  }

  stopObserve(): Promise<void> {
    return this.pluginProxy
      .disconnect()
      .then(() => {
        this.onStopObserve();
        this.logger.debug('stopObserve');
      })
      .catch((error) => {
        this.onStopObserve();
        this.logger.error(error, 'stopObserve');
        throw error;
      });
  }

  onConnected(): Observable<SocketScannerDevice> {
    return this.subject.asObservable().pipe(
      filter((item) => item.action === ScannerResultAction.deviceConnected),
      map((item) => item.value as SocketScannerDevice)
    );
  }

  onDisconnected(): Observable<string> {
    return this.subject.asObservable().pipe(
      filter((item) => item.action === ScannerResultAction.deviceDisconnected),
      map((item) => item.value as string)
    );
  }

  onData(): Observable<string> {
    return this.subject.asObservable().pipe(
      filter((item) => item.action === ScannerResultAction.dataReceived),
      map((item) => item.value as string)
    );
  }

  private onStopObserve() {
    this.connectedDevices = this.connectedDevices.filter((device) => {
      this.subject.next({
        action: ScannerResultAction.deviceDisconnected,
        value: device.guid,
      });
      return false;
    });
  }

  private addScannerToList(scanner: SocketScannerDevice) {
    const currentScanner = this.connectedDevices.find((item) => item.guid === scanner.guid);
    if (currentScanner) {
      currentScanner.batteryLevel = scanner.batteryLevel;
      return;
    }
    this.connectedDevices = [...this.connectedDevices, scanner];
  }
}
