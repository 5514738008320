export enum MyposGlassTransactionCodeEnum {
  PLATFORM_NOT_SUPPORT_GLASS = 'PLATFORM_NOT_SUPPORT_GLASS',
  GLASS_APP_NOT_INSTALLED = 'GLASS_APP_NOT_INSTALLED',
  POS_INFO_NOT_EXISTS = 'POS_INFO_NOT_EXISTS',
  TRANSACTION_SUCCESS  = 'TRANSACTION_SUCCESS',
  TRANSACTION_CANCELED = 'TRANSACTION_CANCELED',
  TRANSACTION_DECLINED = 'TRANSACTION_DECLINED',
  TRANSACTION_FAILED   = 'TRANSACTION_FAILED',
  DEVICE_NOT_ACTIVATED = 'DEVICE_NOT_ACTIVATED',
  REFUND_NOT_ACTIVATED = 'REFUND_NOT_ACTIVATED',
  NO_DATA_FOUND        = 'NO_DATA_FOUND',
  INVALID_CURRENCY     = 'INVALID_CURRENCY',
  INVALID_AMOUNT       = 'INVALID_AMOUNT',
  COMMUNICATION_ERROR  = 'COMMUNICATION_ERROR',
  NFC_NOT_READY = 'NFC_NOT_READY',
  UNKNOWN = 'UNKNOWN',
  INVALID_E_RECEIPT_CREDENTIAL = 'INVALID_E_RECEIPT_CREDENTIAL'
}
