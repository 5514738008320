import { Injectable } from '@angular/core';
import { IMyPosReceiptItem } from '@pos-common/services/system/receipt-printers/classes/receipt-builder.class';
import { LangMap, CurrencyMap } from '@pos-common/constants/mypos.const';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '@pos-common/constants/payment-providers.const';
import { PaymentResult } from '@pos-common/classes/payment-result.class';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { MyPosMiniPlugin } from './plugin';
import { ConnectOptions, PayOptions, TransactionResult } from '@paymash/capacitor-mypos-mini-types';

@Injectable()
export class MyPosMiniFacade {
  private serialNumber: string = '';
  private currency: string;
  private language: number;
  private errorMessages: Map<string, string>;
  private readonly logger = this.logService.createLogger('MyPosMiniFacade');

  constructor(
    private platformService: PlatformService,
    private paymentProcessingService: PaymentProcessingService,
    private translateService: TranslateService,
    private logService: LogService
  ) {
    this.setErrorMessages();
  }

  private getLanguage(lang: string): number {
    return LangMap[lang] || LangMap['en'];
  }

  private getCurrency(currency: string): number {
    return CurrencyMap[currency] || CurrencyMap['default'];
  }

  connectAndGetPrinter(currency: string, language: string): Promise<boolean> {
    if (this.platformService.isWeb) {
      return Promise.resolve(true);
    }
    this.currency = currency;
    this.language = this.getLanguage(language);
    return this.reConnect().then((resp) => {
      if (resp) {
        this.serialNumber = resp;
      }
      return this.hasPrinter();
    });
  }

  connect(): Promise<string> {
    const data = this.getConnectionData();
    return MyPosMiniPlugin.connect(data);
  }

  isConnected(): Promise<boolean> {
    return MyPosMiniPlugin.isConnected();
  }

  private hasPrinter(): Promise<boolean> {
    return MyPosMiniPlugin.hasPrinter();
  }

  pay(amount: number): Promise<TransactionResult> {
    const data = this.getPaymentData(amount, 'myPOS payment');
    return MyPosMiniPlugin.pay(data);
  }

  public refund(amount: number): Promise<TransactionResult> {
    const data = this.getPaymentData(amount, 'myPOS refund');
    return MyPosMiniPlugin.refund(data);
  }

  public print(receipt: IMyPosReceiptItem[]): Promise<void> {
    return this.reConnect().then(() => MyPosMiniPlugin.print({ data: receipt }));
  }

  update(): Promise<void> {
    return MyPosMiniPlugin.update();
  }

  activate(): Promise<void> {
    return MyPosMiniPlugin.activate();
  }

  deactivate(): Promise<void> {
    return MyPosMiniPlugin.deactivate();
  }

  private setErrorMessages() {
    this.errorMessages = new Map<string, string>([['Last transaction not completed', 'terminal_transaction_not_completed']]);
  }

  private reConnect(): Promise<string> {
    return this.isConnected().then((isConnected) => {
      return isConnected ? '' : this.connect();
    });
  }

  private getPaymentData(amount: number, title: string) {
    let data: PayOptions = {
      amount,
      receiptType: 3,
    };

    if (this.platformService.isIOS) {
      data = {
        ...data,
        title, // optinal, title of transaction to show
        skipConfirmation: true, // optinal, if false user would be able to change amount and confirm transaction,
        currency: this.getCurrency(this.currency), // optional, see [Currency]
      };
    }

    return data;
  }

  private getConnectionData(): ConnectOptions {
    if (this.platformService.isAndroid) {
      return { currency: this.currency };
    }
    return {
      appName: 'Paymash',
      language: this.language, // optional, see [Language  type]
      receiptType: 3, // optional, see [Print Receipt options]
      serialNumber: this.serialNumber, // optional, serial number of a POS device to which to connect automatically upon discovery
    };
  }

  makeTransaction(currency: string, language: string, amount: number, transactionType?: string): Promise<PaymentResult> {
    return new Promise((resolve, reject) => {
      this.paymentProcessingService.init();
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.connecting, { cancelButtonOff: true });
      return this.connectAndGetPrinter(currency, language)
        .then(() => {
          this.logger.debug('connectAndGetPrinter:connected');
          this.paymentProcessingService.dispatchAction(PaymentProcessingActions.processing);
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0) {
            amount = Math.abs(amount);
            return this.refund(amount);
          }
          return this.pay(amount);
        })
        .then((transactionResult: TransactionResult) => {
          const paymentResult = new PaymentResult(PAYMENT_PROVIDERS.MYPOSMINI);
          paymentResult.setPaymentResultData(transactionResult);
          this.logger.debug('pay:refund', { paymentResult });
          resolve(paymentResult);
        })
        .catch((error) => {
          this.logger.error(error, 'makeTransaction');
          const message = this.getErrorMessage(error);
          this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
            message,
            retryButtonOff: true,
          });
          reject(error);
        });
    });
  }

  private getErrorMessage(err: string): string {
    const message = this.errorMessages.get(err) || 'myPos_does_not_connect';
    return this.translateService.instant(message);
  }
}
