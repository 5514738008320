import { Injectable } from '@angular/core';
import { NetworkService } from '@pos-common/services/system/network.service';
import { UserNotificationService } from '@pos-common/services/system/user-notification/user-notification.service';
import { InvoicesProvider } from '@pos-common/services/resources/invoices-db-entity.provider';
import { Invoice } from '@pos-common/classes/invoice.class';
import { Observable, of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { CancellationTypes } from '@pos-common/constants/cancellation-types.enum';
import { AlertService } from '../alert.service';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';

@Injectable()
export class InvoiceCancellationService {
  constructor(
    private networkService: NetworkService,
    private userNotificationService: UserNotificationService,
    private alertService: AlertService,
    private invoicesProvider: InvoicesProvider
  ) {}

  isAllowCreateCancelModal(invoiceUUID: string): Observable<boolean> {
    if (this.networkService.getConnectionStatus()) {
      this.userNotificationService.showNoConnectionMessage();
      return of(false).pipe(first());
    }
    return this.invoicesProvider.getInvoiceWithoutCancelledInvoices(invoiceUUID).pipe(
      map((invoice) => {
        invoice = new Invoice(invoice);
        const hasProductEntries = invoice.hasProductEntries();
        const hasGiftCardEntries = invoice.hasGiftCardsEntries();
        const isOnlyGiftCardsEntries = hasGiftCardEntries && !hasProductEntries && !invoice.isUnpaidInvoiceWithOnInvoicePaymentMethod();
        const isDisallowCancellation = hasGiftCardEntries && this.isAllowCancellation(invoice);

        if (isOnlyGiftCardsEntries || isDisallowCancellation) {
          if (invoice.isUnpaidInvoiceWithOnInvoicePaymentMethod()) {
            return true;
          }
          this.userNotificationService.showInvoiceContainsGiftCardsAlert();
          return false;
        }
        return true;
      }),
      first()
    );
  }

  async getCancelComponent(cancellationType: CancellationTypes) {
    if (cancellationType === CancellationTypes.full) {
      const { InvoiceCancelSendModal } = await import('../../../components/invoice-cancel-send-modal/invoice-cancel-send-modal.component');
      return InvoiceCancelSendModal;
    }
    const { InvoiceCancellationPage } = await import('../../../../pages/invoice-cancellation/page/invoice-cancellation.page');
    return InvoiceCancellationPage;
  }

  async getCancellationType(invoice: Invoice): Promise<CancellationTypes> {
    if (this.isFullCancellation(invoice)) {
      return CancellationTypes.full;
    }
    const isPartialCancellation = await this.isPartialCancellation(invoice.uuid);
    if (isPartialCancellation) {
      return CancellationTypes.partial;
    }
    return CancellationTypes.all;
  }

  private isFullCancellation(invoice: Invoice): boolean {
    const activeInvoiceEntries = invoice.getActiveInvoiceEntriesWithoutGuests();
    const hasOneInvoiceEntry = activeInvoiceEntries.length === 1 && activeInvoiceEntries[0].quantity === 1;
    const hasShoppingCostEntry = activeInvoiceEntries.some((entry) => entry.type === PRODUCT_TYPES.SHIPPING_COST);
    return (
      invoice.isUnpaidInvoiceWithOnInvoicePaymentMethod() ||
      this.isAllowCancellation(invoice) ||
      hasOneInvoiceEntry ||
      hasShoppingCostEntry ||
      invoice.isUnpaidWebshopInvoice() ||
      invoice.isUnpaidSelfOrderInvoice()
    );
  }

  isAllowCancellation(invoice: Invoice): boolean {
    const isReimbursedInvoice = invoice.amount < 0;
    const hasNegativeInvoiceEntry = invoice.getActiveInvoiceEntriesWithoutGuests().some((invoiceEntry) => invoiceEntry.quantity < 0);
    const hasAbsoluteDiscount = !!invoice.discount;
    const hasCustomerAbsoluteDiscount = !!invoice.customerDiscount;
    const hasDiscountCode = !!invoice.discountCode;
    return isReimbursedInvoice || hasNegativeInvoiceEntry || hasAbsoluteDiscount || hasCustomerAbsoluteDiscount || hasDiscountCode;
  }

  private isPartialCancellation(invoiceUuid: string): Promise<boolean> {
    return this.invoicesProvider
      .getInvoiceWithoutCancelledInvoices(invoiceUuid)
      .pipe(
        map((invoice) => {
          invoice = new Invoice(invoice);
          const hasProductEntries = invoice.hasProductEntries();
          const hasGiftCardEntries = invoice.hasGiftCardsEntries();
          const isAllowPartialCancellation =
            hasGiftCardEntries && hasProductEntries && !invoice.isUnpaidInvoiceWithOnInvoicePaymentMethod();
          const hasCancellationInvoices = invoice.cancellationInvoiceReference && invoice.cancellationInvoiceReference.uuid;
          console.log('isAllowPartialCancellation', isAllowPartialCancellation, hasCancellationInvoices);
          return isAllowPartialCancellation || hasCancellationInvoices;
        }),
        first()
      )
      .toPromise();
  }

  async showCancelPopover(invoice: Invoice, event: any): Promise<any> {
    let cancelComponent: any = null;
    const allowCancellationType = await this.getCancellationType(invoice);
    if (allowCancellationType === CancellationTypes.all) {
      const component = (await import('../../../components/cancel-btn/cancel-popover/cancel-popover.component')).CancelPopoverComponent;
      const cancelPopover = await this.alertService.createPopover(
        component,
        {
          allowCancellationType,
          cancelCallback: async (cancellationType: CancellationTypes) => {
            cancelComponent = await this.getCancelComponent(cancellationType);
          },
        },
        event
      );

      await cancelPopover.present();
      return cancelPopover.onDidDismiss().then(() => {
        return cancelComponent;
      });
    }
    cancelComponent = await this.getCancelComponent(allowCancellationType);
    return cancelComponent;
  }
}
