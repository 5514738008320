import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LocalizationUtils } from './utils/localization.utils';
import { CustomerService } from './system/customers.service';
import { MyHammerConfig } from './system/hammer.config';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteNavigationService } from './system/route-navigation/route-navigation.service';
import { CommunityScannerPluginWrapper, PaymashScannerPluginWrapper } from '@pos-common/services/system/camera-scanner-adapter/wrappers';
import { MyposGlassModule } from './system/mypos-glass/mypos-glass.module';
import { AdyenModule } from './system/adyen/adyen.module';

@NgModule({
  imports: [
    AdyenModule,
    MyposGlassModule,
  ],
  providers: [
    LocalizationUtils,
    CustomerService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    RouteNavigationService,
    CommunityScannerPluginWrapper,
    PaymashScannerPluginWrapper,
  ],
})
export class ServicesModule {
  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error('ServicesModule is already loaded. Import it in the AppModule only');
    }
  }
}
