/**
 * Created by y.belinsky on 3/6/17.
 */

import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProductOrCategoryType } from '@pos-common/types/product-or-category.type';

@Injectable()
export class CollectionViewService {
  private cellWidth: number = 115;
  private cellsQuantity: number = 5;
  private currentSidebarSlide: number = 0;
  private cellWidthChangeEvent: EventEmitter<any> = new EventEmitter();
  private cellsQuantityChangeEvent: EventEmitter<any> = new EventEmitter();
  private sidebarSlidesChangeEvent: EventEmitter<any> = new EventEmitter();
  private mobileConfirmationStateChangeEvent: EventEmitter<any> = new EventEmitter();
  private paySeparatelyModeChangeEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public productVariantModalDataChangeEvent: EventEmitter<any> = new EventEmitter();
  public categoryHideEvent: EventEmitter<any> = new EventEmitter();
  public paySeparatelyModeActive: boolean = false;
  private isImagesActive: boolean = true;
  private newProductItemEvent = new Subject<ProductOrCategoryType>();

  constructor() {}

  public calculateCellWidth(wrapperWidth: number, cellsQuantity: number): number {
    return wrapperWidth / cellsQuantity;
  }

  public calculateCellsQuantityInRow(wrapperWidth: number, minCellWidth: number): number {
    return Math.floor(wrapperWidth / minCellWidth);
  }

  public setCellWidth(cellWidth: number): void {
    if (cellWidth < 115) cellWidth = 115;
    this.cellWidth = cellWidth;
    this.cellWidthChangeEvent.emit(this.cellWidth);
  }

  public setCellsQuantity(cellsQuantity: number): void {
    this.cellsQuantity = cellsQuantity;
    this.cellsQuantityChangeEvent.emit(this.cellsQuantity);
  }

  public setCurrentSidebarSlide(currentSidebarSlide: number): void {
    this.currentSidebarSlide = currentSidebarSlide;
    this.sidebarSlidesChangeEvent.emit(this.currentSidebarSlide);
  }

  public getCellWidth(): number {
    return this.cellWidth;
  }

  public getCellsQuantity(): number {
    return this.cellsQuantity;
  }

  public getCurrentSidebarSlide(): number {
    return this.currentSidebarSlide;
  }

  public getCellWidthEmitter(): EventEmitter<any> {
    return this.cellWidthChangeEvent;
  }

  public getCellsQuantityEmitter(): EventEmitter<any> {
    return this.cellsQuantityChangeEvent;
  }

  public getCurrentSidebarSlideEmitter(): EventEmitter<any> {
    return this.sidebarSlidesChangeEvent;
  }

  public getMobileConfirmationStateEmitter(): EventEmitter<any> {
    return this.mobileConfirmationStateChangeEvent;
  }

  public getProductVariantModalDataChangeEmitter(): EventEmitter<any> {
    return this.productVariantModalDataChangeEvent;
  }

  public getRenderImagesStatus(): boolean {
    return this.isImagesActive;
  }

  public setRenderImagesStatus(isImagesActive): void {
    this.isImagesActive = isImagesActive;
  }

  public setProducVariantModalData(productData: any): void {
    this.productVariantModalDataChangeEvent.emit(productData);
  }

  public setCategoryHideEvent(categoryUuid: string) {
    this.categoryHideEvent.emit(categoryUuid);
  }

  public getCategoryHideEvent(): EventEmitter<any> {
    return this.categoryHideEvent;
  }

  public setPaySeparatelyModeChangeEvent(paySeparatelyModeActive: boolean) {
    this.paySeparatelyModeChangeEvent.next(paySeparatelyModeActive);
  }

  public getPaySeparatelyModeChangeEvent() {
    return this.paySeparatelyModeChangeEvent;
  }

  public getActiveVatRatesSwitcher() {
    return this.paySeparatelyModeChangeEvent.pipe(map((paySeparatelyModeActive) => !paySeparatelyModeActive));
  }

  public setNewProductItemEvent(productItem: ProductOrCategoryType) {
    this.newProductItemEvent.next(productItem);
  }

  public getNewProductItemEvent() {
    return this.newProductItemEvent;
  }
}
