import { IPrinterReceiptItem } from '../../interfaces';
import { IReceiptItem } from '../../interfaces/receipt-item.interface';

export class StarReceiptItem implements IPrinterReceiptItem<IReceiptItem> {
  getReceipt(receipt: IReceiptItem[]): IReceiptItem[] {
    return receipt.reduce((newReceipt, item) => {
      if (item.text) {
        let text = item.text;
        text += text.endsWith('\n') ? '' : '\n';
        item = { ...item, text };
      }
      newReceipt.push(item);
      return newReceipt;
    }, []);
  }
}
