/**
 * Created by maksymkunytsia on 9/12/16.
 */

import { timer as observableTimer, BehaviorSubject, Observable, Subscription } from 'rxjs';
// Vendors
import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
// Common - Services
import { SecurityService } from './security.service';
import { DbDaoService } from '../db/db-dao.service';
import { DbResponse } from '../db/db-dao.utils';
import { LoadingService } from './loading.service';
import { LocalStorage } from '../utils/localstorage.utils';
import { CartService } from './cart.service';
import { SyncService } from './sync.service';
// Common - Classes/Constants
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { SERVER_CONFIG } from '../../constants/server.const';
import { UPDATES_SOURCES } from '../../constants/updates-sources.const';
import { SecuredResponse } from '../../classes/secured-response.class';
import { Employee } from '../../classes/employee.class';
import { Store } from '../../classes/store.class';
import { VatRate } from '../../classes/vat-rate.class';
import { PaymentMethod } from '../../classes/payment-method.class';
import { Customer } from '../../classes/customer.class';
import { Product } from '../../classes/product.class';
import { ProductCategory } from '../../classes/product-category.class';
import { ProductVariant } from '../../classes/product-variant.class';
import { Invoice } from '../../classes/invoice.class';
import { GastronomyHall } from '../../classes/gastronomy-hall.class';
import { License } from '../../classes/license.class';
import { NetworkService } from './network.service';
import { CollectionViewService } from './collection-view.service';
import { SYNC_STATUSES } from '../../constants/sync-statuses.const';
import { Company } from '../../classes/company.class';
import { ImageLoaderService } from '@spryrocks/ionic-image-loader-v5';
import { SyncListItem } from '../../classes/sync-list-item.class';
import { LogService } from './logger/log.service';
import { ImageLoadingService } from './image-loading.service';
import { UpdateTypeInterface } from '../../interfaces/update-type.interface';
import { PAYMASH_PROFILE } from '@profile';
import { InvoicesService } from './invoices.service';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { ignoreImageProps } from '@pos-common/services/system/logger';
import { ErrorLevel } from '@spryrocks/logger';
import { PosSettingsService } from './pos-settings.service';
import { NgZoneService } from './ng-zone/ng-zone.service';
import { SelfOrdersService } from '@pos-common/services/system/self-order-invoices/self-orders.service';
import { SalesSubChannelTypesEnum } from '@pos-common/constants';
// Pages

@Injectable()
export class UpdatesService {
  public currentCompanyUuid: string = null;
  public currentCompany: Company;
  // local versions/updates data
  public localVersions: Object = {};
  public localUpdatesLinks: Object = {};
  // updates data
  public updatesInterval: Observable<any>;
  public updatesIntervalSubsciption: Subscription;
  public updatesEmitters: Object = {};
  public updatesTypeKeys: Array<string> = [];
  public updatesTypeInProgressStatus: Object = {};
  public updatesTypeInProgressSubscriptions: { [propName: string]: Subscription } = {};
  public updatesTypeBlocked: Object = {};
  public activeInvoiceWasPaid: any = new EventEmitter();
  public newProductEmitter: EventEmitter<any> = new EventEmitter();
  public newCategoryEmitter: EventEmitter<any> = new EventEmitter();
  public allProductsRemoved: EventEmitter<any> = new EventEmitter();
  // initial update
  private _initialUpdatesInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _onDemandUpdatesInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // versions params
  public versionsTimeout: number = 5 * 1000;
  public versionsInProgress: boolean = false;
  private syncInProgress: BehaviorSubject<boolean> = new BehaviorSubject(SYNC_STATUSES.NOT_IN_PROGRESS);
  private itemsInSyncListCount: number = 0;
  private readonly logger = this.logService.createLogger('UpdatesService');

  constructor(
    public SecurityService: SecurityService,
    public DbDaoService: DbDaoService,
    public LocalStorage: LocalStorage,
    public SyncService: SyncService,
    public CartService: CartService,
    public LoadingService: LoadingService,
    public ImageLoader: ImageLoaderService,
    public NetworkService: NetworkService,
    public CollectionViewService: CollectionViewService,
    private imageLoadingService: ImageLoadingService,
    private invoicesService: InvoicesService,
    private selfOrderService: SelfOrdersService,
    private posSettingsService: PosSettingsService,
    private ngZoneService: NgZoneService,
    private logService: LogService
  ) {
    this.updatesInterval = observableTimer(0, this.versionsTimeout);

    // save keys of UPDATE_TYPE in Array for further usage in other places
    this.updatesTypeKeys = Object.keys(UPDATES_TYPES).filter((key) => !!UPDATES_TYPES[key].URL);
    // create Event Emitter for each UPDATE_TYPE
    // each component will be subscribed for needed Event Emitters
    // on update event emitter will emit new data to component
    for (let i = 0; i < this.updatesTypeKeys.length; i++) {
      this.updatesEmitters[this.updatesTypeKeys[i]] = new EventEmitter();
      this.updatesTypeInProgressStatus[this.updatesTypeKeys[i]] = false;
      this.updatesTypeInProgressSubscriptions[this.updatesTypeKeys[i]] = null;
      this.updatesTypeBlocked[this.updatesTypeKeys[i]] = false;
    }

    // subscribe for Company updates and update company object if update received
    this.updatesEmitters[UPDATES_TYPES.Company.type].subscribe((data) => {
      if (data && data[0] && this.SecurityService.getLoggedUserData()) {
        this.SecurityService.setLoggedCompanyData(data[0].data);
      }
    });

    this.SyncService.synchronizationInProgressSubject.subscribe((inProgress: boolean) => {
      let updateTypesToBlock: string[] = [UPDATES_TYPES.Invoice.type, UPDATES_TYPES.Customer.type];
      this.setUpdateTypesBlocked(updateTypesToBlock, inProgress);
    });

    this.SyncService.synchronizationCountSubject.subscribe((quantity) => {
      this.itemsInSyncListCount = quantity;
    });

    this.SyncService.dataToSaveSubject.subscribe((dataToSave) => {
      this.processUpdatesData([dataToSave], dataToSave.type, false).catch((err) =>
        this.logger.error(err, 'constructor:dataToSave:processUpdatesData', { level: ErrorLevel.Medium })
      );
    });
  }

  public startUpdates() {
    this.currentCompanyUuid = this.SecurityService.getLoggedUserData() ? this.SecurityService.getLoggedUserData()['company']['uuid'] : null;
    this.currentCompany = this.SecurityService.getLoggedCompanyData();

    if (this.currentCompanyUuid) {
      this.localVersions = this.LocalStorage.getObject(`localVersions-${this.currentCompanyUuid}`);
      this.localUpdatesLinks = this.LocalStorage.getObject(`localUpdatesLinks-${this.currentCompanyUuid}`);

      // TODO MAYBE THIS LOGIC SHOULD BE ADJUSTED
      if (Object.values(this.localVersions).length === 0) {
        this.logger.debug('startUpdates:_initialUpdatesInProgress start');
        this._initialUpdatesInProgress.next(true);
        this.getServerVersions(true);
      }
      if (!this.updatesIntervalSubsciption || this.updatesIntervalSubsciption.closed) {
        this.updatesIntervalSubsciption = this.updatesInterval.subscribe(() =>
          this.ngZoneService.runOutsideAngular(() => this.getServerVersions())
        );
      }

      // this.updatesInterval = setInterval(() => this.getServerVersions(), this.versionsTimeout);
    }
  }

  public stopUpdates() {
    // clearInterval(this.updatesInterval);
    if (this.updatesIntervalSubsciption) {
      this.updatesIntervalSubsciption.unsubscribe();
      this.logger.info('stopUpdates');
    }
  }

  public getServerVersions(initial?: boolean) {
    if (!this.versionsInProgress && !this.syncInProgress.getValue()) {
      this.versionsInProgress = true;
      this.SecurityService.doSecureRequest(SERVER_CONFIG.API_URL + 'status', 'get', null, {
        timeout: PAYMASH_PROFILE.requestTimeout.status,
      })
        .then((data: SecuredResponse) => {
          if (!this.SecurityService.checkLicenseExpiration(new License(data['data']['properties']['license']))) {
            this.SecurityService.logout();
          }

          if (data['data'] && data['data']['properties'] && data['data']['properties']['isMigrated'] !== undefined) {
            this.SecurityService.setMigratedStatus(data['data']['properties']['isMigrated']);
          }

          this.NetworkService.setConnectionStatus(false);
          this.versionsInProgress = false;
          this.checkVersions(data.data['properties']['versions'], initial);
        })
        .catch((err: SecuredResponse) => {
          this.logger.error(err, 'getServerVersions:doSecureRequest', { level: ErrorLevel.Low });
          this.NetworkService.setConnectionStatus(true);
          this.versionsInProgress = false;
        });
    }
  }

  public setLocalVersions(data: Object) {
    if (this.currentCompanyUuid) {
      this.LocalStorage.set('localVersions-' + this.currentCompanyUuid, JSON.stringify(data));
      this.localVersions = data;
    }
  }

  public setLocalUpdatesLinks(data: Object) {
    if (this.currentCompanyUuid) {
      this.LocalStorage.set('localUpdatesLinks-' + this.currentCompanyUuid, JSON.stringify(data));
      this.localUpdatesLinks = data;
    }
  }

  public removeLocalVersions() {
    if (this.currentCompanyUuid) {
      this.LocalStorage.remove('localVersions-' + this.currentCompanyUuid);
      this.localVersions = new Object();
    }
  }

  public removeLocalUpdatesLinks() {
    if (this.currentCompanyUuid) {
      this.LocalStorage.remove('localUpdatesLinks-' + this.currentCompanyUuid);
      this.localUpdatesLinks = new Object();
    }
  }

  /**
   * @description method compare local versions of entities with server
   * */
  public checkVersions(apiVersions: any[], initial?: boolean) {
    let updatesTypesToUpdate = [];
    // check if localVersions and apiVersions exists
    if (this.localVersions && apiVersions) {
      // transform to associative array
      const apiVersionsObj = apiVersions.reduce((r, x) => ({ ...r, [x.type]: x }), {});
      // push UPDATE_TYPE that should be updated to updatesTypesToUpdate array
      // UPDATE_TYPE pushed to array if it NOT exist in localVersions
      // or localVersion of UPDATE_TYPE less then version from apiVersions
      for (let i = 0; i < this.updatesTypeKeys.length; i++) {
        if (
          (this.updatesTypeKeys[i] === UPDATES_TYPES.GastronomyHall.type ||
            this.updatesTypeKeys[i] === UPDATES_TYPES.VirtualPrinter.type) &&
          this.SecurityService.getLoggedCompanyData() &&
          !this.SecurityService.getLoggedCompanyData()?.['isRestaurantEnabled']
        ) {
          continue;
        }
        const localVersion = this.localVersions?.[this.updatesTypeKeys[i]]?.version
          ? moment.utc(this.localVersions[this.updatesTypeKeys[i]].version)
          : null;

        const apiVersion = apiVersionsObj?.[this.updatesTypeKeys[i]]?.version
          ? moment.utc(apiVersionsObj[this.updatesTypeKeys[i]].version)
          : null;

        if (!localVersion || (localVersion && apiVersion && !localVersion.isSame(apiVersion))) {
          updatesTypesToUpdate.push(UPDATES_TYPES[this.updatesTypeKeys[i]]);
        }
      }

      let updateType = UPDATES_SOURCES.UPDATE;
      if (initial) updateType = UPDATES_SOURCES.INITIAL;

      if (updatesTypesToUpdate.length > 0) {
        this.getUpdates(updatesTypesToUpdate, updateType);
      }
    }
  }

  public get initialUpdateStatus(): Observable<boolean> {
    return this._initialUpdatesInProgress.asObservable();
  }

  setOnDemandUpdateStatus() {
    this._onDemandUpdatesInProgress.next(true);
  }

  get onDemandUpdateStatus(): Observable<boolean> {
    return this._onDemandUpdatesInProgress.asObservable();
  }

  processUpdateDeletedItem(currentItem: any): Promise<any> {
    return new Promise((resolve) => {
      let activeInvoice: Invoice = this.CartService.getActiveInvoice();
      // Remove customer in active invoice
      if (
        currentItem.type === UPDATES_TYPES.Customer.type &&
        activeInvoice &&
        activeInvoice.customer &&
        currentItem.properties.uuid === activeInvoice.customer['uuid']
      ) {
        this.CartService.setCustomer(null);
      }

      if (currentItem.type === UPDATES_TYPES.Invoice.type) {
        this.logger.debug('processUpdateDeletedItem:removed_invoice_from_server ', { currentItemUuid: currentItem.properties.uuid });
      }

      // Remove active invoice
      if (currentItem.type === UPDATES_TYPES.Invoice.type && activeInvoice && activeInvoice.uuid === currentItem.properties.uuid) {
        this.CartService.createInvoice();
        this.logger.debug('processUpdateDeletedItem:remove_active_invoice ', { currentItemUuid: currentItem.properties.uuid });
      }
      resolve(currentItem.properties);
    });
  }

  private prepareUpdatesData(updateData) {
    let newData = updateData.data['entities'];
    try {
      let newDataParsed: string = JSON.stringify(newData);
      newDataParsed = newDataParsed.replace(/\u2028/g, '');
      newDataParsed = newDataParsed.replace(/\u2029/g, '');
      newData = JSON.parse(newDataParsed);
    } catch (err) {
      this.logger.error(err, 'prepareUpdatesData');
    }
    return newData;
  }

  private processUpdateItem(currentItem: any, isSyncUpdates: boolean): Promise<any> {
    return new Promise((resolve) => {
      if (this.CollectionViewService.getRenderImagesStatus()) {
        this.imageLoadingService.findAndPrepareImages(currentItem);
      }
      const activeInvoice: Invoice = this.CartService.getActiveInvoice();
      // Update customer in active invoice
      if (
        currentItem.type === UPDATES_TYPES.Customer.type &&
        activeInvoice?.customer &&
        currentItem.properties.uuid === activeInvoice.customer?.['uuid']
      ) {
        // TODO INVESTIGATE ABOUT CUSTOMER SAVE
        this.CartService.setCustomer(new Customer(currentItem.properties), false);
      }

      if (currentItem.type === UPDATES_TYPES.GastronomyHall.type && activeInvoice?.gastronomyTable?.uuid) {
        const table = currentItem.properties.gastronomyTables.find((table) => table.uuid === activeInvoice.gastronomyTable.uuid);
        if (table && table.name !== activeInvoice?.gastronomyTableName) {
          this.CartService.setTable(table);
        }
      }

      if (currentItem.type === UPDATES_TYPES.Invoice.type) {
        const currentInvoice = currentItem.properties as Invoice;
        if (activeInvoice && activeInvoice.uuid === currentInvoice.uuid) {
          // Update active invoice with data from API
          // if invoice not present in sync list
          if (currentInvoice.isPaid || currentInvoice.paymentMethod === DefaultPaymentMethods.ON_INVOICE) {
            this.CartService.setActiveInvoice(new Invoice(currentInvoice));
            this.activeInvoiceWasPaid.next(currentInvoice);
            let itemToRemove: SyncListItem = new SyncListItem(currentInvoice, currentItem.type);
            itemToRemove.type = UPDATES_TYPES.Invoice.type;
            this.SyncService.removeDataFromSyncList(itemToRemove);
            resolve(currentInvoice);
            //TODO investigate invoice twice pushing to 'itemProperties'
          } else {
            this.shouldSkipUpdatedInvoice(currentItem, activeInvoice)
              .then((isSkip) => {
                if (isSkip) {
                  return resolve(null);
                }
                if (isSyncUpdates) {
                  const invoice = new Invoice(currentInvoice);
                  const mergeResult = this.invoicesService.mergeUpdateAndActiveInvoiceEntries(
                    invoice.invoiceEntries,
                    activeInvoice.invoiceEntries
                  );
                  invoice.invoiceEntries = mergeResult.invoiceEntries;
                  if (mergeResult.isSaveRequired) {
                    this.logger.info('processUpdateItem:activeInvoice: Invoice uuid has local changes and needs to update', {
                      invoiceUuid: invoice.uuid,
                    });
                    this.CartService.setActiveInvoice(invoice, mergeResult.isSaveRequired);
                    return resolve(null);
                  }
                }
                this.CartService.setActiveInvoice(new Invoice(currentInvoice));
                return resolve(currentInvoice);
              })
              .catch(() => {
                resolve(null);
              });
          }
        } else if (this.itemsInSyncListCount) {
          this.shouldSkipUpdatedInvoice(currentItem)
            .then((isSkip) => {
              if (isSkip) {
                return resolve(null);
              }
              resolve(currentItem.properties);
            })
            .catch(() => {
              resolve(null);
            });
        } else {
          const isSelfOrderInvoice = currentItem.properties?.subChannel === SalesSubChannelTypesEnum.SELFORDER && currentItem.properties?.selfOrder;
          const invoice: Invoice = isSelfOrderInvoice ? this.selfOrderService.updateSelfOrderService(currentItem) : currentItem?.properties;
          resolve(invoice);
        }
      } else {
        resolve(currentItem.properties);
      }
    });
  }

  private shouldSkipUpdatedInvoice(currentItem: any, activeInvoice?: Invoice): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const updatedInvoice = new Invoice(currentItem.properties);
      const promise = activeInvoice
        ? Promise.resolve(activeInvoice)
        : this.DbDaoService.getDataByUUID(currentItem.type, updatedInvoice.uuid).then((data) => data.data);

      promise
        .then((data) => {
          if (data) {
            const localInvoice = new Invoice(data);
            const isPresent = this.SyncService.isDataPresentInSyncList(localInvoice.syncState);
            const isSkip = this.isSkipActiveInvoice(updatedInvoice, localInvoice);
            if (isPresent || isSkip) {
              return resolve(true);
            }
            const hasChanges = this.hasInvoiceChanges(updatedInvoice, localInvoice);
            if (hasChanges) {
              this.logger.info('shouldSkipUpdatedInvoice: Invoice uuid has local changes and should be updated', {
                invoiceUuid: updatedInvoice.uuid,
              });
              this.invoicesService.saveInvoice(localInvoice);
              return resolve(true);
            }
          }
          return resolve(false);
        })
        .catch(reject);
    });
  }

  private hasInvoiceChanges(updatedInvoice: Invoice, localInvoice: Invoice): boolean {
    const isPaid = localInvoice.isPaid && localInvoice.isPaid !== updatedInvoice.isPaid;
    const isSkip = this.isSkipActiveInvoice(updatedInvoice, localInvoice);
    const hasChanges = isPaid && !isSkip;
    if (hasChanges && updatedInvoice.isPartiallyPaid) {
      return false;
    }
    const hasLocalChanges = updatedInvoice.localModificationDate < localInvoice.localModificationDate;
    return hasLocalChanges || hasChanges;
  }

  private isSkipActiveInvoice(updatedInvoice: Invoice, localInvoice: Invoice) {
    return updatedInvoice.modificationDate < localInvoice.modificationDate;
  }

  private processUpdatesData(newData: any[], updatesType: string, isSyncUpdates: boolean = true): Promise<Array<Promise<DbResponse>>> {
    return new Promise((resolve, reject) => {
      // create array of DB operations(promises): DELETE OR UPSERT
      let dbPromises: Array<Promise<DbResponse>> = [];
      const isInitialUpdate = this._initialUpdatesInProgress.getValue();

      let itemPropertiesPromises: Promise<any>[] = [];
      for (let i = 0; i < newData.length; i++) {
        let currentItem = newData[i];
        if (!isInitialUpdate) {
          this.logger.debug('processUpdatesData', { currentItem: ignoreImageProps(currentItem) });
        }

        if (currentItem.type === UPDATES_TYPES.Customer.type) {
          currentItem.properties.isSynced = true;
        }

        if (currentItem.properties.deleted) {
          itemPropertiesPromises.push(this.processUpdateDeletedItem(currentItem));
        } else {
          const promise = this.processUpdateItem(currentItem, isSyncUpdates);
          if (promise) {
            itemPropertiesPromises.push(promise);
          }
        }
      }

      Promise.all(itemPropertiesPromises)
        .then((itemPropertiesData: any[]) => {
          let itemProperties = [];
          for (let i = 0; i < itemPropertiesData.length; i++) {
            let currentItemProperties = itemPropertiesData[i];
            if (currentItemProperties) {
              if (currentItemProperties.deleted) {
                dbPromises.push(this.DbDaoService.removeDataFromCollection(updatesType, currentItemProperties));
              } else {
                itemProperties.push(currentItemProperties);
              }
            }
          }
          if (itemProperties.length > 0) dbPromises.push(this.DbDaoService.upsertDataToCollection(updatesType, itemProperties));
          return this.imageLoadingService.savePreloadImages();
        })
        .then(() => {
          this.LoadingService.syncFinish();
          resolve(dbPromises);
        })
        .catch((err) => {
          this.LoadingService.syncFinish();
          this.logger.error(err, 'processUpdatesData:Promise:all');
          reject();
        });
    });
  }

  /**
   * @description method requests updates for each entity
   * */
  public getUpdates(updatesArray: Array<UpdateTypeInterface>, source: string, updateFromDate?: string) {
    // go through updatesArray and make request for update via default link or link from localUpdatesLinks
    for (let i = 0; i < updatesArray.length; i++) {
      const currentUpdateType = updatesArray[i];

      if (this.updatesTypeInProgressStatus[currentUpdateType.type]) {
        continue;
      }

      if (
        (currentUpdateType.type === UPDATES_TYPES.GastronomyHall.type || currentUpdateType.type === UPDATES_TYPES.VirtualPrinter.type) &&
        this.SecurityService.getLoggedCompanyData() &&
        !this.SecurityService.getLoggedCompanyData()?.isRestaurantEnabled
      ) {
        continue;
      }

      // create link for update
      let linkForUpdate = `${SERVER_CONFIG.API_URL}updates/${currentUpdateType.URL}`;
      if (source !== UPDATES_SOURCES.INITIAL) {
        // check if link for further updates of needed update type exist in local updates links
        let localLinkForUpdate = this.localUpdatesLinks[currentUpdateType.type]
          ? this.localUpdatesLinks[currentUpdateType.type].href
          : null;
        if (localLinkForUpdate) linkForUpdate = localLinkForUpdate;
      }

      if (source === UPDATES_SOURCES.UPDATE_ON_DEMAND && updateFromDate) {
        linkForUpdate = `${SERVER_CONFIG.API_URL}updates/${currentUpdateType.URL}?fromVersion=${encodeURIComponent(updateFromDate)}`;
        this.logger.debug('getUpdates by update_on_demand', { linkForUpdate });
      }

      if (
        source === UPDATES_SOURCES.MORE ||
        !this.updatesTypeInProgressStatus[currentUpdateType.type] ||
        !this.updatesTypeBlocked[currentUpdateType.type]
      ) {
        // do update request
        this.updatesTypeInProgressStatus[currentUpdateType.type] = true;
        this.setSyncInProgressStatus(SYNC_STATUSES.IN_PROGRESS);
        const { max, firstUpdate } = PAYMASH_PROFILE.requestTimeout;
        const timeout = source === UPDATES_SOURCES.INITIAL ? firstUpdate : max;
        this.updatesTypeInProgressSubscriptions[currentUpdateType.type] = this.SecurityService.doSecureRequestObservable(
          linkForUpdate,
          'get',
          null,
          { timeout }
        ).subscribe(
          (updateData: SecuredResponse) => {
            this.updatesTypeInProgressSubscriptions[currentUpdateType.type] = null;
            this.processUpdatesResponse(updateData, currentUpdateType);
          },
          (err: SecuredResponse) => {
            this.logger.error(err, 'getUpdates:doSecureRequest', undefined, { type: currentUpdateType.type });
            this.updatesTypeInProgressStatus[currentUpdateType.type] = false;
            this.updatesTypeInProgressSubscriptions[currentUpdateType.type] = null;
            this.setSyncInProgressStatus(SYNC_STATUSES.NOT_IN_PROGRESS);
          }
        );
      }
    }
  }

  public handleUpdateData(dataArray, updateData, type, source, parentCategory?) {
    updateData = updateData || [];
    // detect class instance based on UPDATE_TYPE
    let newClass;
    switch (type) {
      case UPDATES_TYPES.Employee.type:
        newClass = Employee;
        break;
      case UPDATES_TYPES.Store.type:
        newClass = Store;
        break;
      case UPDATES_TYPES.VATRate.type:
        newClass = VatRate;
        break;
      case UPDATES_TYPES.PaymentMethod.type:
        newClass = PaymentMethod;
        break;
      case UPDATES_TYPES.Customer.type:
        newClass = Customer;
        break;
      case UPDATES_TYPES.Product.type:
        newClass = Product;
        break;
      case UPDATES_TYPES.ProductVariant.type:
        newClass = ProductVariant;
        break;
      case UPDATES_TYPES.Invoice.type:
        newClass = Invoice;
        break;
      case UPDATES_TYPES.ProductCategory.type:
        newClass = ProductCategory;
        break;
      case UPDATES_TYPES.GastronomyHall.type:
        newClass = GastronomyHall;
        break;
    }

    // go through updateData array and adjust dataArray accordingly
    for (let j = 0; j < updateData.length; j++) {
      const currentItem = source === 'update' ? updateData[j].data : updateData[j];

      if (type === UPDATES_TYPES.ProductCategory.type) {
        if (
          !currentItem.deleted &&
          ((!parentCategory && currentItem.parentProductCategory) ||
            (parentCategory && !currentItem.parentProductCategory) ||
            (parentCategory && currentItem.parentProductCategory && parentCategory !== currentItem.parentProductCategory.uuid))
        ) {
          continue;
        }
      }

      // TODO ADJUST CONDITION TO SUPPORT PRODUCT UPDATES NOT ONLY IN ROOT CATEGORY
      if (type === UPDATES_TYPES.Product.type) {
        if (!currentItem.deleted && parentCategory === 'root') {
          if (currentItem.productInCategories.length === 0) {
            continue;
          } else {
            let rootCategoryFound = 0;
            for (let i = 0; i < currentItem.productInCategories.length; i++) {
              let currentCategoryRelation = currentItem.productInCategories[i];
              if (!currentCategoryRelation.category) rootCategoryFound += 1;
            }
            if (rootCategoryFound === 0) continue;
          }
        }
      }

      // try to get updateData item INDEX in dataArray
      const currentItemIndex = dataArray.findIndex((item) => item.uuid === currentItem.uuid);

      // if updateData item exist in dataArray
      // a) remove it from dataArray, if it was deleted
      // b) replace it with newest version
      if (currentItemIndex !== -1) {
        if (currentItem.deleted) {
          dataArray.splice(currentItemIndex, 1);
        } else {
          dataArray.splice(currentItemIndex, 1, new newClass(currentItem));
        }
        // push new item to dataArray
      } else if (!currentItem.deleted && !currentItem.isWebshopStore) {
        dataArray.push(new newClass(currentItem));
      }
    }

    return dataArray;
  }

  public getSyncGettingDataStatusEvent(): Observable<boolean> {
    return this.syncInProgress.asObservable();
  }

  private processUpdatesResponse(updateData: SecuredResponse, currentUpdateType: UpdateTypeInterface) {
    // save updated entities to temp array
    let newData = this.prepareUpdatesData(updateData);
    this.processUpdatesData(newData, currentUpdateType.type)
      .then((dbPromises) => Promise.all(dbPromises))
      .then((data: any) => {
        // save link for further updates to localStorage
        let tempLocalUpdatesLinks = this.localUpdatesLinks;
        if (!tempLocalUpdatesLinks[currentUpdateType.type]) tempLocalUpdatesLinks[currentUpdateType.type] = new Object();
        tempLocalUpdatesLinks[currentUpdateType.type].href = updateData.data['links'][0].href;
        this.setLocalUpdatesLinks(tempLocalUpdatesLinks);

        // save link for further updates to localStorage
        if (!this.localVersions[currentUpdateType.type]) this.localVersions[currentUpdateType.type] = new Object();
        this.localVersions[currentUpdateType.type].version = updateData.data['properties']['version'];
        this.setLocalVersions(this.localVersions);
        this.updatesTypeInProgressStatus[currentUpdateType.type] = false;

        if (updateData.data['links'][0] && updateData.data['links'][0].rel === 'more') {
          this.getUpdates([currentUpdateType], UPDATES_SOURCES.MORE);
        } else {
          this.setSyncInProgressStatus(SYNC_STATUSES.NOT_IN_PROGRESS);
        }

        // emit new event for UPDATE_TYPE with update d data
        let transformedData = []; // to adapt for bunched database savings
        for (let dataChange of data) {
          if (dataChange.data.length > 0) {
            for (let obj of dataChange.data) {
              transformedData.push({ data: obj });
            }
          } else {
            transformedData.push({ data: dataChange.data });
          }
        }

        if (currentUpdateType.type === UPDATES_TYPES.Invoice.type) {
          for (let obj of transformedData) {
            let tableUuid = obj.data && obj.data.gastronomyTable && obj.data.gastronomyTable.uuid;
            if (tableUuid) {
              this.CartService.calculateGastronomyTableInvoices(obj.data).catch((err) =>
                this.logger.error(err, 'getUpdates:CartService:calculateGastronomyTableInvoices')
              );
            }
          }
        }

        if (transformedData.length > 0) {
          this.updatesEmitters[currentUpdateType.type].next(transformedData);
        } else {
          // TODO investigate null emit
          this.updatesEmitters[currentUpdateType.type].next(null);
        }

        if (this._initialUpdatesInProgress.getValue()) {
          // TODO ADJUST WAY TO SEARCH VALUES?
          if (JSON.stringify(this.updatesTypeInProgressStatus).indexOf('true') === -1) {
            this.logger.debug('startUpdates:_initialUpdatesInProgress end');
            this._initialUpdatesInProgress.next(false);
          }
        }
        if (this._onDemandUpdatesInProgress.getValue()) {
          // TODO ADJUST WAY TO SEARCH VALUES?
          if (JSON.stringify(this.updatesTypeInProgressStatus).indexOf('true') === -1) {
            this.logger.debug('startUpdates:_onDemandUpdatesInProgress end');
            this._onDemandUpdatesInProgress.next(false);
          }
        }
      })
      .catch((err) => {
        this.logger.error(err, 'getUpdates:processUpdatesData');
        this.updatesTypeInProgressStatus[currentUpdateType.type] = false;
        this.setSyncInProgressStatus(SYNC_STATUSES.NOT_IN_PROGRESS);
      });
  }

  private setSyncInProgressStatus(status: boolean): void {
    this.syncInProgress.next(status);
  }

  private setUpdateTypesBlocked(updateTypes: string[], status: boolean) {
    updateTypes.forEach((type) => {
      this.updatesTypeBlocked[type] = status;
      if (status) {
        this.cancelUpdateRequestByType(type);
      }
    });
  }

  public getUpdateEmmiterByType(type: string): Observable<any> {
    return this.updatesEmitters[type];
  }

  private cancelUpdateRequestByType(type: string) {
    if (this.updatesTypeInProgressSubscriptions[type]) {
      this.logger.debug('cancelUpdateRequestByType: type - ', { type });
      this.updatesTypeInProgressSubscriptions[type].unsubscribe();
      this.updatesTypeInProgressStatus[type] = false;
      this.updatesTypeInProgressSubscriptions[type] = null;
      this.setSyncInProgressStatus(SYNC_STATUSES.NOT_IN_PROGRESS);
    }
  }

  public getInvoicesUpdatesOnDemand() {
    const invoicesKeepDuration = this.posSettingsService.getInvoicesKeepDuration();
    const fromDate = moment.utc().subtract(invoicesKeepDuration, 'day').startOf('day').toISOString();
    this.setOnDemandUpdateStatus();
    this.getUpdates([UPDATES_TYPES.Invoice], UPDATES_SOURCES.UPDATE_ON_DEMAND, fromDate);
    this.posSettingsService.setInitialPaidInvoicesStatus(false);
  }
}
