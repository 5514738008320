import { combineLatest, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { MyPosPrinterService } from './my-pos-printer/my-pos-printer.service';
import { StarPrinterService } from './star-printer';
import { ReceiptPrinter } from '../classes/receipt-printer.class';

import { IMyPosReceiptItem, ReceiptBuilder } from '../classes/receipt-builder.class';
import { EpsonPrinterService } from './epsonprint';
import { PrinterType } from '../enum/printer-type.enum';
import { ReceiptPrintersStore, ReceiptPrintersStoreActions } from '../receipt-printers.store';
import { ToastController } from '@ionic/angular';
import { ReceiptPrinterModeTypes } from '../../../../constants/receipt-printer-mode-types';
import { StarPrinterScannerEventAction } from '../enum/star-printer-scanner-event-action';
import { StarPrinterScannerEventState } from '../enum/star-printer-scanner-event-state';
import { PAYMASH_PROFILE } from '@profile';
import { LogService } from '../../logger/log.service';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { MyPosFacade } from '@pos-modules/my-pos/my-pos.facade';
import { MyPosMiniFacade } from '@pos-modules/my-pos-mini/my-pos-mini.facade';
import { first, filter, map } from 'rxjs/operators';
import { ISunmiService } from '@pos-common/services/system/devices/sunmi/sunmi.service';
import { ISunmiPrinterService } from '@pos-common/services/system/receipt-printers/services/sunmi-printer';
import { IAdyenPrinterService } from '@pos-common/services/system/receipt-printers/services/adyen-printer';
import { IDeviceReportingService } from '@pos-common/services/system/device-report';
import { ReceiptPrintersReporting } from './receipt-printers.reporting';
import { BarcodeReaderResultDataMessage, BarcodeReaderResultStateMessage } from '@paymash/capacitor-star-printer-plugin';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

@Injectable()
export class ReceiptPrintersService {
  private readonly kSavedPrinters: string = 'savedPrinters';
  private savedPrinters: ReceiptPrintersStore;
  private localStorage: Storage;
  private _discoverInProgressState: Observable<boolean>;

  private readonly reporting: ReceiptPrintersReporting;
  private readonly logger = this.logService.createLogger('ReceiptPrintersService');

  constructor(
    public LocalStorageService: LocalStorage,
    private starPrinterService: StarPrinterService,
    private epsonPrinterService: EpsonPrinterService,
    private myPosPrinterService: MyPosPrinterService,
    private sunmiPrinterService: ISunmiPrinterService,
    private adyenPrinterService: IAdyenPrinterService,
    private toastCtrl: ToastController,
    private myPosService: MyPosService,
    private myPosFacade: MyPosFacade,
    private sunmiService: ISunmiService,
    private myPosMiniFacade: MyPosMiniFacade,
    deviceReportingService: IDeviceReportingService,
    private logService: LogService,
    private readonly adyenService: IAdyenService
  ) {
    this.localStorage = window.localStorage;
    this.savedPrinters = new ReceiptPrintersStore(this.getSavedPrinters());
    this.savedPrinters.subscribe((printersList) => this.setSavedPrinters(printersList));

    if (PAYMASH_PROFILE.APP_ENV !== 'production') {
      this.starPrinterService.scannerEvents.pipe(filter((event) => event.action === StarPrinterScannerEventAction.stateChanged)).subscribe(
        (event) => {
          this.presentToast((event as BarcodeReaderResultStateMessage).newState);
        },
        (error) => this.presentToast(JSON.stringify(error), false)
      );
    }
    this.reporting = new ReceiptPrintersReporting(this, deviceReportingService);
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this._discoverInProgressState = combineLatest([
      this.starPrinterService.discoverInProgressState,
      this.epsonPrinterService.discoverInProgressState,
    ]).pipe(map((res) => res[0] || res[1]));
  }

  get printersList(): Observable<ReceiptPrinter[]> {
    const listSources: Observable<ReceiptPrinter[]>[] = [
      this.savedPrinters,
      this.epsonPrinterService.printerList,
      this.starPrinterService.printerList,
    ];

    if (this.myPosService.hasMyPosPrinter) {
      listSources.push(this.myPosPrinterService.printerList);
      this.myPosPrinterService.printerList.pipe(first()).subscribe((printers) => printers.length && this.setActivePrinter(printers[0]));
    }

    if (this.sunmiService.isSunmiDevice) {
      listSources.push(this.sunmiPrinterService.printerList);
      this.sunmiPrinterService.printerList.pipe(first()).subscribe((printers) => printers.length && this.setActivePrinter(printers[0]));
    }

    const isAdyenPrinterEnabled = this.adyenService.isPrinterConnected;
    if (isAdyenPrinterEnabled) {
      listSources.push(this.adyenPrinterService.printerList);
      this.adyenPrinterService.printerList.pipe(first()).subscribe((printers) => printers.length && this.setActivePrinter(printers[0]));
    }

    return combineLatest(listSources).pipe(
      map((result) => {
        const [savedPrinters, ...printers] = result;
        const foundedPrinters = [].concat(...printers);
        const mergeItems = (savedItems: ReceiptPrinter[], itemsToAdd: ReceiptPrinter[]): ReceiptPrinter[] => {
          savedItems.forEach((item) => {
            item.saved = true;
            item.isActive = false;
          });

          if (savedItems.length && itemsToAdd.length) {
            let savedItemsLength = 0;
            const newItems = itemsToAdd.filter(function (item) {
              let isExist = false;
              if (savedItemsLength < this.length) {
                for (let i = 0; i < this.length; i++) {
                  const saveItem: ReceiptPrinter = this[i];
                  const hasMacAddress = !!item.macAddress && !!saveItem.macAddress;
                  const isEqualMacAddres = hasMacAddress && item.macAddress.toLowerCase() === saveItem.macAddress.toLowerCase();
                  const isEqualModal = item.printerModel === saveItem.printerModel;
                  if (isEqualMacAddres && isEqualModal) {
                    saveItem.isActive = true;
                    saveItem.updateDeviceInfo(item);
                    isExist = true;
                    savedItemsLength++;
                  }
                }
              }
              return !isExist;
            }, savedItems);

            return [...savedItems, ...newItems];
          }

          return [...savedItems, ...itemsToAdd];
        };
        return mergeItems(
          savedPrinters,
          foundedPrinters.map((item) => {
            item.isActive = true;
            return item;
          })
        );
      })
    );
  }

  get discoverInProgressState(): Observable<boolean> {
    return this._discoverInProgressState;
  }

  getSavedPrintersList(): Observable<ReceiptPrinter[]> {
    return this.savedPrinters.asObservable();
  }

  print(device: ReceiptPrinter, data: ReceiptBuilder): Promise<void> {
    const receipt = data.getReceiptByPrinterType(device.deviceType);
    switch (device.deviceType) {
      case PrinterType.Star:
        return this.starPrinterService.print(device, receipt);
      case PrinterType.Sunmi:
        return this.sunmiPrinterService.print(device, receipt);
      case PrinterType.Adyen:
        return this.adyenPrinterService.print(device, receipt);
      case PrinterType.MyPosMini:
        return this.myPosMiniFacade.print(receipt as IMyPosReceiptItem[]);
      case PrinterType.MyPos:
        if (this.myPosService.isMyPosHubDevice) {
          return this.myPosFacade.print(data.receiptForMyPosHub);
        }
        return this.myPosFacade.print(receipt as IMyPosReceiptItem[]);
      default:
        return this.epsonPrinterService.print(device, receipt);
    }
  }

  getSavedPrinters(): ReceiptPrinter[] {
    let savedPrinters = [];
    if (!this.localStorage) return savedPrinters;
    const printersData: string = this.localStorage.getItem(this.kSavedPrinters);
    if (printersData) {
      try {
        savedPrinters = JSON.parse(printersData).map((printer) => new ReceiptPrinter(printer));
      } catch (err) {
        this.logger.error(err, 'Read printers storage error');
      }
    }
    return savedPrinters;
  }

  private setSavedPrinters(printers: ReceiptPrinter[]): void {
    if (this.localStorage) {
      let printersDataForSave = '[]';
      try {
        printersDataForSave = JSON.stringify(printers);
      } catch (err) {
        this.logger.error(err, 'Write printers storage error');
      }
      this.localStorage.setItem(this.kSavedPrinters, printersDataForSave);
    }
  }

  public setActivePrinter(printer: ReceiptPrinter, printerType?: ReceiptPrinterModeTypes) {
    const printerToAdd = new ReceiptPrinter(printer);
    if (printerType && printerType === ReceiptPrinterModeTypes.KITCHEN) {
      printerToAdd.isKitchenPrinter = true;
    } else {
      printerToAdd.isPosPrinter = true;
    }
    this.addToSaved(printerToAdd);
  }

  public setScannerPrinter(printer: ReceiptPrinter, isActive: boolean) {
    const printerToAdd = new ReceiptPrinter(printer);
    printerToAdd.isScannerActive = isActive;
    this.addToSaved(printerToAdd);
  }

  public addScannerPrinter(printer: ReceiptPrinter) {
    this.setScannerPrinter(printer, true);
  }

  public removeScannerPrinter(printer: ReceiptPrinter) {
    this.setScannerPrinter(printer, false);
  }

  checkScannerConnectivityStatus(printer: ReceiptPrinter): Observable<boolean> {
    const connectionEvent: Subject<boolean> = new Subject<boolean>();
    const completion = (connectionEvent, subscription) => {
      connectionEvent.complete();
      if (subscription) subscription.unsubscribe();
    };
    const subscription = this.starPrinterService.startBarcodeReader(printer).subscribe(
      (event) => {
        if (event.action === StarPrinterScannerEventAction.stateChanged) {
          switch (event.newState) {
            case StarPrinterScannerEventState.readerConnected:
            case StarPrinterScannerEventState.readerDisconnected:
              connectionEvent.next(event.newState === StarPrinterScannerEventState.readerConnected);
              this.starPrinterService
                .stopBarcodeReader()
                .then(() => completion(connectionEvent, subscription))
                .catch(() => completion(connectionEvent, subscription));
              break;
            case StarPrinterScannerEventState.stopped:
              completion(connectionEvent, subscription);
              break;
          }
        }
      },
      (err) => {
        this.logger.error(err, 'checkScannerConnectivityStatus:startBarcodeReader');
      }
    );
    return connectionEvent.asObservable();
  }

  get newBarcodeEvent(): Observable<string> {
    return this.starPrinterService.scannerEvents.pipe(
      filter((event) => event.action === StarPrinterScannerEventAction.dataReceived),
      map((event) => {
        return (event as BarcodeReaderResultDataMessage).data;
      })
    );
  }

  startBarcodeReader(printer: ReceiptPrinter) {
    this.starPrinterService.startBarcodeReader(printer);
    return this.newBarcodeEvent;
  }

  stopBarcodeReader(): Promise<void> {
    return this.starPrinterService.stopBarcodeReader();
  }

  async presentToast(message: string, success: boolean = true) {
    try {
      const toast = await this.toastCtrl.create({
        message,
        duration: success ? 300 : 1500,
        position: 'top',
        cssClass: success ? '' : 'failure-barcode-toast',
      });
      await toast.present();
    } catch (err) {
      this.logger.error(err, 'presentToast:toast:present');
    }
  }

  updateSavedPrinterViewData(updatedPrinter: ReceiptPrinter) {
    this.reporting.sendEvent('Updated', updatedPrinter);

    this.savedPrinters.dispatch({
      type: ReceiptPrintersStoreActions.UPDATE_PRINTER_VIEW_DATA,
      payload: new ReceiptPrinter(updatedPrinter),
    });
  }

  updateSavedPrinterModel(updatedPrinter: ReceiptPrinter) {
    this.reporting.sendEvent('Updated', updatedPrinter);

    this.savedPrinters.dispatch({
      type: ReceiptPrintersStoreActions.UPDATE_PRINTER_MODEL,
      payload: new ReceiptPrinter(updatedPrinter),
    });
  }

  addToSaved(newPrinter: ReceiptPrinter) {
    this.reporting.sendEvent('Added', newPrinter);

    this.savedPrinters.dispatch({
      type: ReceiptPrintersStoreActions.ADD_PRINTER,
      payload: new ReceiptPrinter(newPrinter),
    });
  }

  removeFromSaved(printer: ReceiptPrinter) {
    this.reporting.sendEvent('Removed', printer);

    this.savedPrinters.dispatch({
      type: ReceiptPrintersStoreActions.REMOVE_PRINTER,
      payload: new ReceiptPrinter(printer),
    });
  }
}
