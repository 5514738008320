/**
 * Created by maksymkunytsia on 9/19/16.
 */

export class Address {
    firstName?: string;
    lastName?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phoneNr?: string;
    phoneNrMobile?: string;
    business?: string;
    addition?: string;
    salutation?: string;
    uuid?: string;

    constructor(data) {
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.address = data.address || '';
        this.postalCode = data.postalCode || '';
        this.city = data.city || '';
        this.country = data.country || '';
        this.phoneNr = data.phoneNr || '';
        this.phoneNrMobile = data.phoneNrMobile || '';
        this.business = data.business || '';
        this.addition = data.addition || '';
        this.uuid = data?.uuid;
        this.salutation = data?.salutation;
    }
}
