import { MyposGlassTransactionCodeEnum } from "./mypos-glass-transaction-code.enum";

// @ts-ignore
export const MYPOS_GLASS_ERROR_CODE_MATRIX: Record<MyposGlassTransactionCodeEnum, string> = {
  [MyposGlassTransactionCodeEnum.PLATFORM_NOT_SUPPORT_GLASS]: 'terminal_error_payment_option_is_not_available_for_this_device',
  [MyposGlassTransactionCodeEnum.GLASS_APP_NOT_INSTALLED]: 'terminal_error_pos_glass_app_not_installed',
  [MyposGlassTransactionCodeEnum.POS_INFO_NOT_EXISTS]: 'terminal_error_pos_glass_app_info_not_exists',
  [MyposGlassTransactionCodeEnum.TRANSACTION_FAILED]: 'terminal_transaction_error_failed',
  [MyposGlassTransactionCodeEnum.TRANSACTION_CANCELED]: 'terminal_transaction_error_user_cancel',
  [MyposGlassTransactionCodeEnum.TRANSACTION_DECLINED]: 'terminal_transaction_error_declined',
  [MyposGlassTransactionCodeEnum.DEVICE_NOT_ACTIVATED]: 'terminal_transaction_error_pos_glass_device_not_activated',
  [MyposGlassTransactionCodeEnum.REFUND_NOT_ACTIVATED]: 'terminal_transaction_error_pos_glass_refund_not_activated',
  [MyposGlassTransactionCodeEnum.NO_DATA_FOUND]: 'terminal_transaction_error_no_data_found',
  [MyposGlassTransactionCodeEnum.INVALID_CURRENCY]: 'terminal_transaction_error_wrong_currency',
  [MyposGlassTransactionCodeEnum.INVALID_AMOUNT]: 'terminal_transaction_error_invalid_amount',
  [MyposGlassTransactionCodeEnum.COMMUNICATION_ERROR]: 'terminal_error_connect_fail',
  [MyposGlassTransactionCodeEnum.NFC_NOT_READY]: 'terminal_error_connect_nfc_not_ready',
  [MyposGlassTransactionCodeEnum.UNKNOWN]: 'common_error_msg'
}
