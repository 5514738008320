import { Injectable } from '@angular/core';
import { AdyenTippingService } from './adyen-tipping.service';
import { AdyenAcquirerDataEnum } from '../enums';

@Injectable()
export class AdyenAcquirerDataService {

  constructor(
    private tipping: AdyenTippingService
  ) { }

  public saleToAcquirerData(): string {
    return this.tenderOption();
  }

  private tenderOption(): string {
    return this.tipping.isTippingAllowed ? `${AdyenAcquirerDataEnum.TenderOption}=${this.tipping.add}` : '';
  }
}
