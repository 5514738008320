import { Injectable, NgZone } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class UserIdleService {
  public settings;
  public idle;
  public lastTimerId;
  public isStarted: boolean;
  public idleEvent = new EventEmitter();

  constructor(private ngZone: NgZone) {
    this.settings = {
      idle: 60 * 1000 * 5, // idle time in ms (5 minutes)
      events: ['keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: this.onIdle.bind(this), // callback function to be executed after idle time
      onActive: this.onActive.bind(this), // callback function to be executed after back form idleness
      keepTracking: true, // false to track only once
      startAtIdle: false, // true to start in the idle state
      recurIdleCall: false, // true to use setInterval instead of setTimeout
    };
    this.idle = this.settings.startAtIdle;
    this.lastTimerId = null;
  }

  public start() {
    if (!this.isStarted) {
      this.isStarted = true;
      // add idle stop event listner
      window.addEventListener('idle:stop', function (event) {
        this.bulkRemoveEventListener(window, this.settings.events);
        this.settings.keepTracking = false;
        this.resetTimeout(this.lastTimerId, this.settings);
      });
      // start timer and save it id
      this.lastTimerId = this.timeout(this.settings);
      // add event listed in settings
      this.bulkAddEventListener(
        window,
        this.settings.events,
        function (event) {
          this.lastTimerId = this.resetTimeout(this.lastTimerId, this.settings);
        }.bind(this)
      );
    }
  }

  public onIdle() {
    this.idleEvent.next('idle');
  }

  public onActive() {
    // TODO DO WE NEED THIS CALL?
  }

  public forceResetTimeout(): void {
    this.lastTimerId = this.resetTimeout(this.lastTimerId, this.settings);
  }

  public resetTimeout(id, settings) {
    if (this.idle) {
      // TODO DO WE NEED THIS CALL?
      settings.onActive.call();
      this.idle = false;
    }
    clearTimeout(id);
    if (this.settings.keepTracking) {
      return this.timeout(this.settings);
    }
  }

  public timeout(settings) {
    var timer = this.settings.recurIdleCall ? setInterval : setTimeout;
    var id;
    id = timer(
      function () {
        this.idle = true;
        this.settings.onIdle.call();
      }.bind(this),
      this.settings.idle
    );
    return id;
  }

  public bulkRemoveEventListener(object, events) {
    events.forEach(function (event) {
      object.removeEventListener(event);
    });
  }

  public bulkAddEventListener(object, events, callback) {
    this.ngZone.runOutsideAngular(() => {
      events.forEach(function (event) {
        object.addEventListener(event, function (event) {
          callback(event);
        });
      });
    });
  }
}
