import { Pipe, PipeTransform } from '@angular/core';
import { SelfOrderShortOrderNumberUtil } from '@pos-common/services';

@Pipe({
  name: 'generateSelfOrderNumber'
})
export class GenerateSelfOrderNumberPipe implements PipeTransform {
  transform(invoiceId: string): string {
    return SelfOrderShortOrderNumberUtil(invoiceId);
  }
}
