/**
 * Created by maksymkunytsia on 8/19/16.
 */

// Vendors
import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { LogService } from './logger/log.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class MessagesService implements OnDestroy {
  public toasterItem: HTMLIonToastElement = null;
  public routerNavigation$: Subscription;
  private readonly logger = this.logService.createLogger('MessagesService');
  constructor(public toastController: ToastController, private logService: LogService, private router: Router) {
    this.routerNavigation$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideToasterItem();
    });
  }

  ngOnDestroy() {
    this.routerNavigation$.unsubscribe();
  }

  public handleError(errorData): void {
    // HTTP/API error
    if (errorData.data && errorData.hasOwnProperty('url')) {
      if (errorData.data.errors && errorData.data.errors.length > 0) {
        let messageToShow;
        for (let i = 0; i++; i < errorData.data.errors.length) {
          messageToShow += errorData.data.errors[i].message + '</br>';
        }
        this.showToasterItem({ message: messageToShow, type: 'error' });
      } else {
        this.showToasterItem({ message: errorData.data.message, type: 'error' });
      }
      // DB error
    } else if (errorData.hasOwnProperty('dbMessage')) {
      this.showToasterItem({ message: errorData.message, type: 'error' });
    }
  }

  public showToasterItem(toasterParams): void {
    this.hideToasterItem();

    setTimeout(async () => {
      this.toasterItem = await this.toastController.create({
        position: 'top',
        message: toasterParams.message,
        duration: toasterParams.duration || 2000,
        cssClass: toasterParams.type || 'info',
      });
      this.toasterItem.present().catch((err) => this.logger.error(err, 'showToasterItem:toasterItem:present'));

      if (toasterParams.callback) {
        this.toasterItem.onDidDismiss().then(() => {
          toasterParams.callback();
          this.toasterItem = null;
        });
      } else {
        this.toasterItem.onDidDismiss().then(() => (this.toasterItem = null));
      }
    }, 50);
  }

  public hideToasterItem(): void {
    if (this.toasterItem) {
      this.toasterItem.dismiss().catch((err) => this.logger.error(err, 'hideToasterItem:toasterItem:dismiss'));
    }
  }
}
