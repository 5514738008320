import { NgModule } from '@angular/core';
import { ListColComponent } from './list-col/list-col.component';
import { ListGridComponent } from './list-grid/list-grid.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ListColComponent, ListGridComponent, ListHeaderComponent],
  exports: [ListColComponent, ListGridComponent, ListHeaderComponent],
})
export class ListModule {}
