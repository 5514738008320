import { CommonPrinterService } from '@pos-common/services/system/receipt-printers/services/common-printer.service';
import { PrinterServiceInterface } from '@pos-common/interfaces/printer-service.interface';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptPrinter } from '@pos-common/services/system/receipt-printers/classes/receipt-printer.class';
import { MYPOS } from '@pos-common/constants/mypos.const';
import { AdyenPaymentApi } from '@pos-common/services/system/adyen/services/api';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';
import { PrintRequest, PrintReceiptRequestOptions } from '@pos-common/services/system/adyen/adyen-types';
import { IAdyenService } from '@pos-common/services/system/devices/adyen/adyen.service';

export abstract class IAdyenPrinterService extends CommonPrinterService implements PrinterServiceInterface {}

export class AdyenPrinterService extends IAdyenPrinterService {
  constructor(
    injector: Injector,
    private readonly adyenPaymentApi: AdyenPaymentApi,
    private readonly utils: AdyenUtils,
    private readonly adyenService: IAdyenService
  ) {
    super(injector);
  }

  protected get discoverMethodName(): string {
    return '';
  }

  protected get pluginName(): string {
    return '';
  }

  protected get printMethodName(): string {
    return '';
  }

  protected get stopDiscoverMethodName(): string {
    return '';
  }

  protected logsSubscription() {
    return;
  }

  protected discover(): Observable<ReceiptPrinter[]> {
    this.getPrinter();
    return this.printerList;
  }

  protected stopDiscover() {
    return Promise.resolve();
  }

  public checkPermissionsOnAndroid(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private getPrinter(): Observable<ReceiptPrinter[]> {
    const isAdyenPrinterEnabled = this.adyenService.isPrinterConnected;

    if (isAdyenPrinterEnabled) {
      const deviceName = 'Adyen';
      const deviceType = 'Printer';
      const printerType = 'ADYEN';

      const printer = new ReceiptPrinter({
        printerName: deviceName,
        deviceName,
        deviceType,
        ipAddress: MYPOS.IP_ADDRESS,
        isActive: true,
        macAddress: MYPOS.MAC_ADDRESS,
        printerNameForModelPick: printerType,
        target: MYPOS.TARGET,
      });

      this.handleDiscover([printer]);
      return;
    }
    this.handleDiscover([]);
  }

  async print(device: ReceiptPrinter, receipt: PrintRequest[]) {
    for (let request of receipt) {
      await this.adyenPaymentApi.printReceipt(this.createPrintOptions(request));
    }
  }

  private createPrintOptions(request: PrintRequest): PrintReceiptRequestOptions {
    return {
      ...this.utils.createCommonRequestOptions(),
      ...request,
    };
  }
}
