import {
  PrintRequest,
  PrintTextRequest,
  PrinterTextItem,
  PrintQrCodeRequest,
  PrintImageRequest,
} from '@pos-common/services/system/adyen/adyen-types';
import { IPrinterReceiptItem } from '../../interfaces';
import { IReceiptItem } from '../../interfaces/receipt-item.interface';

export class AdyenReceiptItem implements IPrinterReceiptItem<PrintRequest> {
  getReceipt(receipt: IReceiptItem[]): PrintRequest[] {
    let newReceipt = this.createNewReceipt(receipt);
    newReceipt = this.groupReceipt(newReceipt);
    return newReceipt;
  }

  private createNewReceipt(receipt: IReceiptItem[]): PrintRequest[] {
    return receipt.reduce((newReceipt, item) => {
      let value: PrintRequest;
      const key = this.getKey(item);
      switch (key) {
        case 'text':
          value = this.getText(item.text);
          break;
        case 'qrCode':
          value = this.getQrCode(item.qrCode);
          break;
        case 'image':
          value = this.getImage(item.image);
          break;
      }
      if (value) {
        newReceipt.push(value);
      }
      return newReceipt;
    }, []);
  }

  private getKey(item: IReceiptItem) {
    const key = Object.keys(item);
    return key[0];
  }

  private groupReceipt(receipt: PrintRequest[]): PrintRequest[] {
    return receipt.reduce((newReceipt, item) => {
      const lastItem = newReceipt.slice(-1)[0];
      if (lastItem?.type === item.type && item.type === 'text') {
        lastItem['textItems'].push(...item.textItems);
      } else {
        newReceipt.push(item);
      }
      return newReceipt;
    }, [] as PrintRequest[]);
  }

  private getText(multilineText: string): PrintTextRequest {
    if (multilineText.endsWith('\n')) {
      multilineText = multilineText.substring(0, multilineText.length - 1);
    }
    const textItems = multilineText.split('\n').reduce((textItems, line) => {
      const printerText: PrinterTextItem = { Text: line, EndOfLineFlag: true };
      textItems.push(printerText);
      return textItems;
    }, []);
    return { type: 'text', textItems };
  }

  private getQrCode(qrCode: string): PrintQrCodeRequest {
    return { type: 'qrCode', qrCode };
  }

  private getImage(image: string): PrintImageRequest {
    return { type: 'image', imageBase64: image };
  }
}
