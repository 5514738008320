import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trustValue'
})
export class TrustValuePipe implements PipeTransform {

  transform(value: unknown): boolean {
    return value !== null && value !== undefined;
  }

}

@NgModule({
  declarations: [TrustValuePipe],
  exports: [TrustValuePipe],
})
export class TrustValuePipeModule {}
