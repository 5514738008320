import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';

@Injectable()
export class UserNotificationService {
  constructor(private alertService: AlertService) {}

  showEmployeeDoesntAllowedPopup() {
    this.showMessage('employee_are_not_able_to_remove_items');
  }

  showInvoiceContainsPaymentAlert() {
    this.showMessage('invoice_has_payment_record_error');
  }

  showNoConnectionMessage() {
    this.showMessage('global_error_no_connection_with_internet', 'common_error');
  }

  showInvoiceContainsGiftCardsAlert() {
    this.showMessage('giftcard_message_denied');
  }

  showNoConnectionGiftCardsMessage() {
    this.showMessage('giftcard_status_offline_message');
  }

  showGiftCardProcessingErrorMessage() {
    this.showMessage('giftcard_failed_msg');
  }

  showMessage(message: string, header? : string): Promise<HTMLIonAlertElement> {
    const options: { message: string; header?: string; } = header ? { message, header } : { message };
    return this.alertService.showAlert(options);
  }
}
