import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PlatformType } from '@pos-common/constants/platform-types.enum';
import { IonicImageLoaderComponent } from '@spryrocks/ionic-image-loader-v5';
// tslint:disable: no-string-literal

@Component({
  selector: 'pos-image-loader-cap',
  template: '',
  styleUrls: ['./image-loader-cap.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageLoaderCapComponent extends IonicImageLoaderComponent {
  @Input()
  set url(imageUrl: string) {
    this['_src'] = this['processImageUrl'](imageUrl);
    this.updateImageByUrl(this['_src']);
  }

  private updateImageByUrl(imageUrl: string) {
    this['imageLoader']
      .getImagePath(imageUrl)
      .then((url: string) => {
        url = this.getImageUrl(url);
        this.setNewImage(url);
      })
      .catch(() => {
        this.setNewImage(this.fallbackUrl || imageUrl);
      });
  }

  private setNewImage(imageUrl: string, stopLoading: boolean = true): void {
    super['setImage'](imageUrl, stopLoading);
    if (!this['_useImg']) {
      this['renderer'].setStyle(this.element, 'background-image', `url("${imageUrl}")`);
    }
  }

  private getImageUrl(imageUrl: string) {
    const platform = Capacitor.getPlatform();
    if (platform === PlatformType.iOS) {
      return imageUrl.replace('http://localhost:8080/', 'ionic://localhost:8080/_capacitor_file_/');
    } else if (platform === PlatformType.Android) {
      return Capacitor.convertFileSrc(imageUrl);
    }
    return imageUrl;
  }
}
@NgModule({
  declarations: [ImageLoaderCapComponent],
  exports: [ImageLoaderCapComponent],
})
export class ImageLoaderCapComponentModule {}
