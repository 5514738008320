import { IPrinterReceiptItem } from '../../interfaces';
import { IReceiptItem } from '../../interfaces/receipt-item.interface';

export class SunmiReceiptItem implements IPrinterReceiptItem<IReceiptItem> {

  getReceipt(receipt: IReceiptItem[]): IReceiptItem[] {
    return receipt.reduce((newReceipt, item: IReceiptItem) => {
      if (item.textSize) {
        const { textSize } = item;
        const fontSize: 26 | 48 = this.getFontSize(textSize);

        item = {
          ...item,
          textSize,
          fontSize
        };

      }
      newReceipt.push(item);
      return newReceipt;
    }, []);
  }

  private getFontSize(textSize: number): 26 | 48 {
    return textSize === 2 ? 48 : 26;
  }
}
