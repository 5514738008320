export const ROUTE_URLS = {
  collection: '/collection',
  checkout: '/collection/checkout',
  checkoutSuccess: '/collection/success',
  invoices: '/invoices',
  invoicesCombine: '/invoices/combine',
  invoicesOnline: '/invoices/online',
  invoicesOnlineWebShop: '/invoices/online/webshop',
  invoicesOnlineSelfOrder: '/invoices/online/selforders',
  report: '/report',
  admin: '/admin',
  shifts: '/shifts',
  settings: '/settings',
  deviceDetails: '/settings/device-details',
  printerQueue: '/settings/printer-queue',
  manualConnection: '/settings/manual-connection',
  weakDevice: '/weak-device',
  login: '/login',
  employee: '/employee',
  customers: '/customers',
  success: '/success',
  calendar: '/calendar',
  appointment: '/appointment',
};
