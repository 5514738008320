import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import { CartService } from './cart.service';
import { AlertOptions } from '@pos-common/interfaces/alert.inteface';
import { LogService } from './logger/log.service';

@Injectable()
export class TableEnforceService {
  openChangeTableEvent = new EventEmitter();
  private isTableEnforcement = false;
  private tableEnforceAlert = null;
  private readonly logger = this.logService.createLogger('TableEnforceService');

  constructor(
    public TranslateService: TranslateService,
    public AlertService: AlertService,
    public CartService: CartService,
    private logService: LogService
  ) {}

  checkForTableEnforcementAndShowAlert() {
    if (!this.isTableEnforcement || this.CartService.getActiveTableName()) return false;

    if (!this.tableEnforceAlert) {
      const opts: AlertOptions = {
        header: this.TranslateService.instant('restaurant_no_tables_selected_title'),
        subHeader: this.TranslateService.instant('restaurant_no_tables_selected_msg'),
        buttons: [
          {
            text: 'OK',
            handler: () => this.openChangeTableEvent.emit(),
          },
        ],
      };
      this.tableEnforceAlert = this.AlertService.create(opts)
        .then((alert) => {
          alert.onDidDismiss().then(() => (this.tableEnforceAlert = null));
          alert.present();
        })
        .catch((err) => this.logger.error(err, 'checkForTableEnforcementAndShowAlert:AlertService:create'));
    }

    return true;
  }

  setTableEnforcementState(isTableEnforcement: boolean): void {
    this.isTableEnforcement = isTableEnforcement;
  }

  checkForTableEnforcement() {
    return this.isTableEnforcement;
  }
}
