export class ImageToLoad {
  public uuid: string;
  public src: string;
  public numberOfRetries: number

  constructor(data: any) {
    this.uuid = data.uuid;
    this.src = data.src || null;
    this.src = data.numberOfRetries || 0;
  }
}
